import {
  GACreateProductReview,
  GAOrderByWhatsappClickEntity,
  GAProductDetailsEntity,
  GAViewReviewsEntity,
} from "../types";
import { CoreAdapter } from "./shared/CoreAdapter";

export class GAProductDescriptionPage extends CoreAdapter {
  viewItemDetailsAdapter = ({
    product,
    numberOfReviews,
    eventId,
  }: GAProductDetailsEntity) => {
    const gaData = this.convertTransformedProductToAnalyticsFormat(product, -1);
    return {
      event: "view_item_details",
      ecommerce: {
        eventId,
        currency: CoreAdapter.activeCurrency,
        // value: product.price.sellingPrice,
        value: product.price.find(p => p.code === CoreAdapter.activeCurrency)
          ?.sellingPrice,
        product: {
          ...gaData,
          rating: product.averageRating,
          no_of_review: numberOfReviews,
        },
      },
    };
  };

  orderByWhatsappAdapter = ({
    product,
    pageUrl,
    optionLink,
    optionName,
  }: GAOrderByWhatsappClickEntity) => {
    return {
      event: "order_by_whatsapp",
      ecommerce: {
        page_url: pageUrl,
        option_name: optionName,
        option_link: optionLink,
        impressions: this.convertTransformedProductToAnalyticsFormat(
          product,
          -1
        ),
      },
    };
  };

  viewProductReviewAdapter = (data: GAViewReviewsEntity) => {
    const gaData = this.convertTransformedProductToAnalyticsFormat(
      data.product,
      -1
    );
    return {
      event: "view_product_reviews",
      ecommerce: {
        product: gaData,
        reviews: data.reviews,
      },
    };
  };

  createProductReviewAdapter = (data: GACreateProductReview) => {
    const gaData = this.convertTransformedProductToAnalyticsFormat(
      data.product,
      -1
    );
    return {
      event: "write_product_review",
      ecommerce: {
        product: gaData,
        review: data.review,
      },
    };
  };
}

export const productDescriptionAdapter = new GAProductDescriptionPage();
