import { Button } from "@mui/material";
import Link from "next/link";
import React, { FC } from "react";
import { HorizontalCard } from "../HorizontalCard";

export const OrderDetailsCard: FC<{
  product: any;
  currency: string;
  trackingUrl?: string | null;
}> = ({ product, currency, trackingUrl }) => {
  return (
    <HorizontalCard
      key={product.id}
      title={product.title}
      subtitle={
        <p className="text-xs text-gray-500">
          Product category is{" "}
          <span className="text-gray-900 font-bold">{product.category}</span>
        </p>
      }
      image={product.img}
      actionButtons={[]}
      redirectLink={`/products/${product.id}`}>
      <div className="flex flex-col gap-4">
        <ul className="list-disc ml-4 flex flex-col gap-2">
          <li className="text-xs lg:text-sm text-gray-500">
            Quantity of this product is{" "}
            <span className="text-gray-900 font-bold">{product.quantity}</span>
          </li>
          <li className="text-xs lg:text-sm text-gray-500">
            Price of this product{" "}
            <span className="text-gray-900 font-bold">
              {currency}
              {product.priceBoughtAt}
            </span>
            .
          </li>
        </ul>
        {trackingUrl && (
          <div>
            <Link href={trackingUrl} target="_blank">
              <Button>Track this item</Button>
            </Link>
          </div>
        )}
      </div>
    </HorizontalCard>
  );
};
