import { FC } from "react";
import Link from "next/link";

export const Logo: FC<{ small?: boolean }> = ({ small = false }) => {
  return (
    <div className={`relative w-[90px] lg:w-[135px] h-[40px] lg:h-[50px]`}>
      <Link href="/">
        <img
          src="https://cdn.picpil.com/logos/2022/08/217/logo.png"
          alt="brand-logo"
          className="w-full h-full object-contain"
        />
      </Link>
    </div>
  );
};
