import { Link, Stack, Typography } from "@mui/material";
import Nextlink from "next/link";
import { FC, ReactNode, useState, useMemo, useEffect } from "react";
import { CountdownTimer } from "../../CountdownTimer";

interface Step {
  id: string;
  redirectLink?: string;
  label: string;
  timer: {
    timeFrom: string;
    timeUpto: string;
    slotDuration: number;
  };
}

interface Props {
  stepsData: Step[];
}

export const BannerTextCarousel: FC<Props> = ({ stepsData }) => {
  const [activeStep, setActiveStep] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      setActiveStep(p => {
        if (p === stepsData.length - 1) return 0;
        return p + 1;
      });
    }, 5000);
    return () => clearTimeout(timer);
  }, [activeStep]);

  const step = useMemo(() => stepsData[activeStep], [activeStep]);

  let text: ReactNode;
  if (step.redirectLink) {
    text = (
      <Link component={Nextlink} href={step.label} color="#fff">
        {step.label}
      </Link>
    );
  } else {
    text = <Typography variant="body1">{step.label}</Typography>;
  }

  return (
    <Stack
      sx={{ width: "100%" }}
      direction="row"
      justifyContent="center"
      alignItems="center">
      <Stack
        direction="row"
        flexWrap="wrap"
        justifyContent="center"
        alignItems="center"
        gap={1}>
        {text}
        {step.timer ? (
          <Typography variant="body2" color="#fff" component="div">
            <CountdownTimer
              slotDuration={step.timer.slotDuration}
              timeUpto={step.timer.timeUpto}
              timeFrom={step.timer.timeFrom}
            />
          </Typography>
        ) : null}
      </Stack>
    </Stack>
  );
};
