import { TransformedProductEntity } from "../../types/transformed_product_entity";
import { useEffect, useState } from "react";
import { ProductListings } from "../services";

export const useGetProducts = (url: string) => {
  const [products, setProducts] = useState<TransformedProductEntity[]>();

  const getData = async () => {
    const { products } = await ProductListings.getProducts(url);
    setProducts(products);
  };
  useEffect(() => {
    getData();
  }, []);

  return products;
};
