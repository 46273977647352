import React, { Dispatch, FC, SetStateAction } from "react";
import { PopupViewer } from "../../molecules";
import { LoginMain } from "../../templates/Login";

interface Props {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  onSuccess?: () => void;
}

export const LoginPopup: FC<Props> = ({
  open,
  setOpen,
  onSuccess = () => {},
}) => {
  const handleSuccess = async () => {
    setOpen(false);
    onSuccess();
  };
  return (
    <PopupViewer open={open} setOpen={setOpen}>
      <LoginMain onSuccess={handleSuccess} />
    </PopupViewer>
  );
};
