import { NetworkCall } from "../NetworkCall";

export class FileUpload {
  static uploadFile = async (file: File) => {
    const formData = new FormData();
    formData.append("fileName", file);
    const response = await fetch(
      `${process.env.BASE_URL}/listings/items/review/upload_image`,
      {
        method: "POST",
        body: formData,
        headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    const data = (await response.json()) as {
      uploadResult: [
        {
          fields_name: string;
          content_type: string;
          file_name: string;
          file_size: number;
          public_url: string;
        }
      ];
    };
    return data;

    // const data = (await NetworkCall.mutate({
    //   method: "POST",
    //   url: `${process.env.BASE_URL}/listings/items/review/upload_image`,
    //   body: file,
    //   auth: "token",
    // })) as {
    //   uploadResult: [
    //     {
    //       fields_name: string;
    //       content_type: string;
    //       file_name: string;
    //       file_size: number;
    //       public_url: string;
    //     }
    //   ];
    // };
  };
}
