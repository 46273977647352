import { FC, useEffect, useState } from "react";
import {
  useGetCouponsQuery,
  useLazyGetManualCouponQuery,
} from "../../../store";
import {
  Button,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { SlClose } from "react-icons/sl";

interface Props {
  setPriceRuleId: (data: number) => void;
  priceRuleId: number;
}

const CouponTextField: React.FC<Props> = ({ priceRuleId, setPriceRuleId }) => {
  const [applyManualCoupon, { data: manualCouponData, isSuccess }] =
    useLazyGetManualCouponQuery();
  const [couponValue, setCouponValue] = useState(
    manualCouponData?.couponTitle ? manualCouponData.couponTitle : ""
  );

  useEffect(() => {
    if (isSuccess && manualCouponData) {
      setPriceRuleId(manualCouponData.id);
    }
  }, [isSuccess]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    applyManualCoupon(couponValue);
  };
  return (
    <form onSubmit={handleSubmit}>
      <Stack direction="row" gap={1} alignItems="center">
        <TextField
          id="coupon"
          fullWidth
          size="small"
          label="Apply coupon"
          value={couponValue}
          onChange={e => setCouponValue(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {manualCouponData && manualCouponData.id === priceRuleId ? (
                  <IconButton color="error" onClick={() => setPriceRuleId(0)}>
                    <SlClose />
                  </IconButton>
                ) : null}
              </InputAdornment>
            ),
          }}
        />
        <Button type="submit" variant="outlined">
          Apply
        </Button>
      </Stack>
    </form>
  );
};

export const Coupons: FC<Props> = ({ setPriceRuleId, priceRuleId }) => {
  const { data } = useGetCouponsQuery();

  const getCouponDescription = (index: number) => {
    const coupon = data!.coupons[index];
    const valueType = coupon.valueType;
    const bucket = coupon.buckets[coupon.buckets.length - 1];

    switch (valueType) {
      case "PERCENTAGE":
        return `${coupon.value}% Off, Max ₹${bucket.maxRedemptionValue}`;
      case "FIXED":
        return `Rs. ${coupon.value} flat Off.`;
    }
  };

  return (
    <div className="space-y-8">
      <CouponTextField
        priceRuleId={priceRuleId}
        setPriceRuleId={setPriceRuleId}
      />
      {data && data.coupons.length > 0 && (
        <div className="w-full flex flex-col gap-2">
          <List
            subheader={
              <Typography variant="subtitle1" fontWeight={700}>
                Available coupon codes
              </Typography>
            }>
            {data.coupons.map((c, index) => {
              return (
                <ListItem
                  disableGutters
                  key={c.id}
                  secondaryAction={
                    priceRuleId === c.id ? (
                      <IconButton
                        color="error"
                        onClick={() => setPriceRuleId(0)}>
                        <SlClose />
                      </IconButton>
                    ) : (
                      <Button onClick={() => setPriceRuleId(c.id)}>
                        Apply
                      </Button>
                    )
                  }
                  divider>
                  <ListItemText
                    primary={c.title}
                    secondary={getCouponDescription(index)}
                  />
                </ListItem>
              );
            })}
          </List>
        </div>
      )}
    </div>
  );
};
