import { Typography } from "@mui/material";
import React, { FC, ReactNode } from "react";

interface CompoundText {
  H1: typeof H1;
  H2: typeof H2;
  H3: typeof H3;
  P: typeof P;
  Caption: typeof Caption;
}

export const Text: FC<{
  children: ReactNode;
}> &
  CompoundText = ({ children }) => {
  return <Typography>{children}</Typography>;
};

const H1: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <Typography variant="h1" textTransform="capitalize" component="h1">
      {children}
    </Typography>
  );
};
const H2: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <Typography variant="h2" textTransform="capitalize" component="h2">
      {children}
    </Typography>
  );
};
const H3: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <Typography variant="subtitle1" gutterBottom>
      {children}
    </Typography>
  );
};
const P: FC<{ children: ReactNode; secondary?: boolean }> = ({
  children,
  secondary,
}) => {
  return (
    <Typography variant="body2" color="inherit" component="p">
      {children}
    </Typography>
  );
};
const Caption: FC<{ children: ReactNode; secondary?: boolean }> = ({
  children,
}) => {
  return <p className="text-sm">{children}</p>;
};

Text.H1 = H1;
Text.H2 = H2;
Text.H3 = H3;
Text.P = P;
Text.Caption = Caption;
