import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { CountryList, LoginResponse, otpPayload } from "./Types";

export const loginApi = createApi({
  reducerPath: "loginApi",
  refetchOnFocus: true,
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.BASE_URL,
    prepareHeaders: header => {
      header.set(
        "authorization",
        `Basic NC1VKGVMeTNhR1MsI200ZEskNjh1aDZnS3p2dWdURyM6cClIPU1iQHldbWgpcmc4Z2RBey0ufVU0eGE2dkFlMnk=`
      );
      return header;
    },
  }),
  endpoints: builder => ({
    getCountryList: builder.query<CountryList[], void>({
      query: () => "/customer/country/list",
      transformResponse: (res: { result: CountryList[] }) => {
        return res.result.filter(country => country.isMobileOtpEnabled);
      },
    }),
    requestOtp: builder.mutation<any, otpPayload>({
      query: payload => {
        let body: any;
        if (payload.dialCode) {
          body = {
            mobileNumber: `${payload.dialCode}${payload.inputValue}`,
            mobileResendType: "Text",
            otpOption: "mobile",
          };
        } else {
          body = {
            emailId: payload.inputValue,
            otpOption: "email",
          };
        }
        return {
          url: "/customer/send_otp",
          method: "POST",
          body,
        };
      },
    }),
    verifyOtp: builder.mutation<
      LoginResponse,
      { type: "email" | "mobile"; otp: string; inputValue: string }
    >({
      query: ({ type, otp, inputValue }) => {
        return {
          url: "/customer/login",
          method: "POST",
          body: {
            otp,
            [type === "email" ? "emailId" : "mobileNumber"]: inputValue,
            signInType: type === "email" ? "email_otp" : "otp_login",
          },
        };
      },
    }),
    otplessLogin: builder.mutation<
      LoginResponse,
      { mobileNumber: string; otp: string }
    >({
      query: body => {
        return {
          url: "/customer/login",
          method: "POST",
          body: {
            ...body,
            signInType: "otpless",
          },
        };
      },
    }),
  }),
});

export const {
  useGetCountryListQuery,
  useRequestOtpMutation,
  useVerifyOtpMutation,
  useOtplessLoginMutation,
} = loginApi;
