import { Button, Stack } from "@mui/material";
import { useFormikContext } from "formik";
import { FC } from "react";

export const FormSubmitButtons: FC = () => {
  const { handleSubmit, resetForm } = useFormikContext();
  return (
    <Stack direction="row" gap={2}>
      <Button onClick={() => resetForm()} variant="outlined">
        Reset
      </Button>
      <Button type="submit" onClick={() => handleSubmit()} variant="contained">
        Submit
      </Button>
    </Stack>
  );
};
