import { Box, CardMedia } from "@mui/material";
import React, { FC, useRef } from "react";

const ZOOM_RATIO = 3;
let x = 0,
  y = 0,
  xx = 0,
  yy = 0;
export const ZoomImageWidget: FC<{ src: string; alt: string }> = ({
  src,
  alt,
}) => {
  const containerRef = useRef<HTMLElement>();
  const overlayRectRef = useRef<HTMLElement>();
  const zoomBoxRef = useRef<HTMLElement>();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    overlayRectRef.current!.style.opacity = "1";
    zoomBoxRef.current!.style.backgroundSize = `${
      containerRef.current!.offsetWidth * ZOOM_RATIO
    }px ${containerRef.current!.offsetHeight * ZOOM_RATIO}px`;
    setAnchorEl(event.currentTarget);
  };

  const handleMouseMove = (event: React.MouseEvent<HTMLElement>) => {
    x = event.nativeEvent.offsetX;
    y = event.nativeEvent.offsetY;

    xx = x - overlayRectRef.current?.offsetWidth! / 2;
    yy = y - overlayRectRef.current?.offsetHeight! / 2;

    if (x < overlayRectRef.current?.offsetWidth! * 0.5) {
      x = overlayRectRef.current?.offsetWidth! * 0.5;
      xx = 0;
    }
    // right of main picture
    if (
      x >
      containerRef.current!.offsetWidth -
        overlayRectRef.current?.offsetWidth! * 0.5
    ) {
      x =
        containerRef.current!.offsetWidth -
        overlayRectRef.current?.offsetWidth! * 0.5;
      xx = x - overlayRectRef.current?.offsetWidth! * 0.5;
    }
    // top of main picture
    if (y < overlayRectRef.current?.offsetHeight! * 0.5) {
      y = overlayRectRef.current?.offsetHeight! * 0.5;
      yy = 0;
    }
    // bottom of main picture
    if (
      y >
      containerRef.current!.offsetHeight -
        overlayRectRef.current?.offsetHeight! * 0.5
    ) {
      y =
        containerRef.current!.offsetHeight -
        overlayRectRef.current?.offsetHeight! * 0.5;
    }

    overlayRectRef.current!.style.left = x + "px";
    overlayRectRef.current!.style.top = y + "px";

    zoomBoxRef.current!.style.backgroundPosition = `${-xx * 3}px ${-yy * 3}px`;
  };

  const handlePopoverClose = () => {
    overlayRectRef.current!.style.opacity = "0";
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <Box
        ref={containerRef}
        sx={{ position: "relative", width: "100%", height: "auto" }}
        onMouseEnter={handlePopoverOpen}
        onMouseMove={handleMouseMove}
        onMouseLeave={handlePopoverClose}>
        <Box
          ref={overlayRectRef}
          sx={{
            position: "absolute",
            margin: "0px !important",
            padding: 0,
            width: "200px",
            height: "150px",
            backgroundColor: "#78787c4d",
            transform: "translate(-50%, -50%)",
            pointerEvents: "none",
            opacity: 0,
          }}
        />
        <CardMedia
          component="img"
          image={src}
          alt={alt}
          sx={{ objectFit: "contain", border: 1, borderColor: "divider" }}
        />
        <Box
          ref={zoomBoxRef}
          sx={{
            pointerEvents: "none",
            display: open ? "block" : "none",
            zIndex: 100,
            width: open
              ? overlayRectRef.current?.offsetWidth! * ZOOM_RATIO + "px"
              : 0,
            height: open
              ? overlayRectRef.current?.offsetHeight! * ZOOM_RATIO + "px"
              : 0,
            backgroundImage: `url(${src})`,
            position: "absolute",
            left: "101%",
            top: "20%",
          }}></Box>
      </Box>
    </>
  );
};
