import { FC, useState, useMemo } from "react";
import {
  FilterType,
  FilterValue,
  TransformedFilterEntity,
} from "../../../types/transformedFilterEntity";
import { camelCaseToTitleCase } from "../../../utils";

function compare(a: any, b: any) {
  if (a.name < b.name) {
    return -1;
  }
  if (a.name > b.name) {
    return 1;
  }
  return 0;
}

export const MobileFIlterTabs: FC<{
  filters: TransformedFilterEntity[];
  appliedFilters: { [key in FilterType]: string[] };
  onChangeAppliedFilter: (
    name: keyof typeof FilterType,
    option: FilterValue
  ) => void;
}> = ({ filters, appliedFilters, onChangeAppliedFilter }) => {
  const [activeTabValue, setActiveTabValue] = useState(0);

  const sortedFilters = useMemo(() => {
    return filters.sort(compare);
  }, [filters]);

  const { name, value } = sortedFilters[activeTabValue];

  return (
    <div className="flex h-full">
      <div className="flex flex-col shadow gap-4 py-2">
        {filters.map((f, index) => (
          <p
            key={`${f.name}-${index}`}
            className="py-1 px-2 active:bg-gray-200 transition-all text-sm text-gray-700"
            onClick={() => setActiveTabValue(index)}>
            {camelCaseToTitleCase(f.name)}
          </p>
        ))}
      </div>
      <div className="flex-1 p-3 flex flex-col gap-6">
        {value.sort(compare).map((option, index) => {
          return (
            <div key={`${option}-${index}`} className="flex items-center gap-3">
              <input
                id={`${option}-${index}`}
                type="checkbox"
                onChange={() => onChangeAppliedFilter(name, option)}
                checked={appliedFilters[name]?.includes(option.name)}
                className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 focus:ring-2"
              />
              <label
                htmlFor={`${option}-${index}`}
                className="ml-2 text-sm font-semibold text-gray-900">
                {option.name}
              </label>
            </div>
          );
        })}
      </div>
    </div>
  );
};
