import { CapturePaymentResponse } from "../../../types/capture_pyament_type";
import { NetworkCall } from "../NetworkCall";

export class Razorpay {
  static razorpayPush = async (body: any) => {
    const res = (await NetworkCall.mutate({
      url: `${process.env.BASE_URL}/customer/orders/capture`,
      auth: "token",
      method: "POST",
      body,
    })) as CapturePaymentResponse;
    window.parent.location.href = `https://jumkey.com/checkout/thankyou?id=${res.result.orderId}&eventId=${res.result.eventId}`;
  };
}
