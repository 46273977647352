import React from "react";
import { Avatar, CircularProgress, Stack, Typography } from "@mui/material";
import { useGetTestimonialsDataQuery } from "../../../../store";
import { PopupViewer } from "../../../molecules";
import { StoryContainer } from "../Library";
import { TestimonialDetails } from "./TestimonialDetails";

export const TestimonialStories = () => {
  const { data, isLoading } = useGetTestimonialsDataQuery();
  const [open, setOpen] = React.useState(false);

  if (isLoading) {
    return <CircularProgress size={68} />;
  }

  if (!data) return null;

  return (
    <div>
      <Stack gap={0.25} alignItems="center">
        <Avatar
          onClick={() => setOpen(true)}
          sx={{ width: 64, height: 64 }}
          src="https://cdn.picpil.com/testimonials/2023/05/128/testimonial.Jpg"
        />
        <Typography variant="body2">Testimonials</Typography>
      </Stack>
      <PopupViewer open={open} setOpen={setOpen} ignoreFullWidth>
        <StoryContainer
          length={data.testimonials.length}
          content={data.testimonials.map(t =>
            t.imageUrl.map(i => ({ url: i, type: "image", duration: 5000 }))
          )}>
          {({ activeStep }) => (
            <TestimonialDetails
              testimonial={data.testimonials[activeStep]}
              close={() => setOpen(false)}
            />
          )}
        </StoryContainer>
      </PopupViewer>
    </div>
  );
};
