import { useEffect } from "react";
import { useRouter } from "next/router";
import { Razorpay } from "../services";

export const useRazorpay = () => {
  const router = useRouter();
  useEffect(() => {
    const { query } = router;
    const {
      razorpay_invoice_id,
      razorpay_invoice_receipt,
      razorpay_invoice_status,
      razorpay_payment_id,
      razorpay_signature,
    } = query;
    if (
      razorpay_invoice_id &&
      razorpay_invoice_receipt &&
      razorpay_invoice_status &&
      razorpay_payment_id &&
      razorpay_signature
    ) {
      Razorpay.razorpayPush({
        invoiceId: razorpay_invoice_id,
        paymentId: razorpay_payment_id,
        paymentStatus: razorpay_invoice_status,
        receipt: razorpay_invoice_receipt,
        signature: razorpay_signature,
      });
    }
  }, [router]);
};
