export const sortOptions = [
  {
    id: 1,
    name: "Newest First",
    value: "createdAt desc",
  },
  {
    id: 2,
    name: "Relevance",
    value: "",
  },
  {
    id: 3,
    name: "Best Selling",
    value: "totalOrders desc",
  },
  {
    id: 5,
    name: "Price: High to Low",
    value: "sellingPrice desc",
  },
  {
    id: 6,
    name: "Price: Low to High",
    value: "sellingPrice asc",
  },
];
