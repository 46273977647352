import { GAAppbarSearchEntity, GAMenuClickEntity } from "../../types";
import { CoreAdapter } from "./CoreAdapter";

class Appbar extends CoreAdapter {
  appbarSearchAdapter = ({
    keyword,
    searchUrl,
    products,
    eventId,
  }: GAAppbarSearchEntity) => {
    return {
      event: "search",
      ecommerce: {
        eventId,
        keyword,
        searchUrl,
        products: products.map((p, index) =>
          this.convertTransformedProductToAnalyticsFormat(p, index)
        ),
      },
    };
  };

  menuItemClickAdapter = (menuItem: GAMenuClickEntity) => {
    return {
      event: "menu_click",
      ecommerce: {
        page_url: menuItem.pageUrl,
        menu_top_level_name: menuItem.menuTopLevelName,
        menu_name: menuItem.menuName,
        menu_link: menuItem.menuLink,
      },
    };
  };
}

export const appbarAdapter = new Appbar();
