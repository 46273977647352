import { FC, useState } from "react";
import Link from "next/link";
import {
  ListItemButton,
  ListItemText,
  Popover,
  Typography,
} from "@mui/material";
import { useRouter } from "next/router";
import { useTheme } from "@mui/material/styles";

import { analytics } from "../../../../../src/analytics";
import { NextLevelMenuItem } from "../../../../../types/navigation";
import { FiChevronDown } from "react-icons/fi";

interface Props {
  menus: NextLevelMenuItem[];
}

const Navlinks: FC<Props> = ({ menus }) => {
  const router = useRouter();
  const [selectedMenu, setSelectedMenu] = useState<NextLevelMenuItem | null>(
    null
  );

  const {
    palette: { mode },
  } = useTheme();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setSelectedMenu(null);
  };

  return (
    <>
      <div className="relative flex items-center gap-3" style={{ zIndex: 900 }}>
        {menus.map(menu => {
          const isLink = menu.columns.length === 0;
          return (
            <ListItemButton
              key={menu.name}
              disableGutters
              selected={menu.name === selectedMenu?.name}
              onClick={e => {
                if (isLink) {
                  router.push(menu.redirectLink);
                } else {
                  handleClick(e);
                  setSelectedMenu(menu);
                }
              }}
              dense
              sx={{ gap: 0.5, alignItems: "center", px: 1 }}>
              <ListItemText
                primary={menu.name}
                primaryTypographyProps={{
                  className: `text-sm font-normal ${
                    selectedMenu &&
                    selectedMenu.name !== menu.name &&
                    "text-gray-500"
                  } ${
                    selectedMenu?.name === menu.name &&
                    "font-bold text-gray-900"
                  }`,
                }}
              />
              {!isLink && <FiChevronDown />}
            </ListItemButton>
          );
        })}
      </div>
      <Popover
        disablePortal
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={{ top: 150, left: 0 }}>
        {selectedMenu && (
          <div className="flex justify-between p-6" style={{ width: "96.5vw" }}>
            {selectedMenu?.columns.map(col => {
              return (
                <div className="space-y-3">
                  <Typography
                    variant="subtitle2"
                    textTransform="uppercase"
                    fontWeight={700}>
                    {col.header}
                  </Typography>
                  <div className="flex flex-col gap-3">
                    {col.values.map(val => {
                      return (
                        <Link key={val.label} href={val.redirectLink}>
                          <div
                            onClick={() => {
                              setSelectedMenu(null);
                              handleClose();
                              analytics.pushMenuClick({
                                menuLink: val.redirectLink,
                                menuName: val.label,
                                menuTopLevelName: selectedMenu.name,
                                pageUrl: router.asPath,
                              });
                            }}>
                            <p className="text-sm">{val.label}</p>
                          </div>
                        </Link>
                      );
                    })}
                  </div>
                </div>
              );
            })}
            <Link href={selectedMenu.redirectLink}>
              <img
                src={selectedMenu.imageUrl}
                className="w-60 h-60 object-cover"
              />
            </Link>
          </div>
        )}
      </Popover>
    </>
  );
};

export default Navlinks;
