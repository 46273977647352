import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import Image from "next/image";
import Link from "next/link";
import { FC } from "react";
import { Carousel } from "../../../components/Carousels";

interface Props {
  imageUrl: string[];
  testimonial: string;
  customerName: string;
  productUrl: string | undefined;
}

export const TestimonialCard: FC<Props> = ({
  imageUrl,
  testimonial,
  customerName,
  productUrl,
}) => {
  return (
    <Card elevation={0}>
      <Box sx={{ height: "320px", bgcolor: grey[50] }}>
        {imageUrl.length > 1 ? (
          <Carousel autoplay={false} type="media">
            {imageUrl.map(image => (
              <Box sx={{ height: "320px" }} key={image}>
                <CardMedia
                  key={image}
                  component="img"
                  src={image}
                  sx={{ width: "100%", height: "100%", objectFit: "contain" }}
                />
              </Box>
            ))}
          </Carousel>
        ) : (
          <CardMedia
            component="img"
            src={imageUrl[0]}
            sx={{ width: "100%", height: "100%", objectFit: "contain" }}
          />
        )}
      </Box>
      <CardContent>
        <Typography
          variant="subtitle1"
          fontWeight={700}
          gutterBottom
          textTransform="capitalize">
          {customerName}
        </Typography>
        <Box sx={{ height: "100px", overflowY: "auto" }}>
          <Typography variant="body2">{testimonial}</Typography>
        </Box>
      </CardContent>
      {productUrl && (
        <CardActions>
          <Link href={productUrl}>
            <Button size="small" variant="contained">
              View Product
            </Button>
          </Link>
        </CardActions>
      )}
    </Card>
  );
};
