import { GAAddToWishlistEntity, GAViewWishlistEntity } from "../../types";
import { CoreAdapter } from "./CoreAdapter";

class Wishlist extends CoreAdapter {
  modifyWishlistAdapter = ({
    product,
    type,
    eventId,
  }: GAAddToWishlistEntity) => {
    return {
      event: type === "add" ? "add_to_wishlist" : "remove_from_wishlist",
      ecommerce: {
        eventId,
        currency: CoreAdapter.activeCurrency,
        click: {
          value: product.price.find(p => p.code === CoreAdapter.activeCurrency)
            ?.sellingPrice,
          products: this.convertTransformedProductToAnalyticsFormat(
            product,
            -1
          ),
        },
      },
    };
  };

  viewWishlistAdapter = ({ products }: GAViewWishlistEntity) => {
    window.dataLayer.push({
      event: "view_wishlist",
      ecommerce: {
        currency: CoreAdapter.activeCurrency,
        value: products.reduce((acc, product) => {
          const activePrice = product.price.find(
            p => p.code === CoreAdapter.activeCurrency
          );
          if (activePrice) {
            return acc + activePrice.sellingPrice;
          } else {
            return acc;
          }
        }, 0),
        products: products.map((product, index) => {
          return this.convertTransformedProductToAnalyticsFormat(
            product,
            index + 1
          );
        }),
      },
    });
  };
}

export const wishlistAdapter = new Wishlist();
