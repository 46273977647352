import { FormData } from "../../../../types/form_types";

export const formData: FormData = {
  token: "1fbe0c95644c4b7f88c9ccce85c5084d",
  route: "/items/images",
  id: 5,
  organizationId: 1001,
  groupType: "DEFAULT",
  designType: "OTHERS",
  name: "PIM Images",
  apiRoute: "/v6/admin/items/image",
  groups: [
    {
      title: "Create review",
      formId: 5,
      startIndex: 0,
      components: [
        {
          jsonName: "reviewedBy",
          allowedValues: [""],
          maxAllowedValues: 0,
          id: 29,
          regEx: "/^[a-zA-Z0-9-_]{3,30}$/",
          formId: 5,
          isMultiValued: false,
          isMandatory: true,
          displayName: "Reviewed By",
          groupId: 5,
          type: "text",
          isValidationReqd: true,
          value: "",
          component: "textInput",
        },
        {
          jsonName: "rating",
          allowedValues: [""],
          maxAllowedValues: 0,
          id: 29,
          regEx: "/^[a-zA-Z0-9-_]{3,30}$/",
          formId: 5,
          isMultiValued: false,
          isMandatory: true,
          displayName: "Rate the product",
          groupId: 5,
          type: "text",
          isValidationReqd: true,
          value: "",
          component: "rating",
        },
        {
          jsonName: "headline",
          allowedValues: [""],
          maxAllowedValues: 0,
          id: 21,
          regEx: "/^[a-zA-Z0-9-_]{3,30}$/",
          formId: 5,
          isMultiValued: false,
          isMandatory: true,
          displayName: "Add a headline",
          groupId: 5,
          type: "text",
          isValidationReqd: true,
          value: "",
          component: "textInput",
        },
        {
          formId: 5,
          isMultiValued: false,
          allowedValues: [""],
          regEx: "",
          displayName: "Add a written review",
          jsonName: "description",
          groupId: 5,
          isMandatory: true,
          maxAllowedValues: 1,
          type: "image",
          isValidationReqd: false,
          id: 25,
          value: "",
          component: "textArea",
        },
        {
          formId: 5,
          isMultiValued: false,
          allowedValues: [""],
          regEx: "",
          displayName: "Add a photo-1",
          isMandatory: false,
          groupId: 5,
          jsonName: "imageUrl1",
          maxAllowedValues: 3,
          type: "image",
          isValidationReqd: false,
          id: 26,
          value: "",
          component: "fileInput",
        },
        {
          formId: 5,
          isMultiValued: false,
          allowedValues: [""],
          displayName: "Add a photo-2",
          regEx: "",

          isMandatory: false,
          groupId: 5,
          jsonName: "imageUrl2",
          maxAllowedValues: 3,
          type: "image",
          isValidationReqd: false,
          id: 26,
          value: "",
          component: "fileInput",
        },
        {
          formId: 5,
          isMultiValued: false,
          allowedValues: [""],
          displayName: "Add a photo-3",
          regEx: "",
          isMandatory: false,
          groupId: 5,
          jsonName: "imageUrl3",
          maxAllowedValues: 3,
          type: "image",
          isValidationReqd: false,
          id: 26,
          value: "",
          component: "fileInput",
        },
      ],
      indexReached: 0,
      id: 5,
    },
  ],
};
