import { Avatar, Breadcrumbs, ListItemButton } from "@mui/material";
import React from "react";
import { useLocaleContext } from "../../context/LocaleContext/LocaleContext";

export const LocaleToggler = () => {
  const { showDialog, language, country, currency, symbol, flag } =
    useLocaleContext();
  return (
    <div
      id="localeToggler"
      className="flex items-center gap-4 py-2 px-4 text-gray-700 text-sm hover:bg-gray-100 focus:bg-gray-100"
      onClick={showDialog}>
      <Avatar
        sx={{
          width: "18px",
          height: "18px",
        }}
        alt="Flag"
        src={flag}
      />
      <Breadcrumbs separator="|" sx={{ cursor: "pointer" }}>
        <span className="text-gray-900 text-sm">{country}</span>
        <span className="text-gray-900 text-sm">{language}</span>
        <span className="text-gray-900 text-sm">
          {currency} ( {symbol} )
        </span>
      </Breadcrumbs>
    </div>
  );
};
