import {
  Box,
  Collapse,
  Container,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import React, { useState, ReactNode, FC } from "react";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { useGetFooterQuery } from "../../../store";
import { useScreenSize } from "../../hooks/useScreenSize";
import { FooterMain } from "./Footer";
import { grey } from "@mui/material/colors";

interface Props {
  footerData?: any;
}

export const Footer: FC<Props> = ({ footerData }) => {
  const { isMobile } = useScreenSize();
  const [expanded, setExpanded] = useState(true);
  let component: ReactNode = <FooterMain data={footerData} />;
  if (isMobile) {
    component = (
      <>
        <div>
          <ListItem disableGutters>
            <ListItemButton onClick={() => setExpanded(!expanded)}>
              <ListItemIcon sx={{ fontSize: "1.2rem" }}>
                {expanded ? <FiChevronUp /> : <FiChevronDown />}
              </ListItemIcon>
              <ListItemText primary="More about online shopping at Jumkey." />
            </ListItemButton>
          </ListItem>
        </div>
        <Collapse in={expanded} unmountOnExit>
          <FooterMain data={footerData} />
        </Collapse>
      </>
    );
  }
  return (
    <Box sx={{ bgcolor: grey[50] }}>
      <Container maxWidth="lg">{component}</Container>
    </Box>
  );
};
