import { FC, useState } from "react";
import { Button, Link, Typography } from "@mui/material";
import { FiStar } from "react-icons/fi";
import { CreateReviewDialog } from "./CreateReviewDialog";
import { closePageLoader, showPageLoader } from "../../atoms";

export const ReviewHeader: FC<{
  averageRating: number;
  submitHandler: (values: any) => void;
}> = ({ averageRating, submitHandler }) => {
  const [showAddReviewModal, setShowAddReviewModal] = useState(false);
  const closeReviewModalHandler = () => setShowAddReviewModal(false);
  const onSubmit = async (values: any) => {
    showPageLoader();
    await submitHandler(values);
    closePageLoader();
    closeReviewModalHandler();
  };
  return (
    <div className="space-y-3">
      <div className="flex justify-between items-center">
        <Typography variant="subtitle1">Ratings and reviews</Typography>
        <Link
          onClick={() => setShowAddReviewModal(true)}
          underline="none"
          variant="body2"
          sx={{ cursor: "pointer" }}>
          Add Review
        </Link>
      </div>
      <div className="">
        <div className="flex gap-2 items-center">
          <span className="text-4xl font-bold">{averageRating}</span>
          <span className="text-3xl flex items-center">
            <FiStar className="leading-none fill-yellow-300 stroke-yellow-600" />
          </span>
        </div>
        <p className="text-slate-700 dark:text-slate-300 text-sm">
          Average rating from all customers.
        </p>
      </div>
      <CreateReviewDialog
        open={showAddReviewModal}
        onClose={closeReviewModalHandler}
        submitHandler={onSubmit}
      />
    </div>
  );
};
