import { forwardRef } from "react";
import { nanoid } from "nanoid";
import { Paper } from "@mui/material";
import { TransformedProductEntity } from "../../../types/transformed_product_entity";
import SearchDropdownMain from "./search_dropdown_main/SearchDropdownMain";
import { ListItemGroup } from "../../../src/atoms";

interface Props {
  products: TransformedProductEntity[];
  redirect: () => void;
  closeDropdown: () => void;
}

const suggestions = [
  { id: nanoid(), label: "Necklace", contentHandle: "/collections/necklace" },
  { id: nanoid(), label: "Earring", contentHandle: "/collections/earrings" },
  {
    id: nanoid(),
    label: "Mangalsutra",
    contentHandle: "/collections/mangalsutra",
  },
  { id: nanoid(), label: "Anklet", contentHandle: "/collections/anklet" },
  {
    id: nanoid(),
    label: "Jewellery set",
    contentHandle: "/collections/jewellery-set",
  },
];
const helpsection = [
  {
    id: nanoid(),
    label: "Returns",
    contentHandle: "/policies/returns-and-refunds",
  },
  { id: nanoid(), label: "Order Tracking", contentHandle: "/orders" },
  { id: nanoid(), label: "FAQs", contentHandle: "/policies/faq" },
  {
    id: nanoid(),
    label: "Privacy Policy",
    contentHandle: "/policies/privacy-policy",
  },
];

const SearchDropdown = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { products, redirect, closeDropdown } = props;
  return (
    <Paper
      component="div"
      elevation={4}
      ref={ref}
      sx={{
        width: { xs: "100%", lg: "100%" },
        height: "75vh",
        overflowY: "scroll",
        position: "absolute",
        top: "110%",
        left: "0",
        zIndex: "10000",
      }}>
      <div className="grid grid-cols-12 p-3 gap-6">
        <div className="md:sticky md:top-0 md:left-0 flex flex-col gap-6 mt-1 col-span-12 md:col-span-2 xl:col-span-2">
          <ListItemGroup
            title="Suggestions"
            listItems={suggestions}
            onClick={() => closeDropdown()}
          />
          <ListItemGroup
            title="Help section"
            listItems={helpsection}
            chipProps={{ variant: "outlined" }}
            onClick={() => closeDropdown()}
          />
        </div>
        <div className="col-span-12 md:col-span-10">
          <SearchDropdownMain
            products={products}
            closeDropdown={closeDropdown}
            handleViewAll={redirect}
          />
        </div>
      </div>
    </Paper>
  );
});

SearchDropdown.displayName = "SearchDropdown";

export default SearchDropdown;
