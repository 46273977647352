import { CardHeader, Stack, Typography } from "@mui/material";
import React, { FC } from "react";
import { loginStateType } from "../LoginMain";

export const LoginHeader: FC<{ activeState: loginStateType }> = ({
  activeState,
}) => {
  const getSubHeader = () => {
    switch (activeState) {
      case "mobileOTP":
      case "emailOTP":
        return `Please enter a valid ${
          activeState === "emailOTP" ? "email id" : "mobile number"
        } to receive an OTP`;
      case "OTPValue":
        return "OTP sent to your device successfully";
    }
  };
  return (
    <Stack gap={0.25}>
      <Typography variant="h6" fontWeight={700}>
        {activeState === "OTPValue" ? "Verify OTP" : "Login or Sign up"}
      </Typography>
      <Typography variant="body2" color="text.secondary">
        {getSubHeader()}
      </Typography>
    </Stack>
  );
};
