import React, { FC, useEffect } from "react";
import { useFormik } from "formik";
import { Button, TextField, Typography } from "@mui/material";
import toast from "react-hot-toast";
import * as yup from "yup";

import { User, useUpdateProfileMutation } from "../../../store";
import { CustomTextField } from "../../atoms/CustomTextField";

interface Props {
  title?: string;
  data: User;
  firstName: string;
  lastName: string;
  email?: string;
  number?: string;
  onSubmitEnd?: () => void;
}

const validationSchema = yup.object({
  firstName: yup
    .string()
    .min(3, "First name must be minimum 3 characters long.")
    .matches(
      /^[A-Za-z .]*$/,
      "Only alphabets are allowed for this field (numbers and special characters are not allowed)."
    )
    .required("First name required."),
  lastName: yup
    .string()
    .min(3, "Last name must be minimum 3 characters long.")
    .matches(
      /^[A-Za-z .]*$/,
      "Only alphabets are allowed for this field (numbers and special characters are not allowed)."
    )
    .required("Last name required"),
});

export const EditProfile: FC<Props> = ({
  title,
  data,
  firstName,
  lastName,
  email,
  number,
  onSubmitEnd,
}) => {
  const [editProfile, { isLoading, isSuccess, reset }] =
    useUpdateProfileMutation();
  const { values, errors, touched, handleSubmit, handleChange } = useFormik({
    initialValues: {
      firstName,
      lastName,
      email,
    },
    validationSchema,
    onSubmit: async values => {
      await editProfile({
        ...data,
        firstname: (values.firstName as string).replaceAll(".", " "),
        lastname: values.lastName,
        emailId: email ? values.email : data.emailId,
      });
      if (onSubmitEnd) {
        onSubmitEnd();
      }
    },
  });

  useEffect(() => {
    if (isSuccess) {
      toast.success("Profile updated successfully.");
      reset();
    }
  }, [isSuccess]);

  return (
    <div className="space-y-6">
      <div>
        <Typography variant="subtitle1" fontWeight={700}>
          {title ? title : "Edit Profile"}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Please add the profile details mentioned below to continue shopping
        </Typography>
      </div>
      <form onSubmit={e => e.preventDefault()} className="flex flex-col gap-4">
        <CustomTextField
          required
          id="firstName"
          label="First Name"
          name="firstName"
          value={values.firstName}
          onChange={handleChange}
          error={touched.firstName && Boolean(errors.firstName)}
          helperText={touched.firstName && errors.firstName}
        />
        <CustomTextField
          required
          id="lastName"
          label="Last Name"
          name="lastName"
          value={values.lastName}
          onChange={handleChange}
          error={touched.lastName && Boolean(errors.lastName)}
          helperText={touched.lastName && errors.lastName}
        />
        {email !== undefined && (
          <CustomTextField
            id="email"
            label="Email id"
            name="email"
            value={values.email}
            onChange={handleChange}
            error={touched.email && Boolean(errors.email)}
            helperText={touched.email && errors.email}
          />
        )}
        {number !== undefined && (
          <CustomTextField label="Contact number" disabled value={number} />
        )}
        <Button
          sx={{ mt: 3 }}
          type="submit"
          variant="contained"
          size="large"
          onClick={() => handleSubmit()}
          disabled={isLoading}>
          Save
        </Button>
      </form>
    </div>
  );
};
