import React from "react";
import { LoginResponse, login } from "../../store";
import { parseJwt } from "../../utils";
import { analytics } from "../analytics";
import { useRouter } from "next/router";
import { useTypedDispatch } from "../../hooks/useTypedDispatch";

export const useLoginSuccess = () => {
  const dispatch = useTypedDispatch();
  const router = useRouter();
  const pushLoginToDatalayer = (data: LoginResponse) => {
    const { emailId, mobileNumber, firstname, lastname } = data.customer;
    const { aud } = parseJwt(data.accessToken);
    analytics.pushLogin({
      userId: aud,
      userName: `${firstname} ${lastname}`,
      email: emailId,
      number: mobileNumber,
    });
  };

  const redirectAfterLogin = () => {
    if (window.history.state.idx !== 0) {
      router.back();
    } else {
      router.replace("/");
    }
  };

  const onLoginSuccess = (data: LoginResponse) => {
    pushLoginToDatalayer(data);
    dispatch(login({ token: data.accessToken }));
    redirectAfterLogin();
  };

  return onLoginSuccess;
};
