import { FC, useState } from "react";
import {
  CardMedia,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  Stack,
  TextField,
} from "@mui/material";
import { useField } from "formik";
import { FiUpload } from "react-icons/fi";

interface Props {
  name: string;
  label: string;
  type: "image" | "csv";
}

export const FileInput: FC<Props> = ({ name, label, type }) => {
  const [fileInputValue, setFileInputValue] = useState("");
  const [_, meta, helpers] = useField({ name });
  const { touched, error } = meta;
  return (
    <Stack spacing={0.5}>
      <InputLabel>{label}</InputLabel>
      <TextField
        size="small"
        onChange={(e: any) => {
          if (type === "image") {
            let reader = new FileReader();
            reader.readAsDataURL(e.target.files[0]);
            reader.onloadend = () => {
              setFileInputValue(reader.result as string);
            };
          }
          helpers.setValue(e.target.files[0]);
        }}
        type="file"
        inputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton aria-label="upload">
                <FiUpload />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      {fileInputValue && (
        <CardMedia
          component="img"
          sx={{ width: "200px" }}
          alt="img"
          src={fileInputValue}
        />
      )}
      <FormHelperText error={touched.valueOf() && Boolean(error)}>
        {touched.valueOf() && error}
      </FormHelperText>
    </Stack>
  );
};
