import { CoreAdapter } from "./shared/CoreAdapter";

class Login extends CoreAdapter {
  loginAdapter = ({
    userId,
    userName,
    email,
    number,
  }: {
    userId: string;
    userName: string;
    email: string;
    number: string;
  }) => {
    return {
      event: "login",
      ecommerce: {
        id: userId,
        userName,
        email,
        number,
      },
    };
  };

  logoutAdapter = ({
    pageUrl,
    userId,
  }: {
    pageUrl: string;
    userId: string;
  }) => {
    return {
      event: "logout",
      ecommerce: {
        ref_page_url: pageUrl,
        id: userId,
      },
    };
  };

  loginFailedAdapter = ({
    option,
    reason,
  }: {
    option: "email" | "otp";
    reason: string;
  }) => {
    return {
      event: "login_failure",
      ecommerce: {
        option_name: option,
        reason,
      },
    };
  };
}

export const loginAdapter = new Login();
