import { TransformedOrderEntity } from "./../../../types/order_types";
import {
  OrderbyidResponse,
  OrdersResponse,
  TransformedOrderDetailsEntity,
} from "./Types";

export const orderTransformer = (
  data: OrdersResponse
): TransformedOrderEntity => {
  return {
    count: data.count,
    orders: data.orders.map(order => {
      return {
        id: order.orderId,
        priceData: {
          discount: order.discountValue,
          shippinCharges: order.shipmentChargesValue,
          tax: order.taxValue,
          subTotal: order.totalValue,
        },
        orderNumber: order.orderNumber,
        orderStatus: order.orderStatus,
        totalProducts: order.lineitems.length,
        createdAt: order.createdAt,
        items: order.lineitems.map(item => {
          const meta = JSON.parse(item.metaData);
          return {
            id: item.itemId,
            quantity: item.quantity,
            title: meta.title,
            size: meta.size,
            category: meta.category,
            currencySymbol: item.currencyCode,
            priceBoughtAt: item.totalValue,
            img: item.variantImage,
            variantId: item.variantId,
            brand: meta.brand,
          };
        }),
      };
    }),
  };
};

export const singleOrderTransformer = (
  data: OrderbyidResponse
): TransformedOrderDetailsEntity => {
  const { orders, shippingAddress, billingAddress } = data;
  return {
    id: orders.orderId,
    checkoutToken: orders.checkoutToken,
    cartToken: orders.cartToken,
    currency: orders.currencyInformation.currencyCode === "USD" ? "$" : "₹",
    salesAgent: orders.salesAgent,
    orderType: orders.priceRuleId === 0 ? "COD" : "PREPAID",
    priceData: {
      discount: orders.discountValue,
      shippinCharges: orders.shipmentChargesValue,
      tax: orders.taxValue,
      subTotal: orders.totalValue,
    },
    customer: {
      id: orders.customer.id,
      name: orders.customer.name,
      email: orders.customer.email,
      mobile: orders.customer.mobile,
    },
    orderNumber: orders.orderNumber,
    orderStatus: orders.orderStatus,
    totalProducts: orders.lineitems.length,
    shippingAddress,
    billingAddress,
    createdAt: orders.createdAt,
    finentialStatus: orders.financialStatus,
    payment: orders.paymentObject,
    fulfillments: orders.fulfillments,
    items: orders.lineitems.map(item => {
      const meta = JSON.parse(item.metaData);
      return {
        id: item.itemId,
        quantity: item.quantity,
        title: meta.title,
        size: meta.size,
        category: meta.category,
        currencySymbol: orders.currencyInformation.currencySymbol,
        priceBoughtAt: item.totalValue,
        img: item.variantImage,
        variantId: item.variantId,
        brand: meta.brand,
      };
    }),
  };
};
