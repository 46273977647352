import { ReactNode, FC } from "react";
import MultiCarousel, { ResponsiveType } from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

interface Props {
  responsive: ResponsiveType;
  children: ReactNode[] | ReactNode;
}

export const ProductCardCarousel: FC<Props> = ({ responsive, children }) => {
  return (
    <MultiCarousel
      swipeable={true}
      responsive={responsive}
      rewind={true}
      containerClass="flex pb-1 pl-1">
      {children}
    </MultiCarousel>
  );
};
