import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ProductsResponseType } from "../../../types/product_listing_types";
import { TransformedProductEntity } from "../../../types/transformed_product_entity";
import { newProductTransformer } from "../../../utils";
import { singleOrderTransformer } from "../OrderApi/ResponseTransformers";
import {
  OrderbyidResponse,
  TransformedOrderDetailsEntity,
} from "../OrderApi/Types";
import {
  ReviewResponse,
  ReviewsEntity,
  TestimonialsResponse,
  OrderByWhatsAppPayload,
} from "./Types";

export const sharedApi = createApi({
  reducerPath: "sharedApi",
  refetchOnFocus: true,
  baseQuery: fetchBaseQuery({}),
  endpoints: builder => ({
    getFooter: builder.query<any, void>({
      query: () =>
        "https://swiftly-files.s3.ap-south-1.amazonaws.com/footer.json",
    }),
    getTopNavData: builder.query<any, void>({
      query: () =>
        "https://swiftly-files.s3.ap-south-1.amazonaws.com/topnav.json",
    }),
    getReview: builder.query<ReviewsEntity[], string>({
      query: id => `${process.env.BASE_URL}/listings/items/review?itemId=${id}`,
      transformResponse: (data: ReviewResponse) => {
        return data.reviews;
      },
    }),
    addReview: builder.mutation<any, any>({
      query: body => ({
        url: `${process.env.BASE_URL}/listings/items/review`,
        method: "POST",
        body,
      }),
    }),
    getOrderByToken: builder.query<TransformedOrderDetailsEntity, string>({
      query: id =>
        `https://api.jumkey.com/v5/admin/customer/orders/thankyou?token=${id}`,
      transformResponse: (data: OrderbyidResponse) =>
        singleOrderTransformer(data),
    }),
    getTestimonialsData: builder.query<TestimonialsResponse, void>({
      query: () => `${process.env.BASE_URL}/customer/testimonial_new?limit=400`,
    }),
    getProductRowData: builder.query<TransformedProductEntity[], string>({
      query: url => url,
      transformResponse: (data: ProductsResponseType) =>
        data.products.map(prod => newProductTransformer(prod)),
    }),
    getSearchResult: builder.query<
      { eventId: string; products: TransformedProductEntity[] },
      string
    >({
      query: value =>
        `${process.env.BASE_URL}/listings/items/search?query=${value}&limit=12&sortBy=totalOrders desc`,
      transformResponse: (data: ProductsResponseType) => ({
        eventId: data.eventId,
        products: data.products.map(prod => newProductTransformer(prod)),
      }),
    }),
    createOrderByWhatsApp: builder.mutation<any, OrderByWhatsAppPayload>({
      query: body => ({
        url: `${process.env.BASE_URL}/listings/items/order-by-whatsapp`,
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useGetFooterQuery,
  useGetTopNavDataQuery,
  useGetReviewQuery,
  useGetTestimonialsDataQuery,
  useAddReviewMutation,
  useGetOrderByTokenQuery,
  useLazyGetSearchResultQuery,
  useGetProductRowDataQuery,
  useCreateOrderByWhatsAppMutation,
} = sharedApi;
