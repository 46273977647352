import { ga } from "./GoogleAnalytics";
import {
  GAAddToCartEntity,
  GAAddToWishlistEntity,
  GAAppbarSearchEntity,
  GABannerEntity,
  GACheckoutAddressEntity,
  GACheckoutPaymentEntity,
  GACreateProductReview,
  GAFooterOptionEntity,
  GAHashTagClick,
  GAMenuClickEntity,
  GAOrderByWhatsappClickEntity,
  GAProductCardClickEntity,
  GAProductDetailsEntity,
  GAViewCartEntity,
  GAViewItemListEntity,
  GAViewReviewsEntity,
  GAViewWishlistEntity,
} from "./types";

class Analytics {
  //Product list
  pushViewItemList = (data: GAViewItemListEntity) => {
    ga.pushViewItemList(data);
  };

  //Product Card
  pushSelectItem = (data: GAProductCardClickEntity) => {
    ga.pushSelectItem(data);
  };
  pushHashTagClicked = (data: GAHashTagClick) => {
    ga.pushHashTagClicked(data);
  };

  //Product details
  pushViewItemDetails = (data: GAProductDetailsEntity) => {
    ga.pushViewItemDetails(data);
  };
  pushOrderByWhatsapp = (data: GAOrderByWhatsappClickEntity) => {
    ga.pushOrderByWhatsapp(data);
  };
  pushProuctReview = (data: GAViewReviewsEntity) => {
    ga.pushProuctReview(data);
  };
  pushWriteProductReview = (data: GACreateProductReview) => {
    ga.pushWriteProductReview(data);
  };

  //Wishlist
  pushModifyWishlist = (data: GAAddToWishlistEntity) => {
    ga.pushModifyWishlist(data);
  };
  pushViewWishlist = (data: GAViewWishlistEntity) => {
    ga.pushViewWishlist(data);
  };

  //Cart
  pushAddToCart = (data: GAAddToCartEntity) => {
    ga.pushAddToCart(data);
  };
  pushViewCart = (data: GAViewCartEntity) => {
    ga.pushViewCart(data);
  };

  //Checkout cart
  pushBeginCheckout = (data: GAViewCartEntity) => {
    ga.pushBeginCheckout(data);
  };

  //Checkout address
  pushAddShippingInfo = (data: GACheckoutAddressEntity) => {
    ga.pushAddShippingInfo(data);
  };

  //Checkout payment
  pushAddPaymentInfo = (data: GACheckoutPaymentEntity) => {
    ga.pushAddPaymentInfo(data);
  };

  //Thank you
  pushPurchase = (data: any) => {
    ga.pushPurchase(data);
  };
  //Grid
  pushCarouselBannerClick = (data: GABannerEntity) => {
    ga.pushCarouselBannerClick(data);
  };
  pushGridBannerClick = (data: GABannerEntity) => {
    ga.pushGridBannerClick(data);
  };

  //Aapbar
  pushSearch = (data: GAAppbarSearchEntity) => {
    ga.pushSearch(data);
  };
  pushMenuClick = (data: GAMenuClickEntity) => {
    ga.pushMenuClick(data);
  };

  pushFooterClick = (data: GAFooterOptionEntity) => {
    ga.pushFooterClick(data);
  };

  //Login page
  pushLogin = (data: {
    userId: string;
    userName: string;
    email: string;
    number: string;
  }) => {
    ga.pushLogin(data);
  };
  pushLogout = (data: { pageUrl: string; userId: string }) => {
    ga.pushLogout(data);
  };
  pushLoginFailure = (data: { option: "email" | "otp"; reason: string }) => {
    ga.pushLoginFailure(data);
  };

  //Error
  pushError = (data: { error: any }) => {
    ga.pushError(data);
  };
  pushUnauthorized = (data: { error: any }) => {
    ga.pushUnauthorized(data);
  };

  // pushOpenChat = (data: GACheckoutPaymentEntity) => {
  //   ga.pushOpenChat(data);
  // };
  // pushUpdateProfile = (data: GACheckoutPaymentEntity) => {
  //   ga.pushUpdateProfile(data);
  // };
}

export const analytics = new Analytics();
