import Box from "@mui/material/Box";
import { FC, ReactNode } from "react";

interface Props {
  gridTemplateColumns: any;
  gap: any;
  children: ReactNode;
  sx: any;
  id: any;
}

export const GridSection: FC<Props> = ({
  gridTemplateColumns,
  gap,
  children,
  sx,
  id,
}) => {
  return (
    <Box
      key={id}
      display="grid"
      gridTemplateColumns={gridTemplateColumns}
      gap={gap}
      sx={sx}>
      {children}
    </Box>
  );
};
