import { FC } from "react";
import { Card, CardHeader, Stack } from "@mui/material";
import { ReviewsEntity } from "../../../types/review_types";
import { grey } from "@mui/material/colors";

export const ReviewCard: FC<{ review: ReviewsEntity; onClick: () => void }> = ({
  review: { reviewedBy, description, imageUrl1, imageUrl2, imageUrl3 },
  onClick,
}) => {
  return (
    <Card sx={{ width: "100%" }}>
      <CardHeader
        title={reviewedBy}
        titleTypographyProps={{
          variant: "subtitle2",
          textTransform: "uppercase",
          fontWeight: 700,
        }}
        subheader={description}
        subheaderTypographyProps={{ variant: "body2" }}
      />
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{ p: 1, cursor: "pointer", bgcolor: grey[50] }}
        onClick={onClick}>
        {imageUrl1 && (
          <div className="flex-1 h-52">
            <img src={imageUrl1} className="w-full h-full object-contain" />
          </div>
        )}

        {imageUrl2 && (
          <div className="flex-1 h-40 bg-gray-100">
            <img src={imageUrl2} className="w-full h-full object-contain" />
          </div>
        )}

        {imageUrl3 && (
          <div className="flex-1 h-40 bg-gray-100">
            <img src={imageUrl3} className="w-full h-full object-contain" />
          </div>
        )}
      </Stack>
    </Card>
  );
};
