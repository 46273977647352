import { FC, useLayoutEffect } from "react";
import {
  Button,
  InputAdornment,
  TextField,
  MenuItem,
  Stack,
} from "@mui/material";
import * as Yup from "yup";
import { useFormik } from "formik";
import toast from "react-hot-toast";

import { mobileNumberRegexp } from "../../../../../utils";
import {
  useGetCountryListQuery,
  useRequestOtpMutation,
} from "../../../../../store";
import { CustomButton } from "../../../../atoms";
import { CustomTextField } from "../../../../atoms/CustomTextField";
import { loginDataType, loginStateType } from "../../LoginMain";

interface Props {
  setActiveState: (state: loginStateType) => void;
  setLoginData: (state: loginDataType) => void;
}

const validationSchema = Yup.object().shape({
  mobileNumber: Yup.string()
    .required("Mobile number can't be empty.")
    .matches(mobileNumberRegexp, {
      message: "Enter a valid mobile number",
    }),
});

export const MobileOtp: FC<Props> = ({ setActiveState, setLoginData }) => {
  const [requestOtp, { isLoading, isSuccess }] = useRequestOtpMutation();
  const { data: countryList } = useGetCountryListQuery();

  useLayoutEffect(() => {
    if (isSuccess) {
      toast.success("OTP sent successfully to your number");
      setActiveState("OTPValue");
      setLoginData({
        type: "mobile",
        value: values.dialCode + values.mobileNumber,
      });
    }
  }, [isSuccess]);

  const { values, handleChange, touched, errors, handleSubmit } = useFormik({
    initialValues: { mobileNumber: "", dialCode: "91" },
    onSubmit: async values => {
      await requestOtp({
        dialCode: values.dialCode,
        inputValue: values.mobileNumber,
      });
    },
    validationSchema,
    validateOnChange: true,
  });

  return (
    <>
      <Stack gap={2}>
        {countryList && (
          <CustomTextField
            label="Country Code"
            name="dialCode"
            select
            value={values.dialCode}
            onChange={handleChange}
            error={touched.dialCode && Boolean(errors.dialCode)}
            helperText={touched.dialCode && errors.dialCode}>
            {countryList.map(list => {
              return (
                <MenuItem key={list.dialCode} value={list.dialCode}>
                  {list.name}
                </MenuItem>
              );
            })}
          </CustomTextField>
        )}
        <CustomTextField
          id="mobileNumber"
          label="Mobile number"
          type="string"
          name="mobileNumber"
          value={values.mobileNumber}
          onChange={handleChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                {values.dialCode}
              </InputAdornment>
            ),
          }}
          required
          error={touched.mobileNumber && Boolean(errors.mobileNumber)}
          helperText={touched.mobileNumber && errors.mobileNumber}
        />
      </Stack>
      <Stack gap={1} sx={{ mt: 3 }}>
        <CustomButton
          loading={isLoading}
          fullWidth
          variant="contained"
          onClick={() => handleSubmit()}>
          Request OTP
        </CustomButton>
        <Button
          fullWidth
          variant="outlined"
          onClick={() => setActiveState("emailOTP")}>
          Login by email OTP
        </Button>
      </Stack>
    </>
  );
};
