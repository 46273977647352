import { useGetCouponsQuery } from "../../store";

export const useCoupons = () => {
  const { data, isLoading } = useGetCouponsQuery();

  const getCouponDescription = (index: number) => {
    const coupon = data!.coupons[index];
    const valueType = coupon.valueType;
    const bucket = coupon.buckets[coupon.buckets.length - 1];

    switch (valueType) {
      case "PERCENTAGE":
        return `${coupon.value}% Off, Max ₹${bucket.maxRedemptionValue}`;
      case "FIXED":
        return `Rs. ${coupon.value} flat Off.`;
    }
  };

  return {
    coupons: data?.coupons,
    getCouponDescription,
    isLoading,
  };
};
