import { GAFooterOptionEntity } from "../../types";
import { CoreAdapter } from "./CoreAdapter";

class Footer extends CoreAdapter {
  pushFooterMenuClick = (option: GAFooterOptionEntity) => {
    return {
      event: "footer_click",
      ecommerce: {
        page_url: option.pageUrl,
        option_name: option.optionName,
        option_link: option.optionLink,
      },
    };
  };
}

export const footerAdapter = new Footer();
