import React, { useState, FC, useEffect, memo } from "react";
import { Box, Stack } from "@mui/material";
import { nanoid } from "nanoid";

import { StoryMedia } from "./StoryMedia";
import { StoryProgress } from "./StoryProgress";

interface Props {
  content: { url: string; type: "image" | "video"; duration: number }[];
  changeStep: (type: "next" | "prev") => void;
  isPaused: boolean;
  setIsPaused: React.Dispatch<React.SetStateAction<boolean>>;
}

let timer: NodeJS.Timeout[] = [];
export const StoryMediaContainer: FC<Props> = memo(
  ({ content, changeStep, isPaused, setIsPaused }) => {
    const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

    const changeSlideIndex = (type: "next" | "prev") => {
      if (type === "next" && currentSlideIndex === content.length - 1)
        changeStep("next");
      else if (type === "prev" && currentSlideIndex === 0) changeStep("prev");
      else
        setCurrentSlideIndex(prev => {
          if (type === "next") return prev + 1;
          else return prev - 1;
        });
    };

    useEffect(() => {
      setCurrentSlideIndex(0);
    }, [content]);

    useEffect(() => {
      if (!isPaused) {
        const id = setInterval(() => {
          setCurrentSlideIndex(p => {
            if (p === content.length - 1) {
              changeStep("next");
              return 0;
            } else return p + 1;
          });
        }, 5000);
        timer.push(id);
        return () => {
          timer.forEach(t => clearInterval(t));
          timer = [];
        };
      }
    }, [isPaused, currentSlideIndex, content]);

    return (
      <>
        {content.map((i, index) => {
          if (index !== currentSlideIndex) return null;
          return (
            <Box key={nanoid()} sx={{ position: "relative" }}>
              <Stack
                direction="row"
                gap={0.5}
                sx={{
                  position: "absolute",
                  top: 10,
                  left: 10,
                  right: 10,
                  zIndex: 20,
                }}>
                {!isPaused && (
                  <>
                    {content.map((i, index) => {
                      return (
                        <StoryProgress
                          duration={i.duration}
                          key={index}
                          isActive={index === currentSlideIndex}
                          isCompleted={index < currentSlideIndex}
                        />
                      );
                    })}
                  </>
                )}
              </Stack>
              <StoryMedia
                url={i.url}
                type={i.type}
                isPaused={isPaused}
                play={() => setIsPaused(false)}
                pause={() => setIsPaused(true)}
                nextSlide={() => changeSlideIndex("next")}
                prevSlide={() => changeSlideIndex("prev")}
              />
            </Box>
          );
        })}
      </>
    );
  }
);
