import { ProfileResponse, User } from "./../../../types/profile_types";
import { NetworkCall } from "../NetworkCall";

export class Profile {
  static getProfileData = async () => {
    const data = (await NetworkCall.query({
      url: `${process.env.BASE_URL}/customerV2`,
      auth: "token",
    })) as ProfileResponse;
    return data.customer;
  };

  static updateProfile = async (body: Partial<User>) => {
    await NetworkCall.mutate({
      url: `${process.env.BASE_URL}/customerV2`,
      method: "PUT",
      auth: "token",
      body,
    });
    return await this.getProfileData();
  };
}
