import {
  BottomNavigation,
  BottomNavigationAction,
  Button,
  Container,
  Divider,
  Stack,
} from "@mui/material";
import { FC, ReactNode } from "react";
import { FiFilter, FiSliders } from "react-icons/fi";
import { RiPriceTag3Line } from "react-icons/ri";
import { TransformedFilterEntity } from "../../../types/transformedFilterEntity";
import { useDrawerDispatch } from "../../context";
import { RangeSlider } from "../../molecules";
import { queryFiltersType } from "../../templates/Productlistings/hooks/useProductListingFilter/useProductListingFilter";
import { QueryFiilters } from "./QueryFilters/QueryFilters";
import { SortFilter } from "./SortFilter";
import {
  AiOutlineFilter,
  AiOutlineSortAscending,
  AiOutlineSortDescending,
} from "react-icons/ai";
import { grey } from "@mui/material/colors";
import { BsSortDown } from "react-icons/bs";

interface Props {
  filters: TransformedFilterEntity[];
  filterQuery: queryFiltersType;
  setFilterQuery: (
    value: queryFiltersType | ((prevVar: queryFiltersType) => queryFiltersType)
  ) => void;
  sortBy: string;
  setSortBy: (sortBy: string) => void;
  setPriceRange: (priceRange: any) => void;
  priceRange: any;
}

export const ProductListingMobileFilters: FC<Props> = ({
  filters,
  filterQuery,
  setFilterQuery,
  sortBy,
  setSortBy,
  priceRange,
  setPriceRange,
}) => {
  const drawerDispatch = useDrawerDispatch();

  const handleChange = (newValue: string) => {
    let component: ReactNode;
    if (newValue === "filters") {
      component = (
        <QueryFiilters
          filters={filters}
          filterQuery={filterQuery}
          setFilterQuery={setFilterQuery}
        />
      );
    } else if (newValue === "range") {
      component = (
        <Container sx={{ py: 2 }}>
          <RangeSlider
            priceRange={priceRange}
            setPriceRange={value => {
              setPriceRange(value);
              drawerDispatch({ type: "CLOSE_DRAWER" });
            }}>
            <RangeSlider.Label>Price range</RangeSlider.Label>
            <RangeSlider.Slider />
            <RangeSlider.Message />
          </RangeSlider>
        </Container>
      );
    } else {
      component = (
        <SortFilter
          sortBy={sortBy}
          setSortBy={(sortBy: string) => {
            setSortBy(sortBy);
            drawerDispatch({ type: "CLOSE_DRAWER" });
          }}
        />
      );
    }
    drawerDispatch({
      type: "OPEN_DRAWER",
      payload: {
        component,
        side: "bottom",
      },
    });
  };

  return (
    <Stack
      alignItems="center"
      p={1}
      bgcolor={grey[50]}
      direction="row"
      divider={<Divider orientation="vertical" flexItem />}>
      <Button
        fullWidth
        startIcon={<AiOutlineFilter />}
        size="small"
        color="inherit"
        onClick={() => handleChange("filters")}>
        Filters
      </Button>
      <Button
        startIcon={<RiPriceTag3Line />}
        fullWidth
        size="small"
        color="inherit"
        onClick={() => handleChange("range")}>
        Price
      </Button>
      <Button
        startIcon={<AiOutlineSortDescending />}
        fullWidth
        size="small"
        color="inherit"
        onClick={() => handleChange("sort")}>
        Sort
      </Button>
    </Stack>
  );
};
