import { Formik } from "formik";
import { FC, useState, useContext, createContext, ReactNode } from "react";
import { FormData } from "../../../types/form_types";
import { FormContent } from "./FormContents";
import { FormSubmitButtons } from "./FormSubmitButtons";
import { FormTab } from "./FormTab";
import * as Yup from "yup";

interface Props {
  data: FormData;
  children: ReactNode;
  submitHandler: (data: any) => void;
}

interface CompoundForm {
  Tabs: typeof FormTab;
  ActionButtons: typeof FormSubmitButtons;
  FormContent: typeof FormContent;
}

const formContext = createContext<any>({});

const getInitialValue = (formData: FormData, activeGroup: number) => {
  return formData.groups[activeGroup].components.reduce((acc, component) => {
    return { ...acc, [component.jsonName]: component.value };
  }, {});
};

const getValidationSchema = (formData: FormData, activeGroup: number) => {
  const schema = Yup.object();
  return formData.groups[activeGroup].components.reduce((acc, component) => {
    const validationObj = { ...acc };
    switch (component.component) {
      case "textInput":
        if (component.isMandatory) {
          validationObj[component.jsonName] = Yup.string().required(
            `${component.displayName} is required.`
          );
        }
        if (component.isValidationReqd) {
          if (component.regEx) {
            validationObj[component.jsonName] = validationObj[
              component.jsonName
            ].matches(component.regEx, "Invalid input");
          }
        }
        break;
      default:
        break;
    }
    return { ...acc, [component.jsonName]: component.value };
  }, {} as any);
};

export const Form: FC<Props> & CompoundForm = ({
  data,
  children,
  submitHandler,
}) => {
  const [activeGroup, setActiveGroup] = useState(0);
  return (
    <formContext.Provider
      value={{
        formData: data,
        activeGroup,
        setActiveGroup,
      }}>
      <Formik
        initialValues={getInitialValue(data, activeGroup)}
        onSubmit={values => {
          submitHandler(values);
        }}>
        <>{children}</>
      </Formik>
    </formContext.Provider>
  );
};

export const useFormContext = () => {
  return useContext(formContext);
};

Form.Tabs = FormTab;
Form.FormContent = FormContent;
Form.ActionButtons = FormSubmitButtons;
