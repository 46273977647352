var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"3570dbd9749654093993b60879f763edf74092fd"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import { init, Replay } from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;


init({
  dsn: SENTRY_DSN || 'https://66c6b0343a4348d2a936e95c644840f3@o155911.ingest.sentry.io/6519200',
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1.0,
  enabled: process.env.NEXT_ENV !== "development",
  environment: process.env.NEXT_ENV,
  ignoreErrors: [
    "Cannot read properties of undefined (reading 'init')",
    "Non-Error promise rejection captured with keys: data, status, success"
  ],
  tracesSampleRate: 0,
  denyUrls: [
    /^http:\/\/\w+(\.\w+)*(:[0-9]+)?\/?$/
  ],
  replaysSessionSampleRate: 0.1,

  replaysOnErrorSampleRate: 1.0,

  integrations: [new Replay()]
});
