import { Dialog, DialogProps, Drawer } from "@mui/material";

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  children: React.ReactNode;
  ignoreFullWidth?: boolean;
}

export const PopupViewer: React.FC<Props> = ({
  children,
  open,
  setOpen,
  ignoreFullWidth = false,
}) => {
  return (
    <>
      <Dialog
        disablePortal
        fullWidth={!ignoreFullWidth}
        sx={{
          display: { lg: "block", xs: "none" },
        }}
        open={open}
        onClose={() => setOpen(false)}>
        {children}
      </Dialog>
      <Drawer
        disablePortal
        sx={{ display: { lg: "none", xs: "block" } }}
        anchor="bottom"
        open={open}
        onClose={() => setOpen(false)}>
        {children}
      </Drawer>
    </>
  );
};
