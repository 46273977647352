import React, { FC } from "react";
import {
  Button,
  CardHeader,
  Chip,
  IconButton,
  Stack,
  Typography,
  Link,
  Box,
} from "@mui/material";
import NavLink from "next/link";
import { FiCheckSquare, FiLink2, FiX } from "react-icons/fi";
import { BsWhatsapp } from "react-icons/bs";
import { useRouter } from "next/router";

import { TransformedProductEntity } from "../../../../types/transformed_product_entity";

import { useLocaleContext } from "../../../context";
import { useSelectVariant } from "../../../hooks";
import { analytics } from "../../../analytics";
import { ShareButton, WishlistButton } from "../../../atoms";
import { LoginPopup } from "../../LoginPopup";

interface Props {
  product: TransformedProductEntity;
  toggleOpen: () => void;
  pause: () => void;
  play: () => void;
}

export const ProductStoryContent: FC<Props> = ({
  product: p,
  toggleOpen,
  pause,
  play,
}) => {
  const router = useRouter();
  const { currency } = useLocaleContext();
  const {
    selectedVariant,
    setSelectedVariant,
    addToCartHandler,
    isAddedToCart,
    handleBuyNow,
    loginModalOpen,
    setLoginModalOpen,
    onLoginSuccess,
  } = useSelectVariant(p);

  const activePrice = currency === "INR" ? p.price[0] : p.price[1];
  const symbol = currency === "INR" ? "₹" : "$";

  const shareText = `Hey! Check out these awesome products on Jumkey. Hope you will like them :)\n\n1. ${p.title}:\n https://jumkey.com/products/${p.productHandle}\n\n`;

  const whatsAppLink = `https://api.whatsapp.com/send?phone=919902631591&text=Please place an order for SKU:${p.sku}; Product URL -https://www.jumkey.com/products/${p.productHandle}`;

  const pushWhatsappButtonClick = () => {
    analytics.pushOrderByWhatsapp({
      optionLink: whatsAppLink,
      optionName: p.productHandle,
      pageUrl: router.asPath,
      // @ts-ignore
      product: p,
    });
  };

  return (
    <>
      <Stack sx={{ p: 2 }} gap={2}>
        <CardHeader
          sx={{ p: 0 }}
          title={
            <Link
              href={`/products/${p.productHandle}`}
              color="#fff"
              variant="body1"
              component={NavLink}>
              {p.title}
            </Link>
          }
          subheader={p.category + " - " + p.rating + " ⭐"}
          subheaderTypographyProps={{
            variant: "body2",
            color: "#fff",
            sx: { mt: 0.25 },
          }}
          action={
            <IconButton
              sx={{ color: "white", mt: 0.25 }}
              onClick={() => toggleOpen()}>
              <FiX />
            </IconButton>
          }
        />
        <Stack direction="row" gap={1} flexWrap="wrap">
          {p.variants.map(variant => {
            return (
              <Chip
                size="small"
                onClick={() => setSelectedVariant(variant.id)}
                key={variant.id}
                label={variant.size ? variant.size : "One Size"}
                color="secondary"
                sx={{
                  opacity: variant.availability === "OUT-OF-STOCK" ? 0.5 : 1,
                }}
                variant={selectedVariant === variant.id ? "filled" : "outlined"}
              />
            );
          })}
        </Stack>
        <Stack gap={1.5}>
          <Stack
            sx={{ color: "#fff" }}
            direction="row"
            justifyContent="space-between"
            alignItems="center">
            <Stack
              direction="row"
              alignItems="baseline"
              gap={1}
              sx={{ color: "#fff" }}>
              <Typography fontWeight={500} variant="h6">
                {symbol}
                {activePrice.sellingPrice}
              </Typography>
              <Typography
                component="del"
                variant="body1"
                color={t => t.palette.error.light}>
                {symbol}
                {activePrice.mrp}
              </Typography>
              <Typography color={t => t.palette.success.light} variant="body1">
                {Math.floor(activePrice.discount)}% OFF
              </Typography>
            </Stack>
            <Box>
              <WishlistButton itemId={p.id} onClick={() => pause()} />
              <ShareButton
                shareText={shareText}
                numberOfItems={1}
                color="inherit"
                onClick={() => pause()}
              />
            </Box>
          </Stack>
          <Stack gap={1}>
            {isAddedToCart ? (
              <Button
                size="small"
                fullWidth
                onClick={() => router.push("/checkout/cart")}
                variant="contained"
                startIcon={<FiCheckSquare />}>
                Proceed To Checkout
              </Button>
            ) : (
              <Stack direction="row" gap={1}>
                <Button
                  variant="contained"
                  fullWidth
                  size="small"
                  onClick={() => {
                    pause();
                    addToCartHandler();
                  }}>
                  Add to cart
                </Button>
                <Button
                  variant="contained"
                  fullWidth
                  size="small"
                  color="secondary"
                  onClick={() => {
                    pause();
                    handleBuyNow();
                  }}>
                  Buy Now
                </Button>
              </Stack>
            )}
            <Link
              sx={{ display: "block", width: "100%", textDecoration: "none" }}
              // @ts-ignore
              target="_blank"
              href={whatsAppLink}>
              <div
                onClick={pushWhatsappButtonClick}
                style={{ width: "100%", display: "block", flex: 1 }}>
                <Button
                  fullWidth
                  size="small"
                  variant="outlined"
                  sx={{ borderRadius: "4px" }}
                  startIcon={<BsWhatsapp />}>
                  Order via whatsapp
                </Button>
              </div>
            </Link>
          </Stack>
        </Stack>
      </Stack>
      <LoginPopup
        open={loginModalOpen}
        setOpen={setLoginModalOpen}
        onSuccess={async () => {
          await onLoginSuccess();
          play();
        }}
      />
    </>
  );
};
