import React from "react";
import { CircularProgress, Stack } from "@mui/material";

export const Spinner = () => {
  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{ width: "100%", height: "100%" }}>
      <CircularProgress color="secondary" />
    </Stack>
  );
};
