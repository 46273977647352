import { FC } from "react";
import { FiMessageCircle } from "react-icons/fi";
import { useFreshChat } from "../../hooks/useFreshChat";

export const FreshChatButton: FC<{
  children: ({
    handleClick,
    loading,
  }: {
    handleClick: () => void;
    loading: boolean;
  }) => any;
}> = ({ children }) => {
  const { handleClick, loading } = useFreshChat();
  return children({ loading, handleClick });
};
