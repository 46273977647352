import { FC } from "react";
import { useRouter } from "next/router";
import {
  IconButton,
  colors,
  Divider,
  Typography,
  List,
  ListItemButton,
} from "@mui/material";
import {
  FiCornerDownLeft,
  FiFacebook,
  FiInstagram,
  FiLock,
  FiTwitter,
  FiX,
  FiYoutube,
} from "react-icons/fi";
import { SlCallOut, SlBubbles, SlLocationPin } from "react-icons/sl";

import { NextLevelMenuItem } from "../../../../../types/navigation";
import { CustomizedTreeView } from "./TreeView";
import { FreshChatButton, Logo } from "../../../../../src/atoms";
import { nanoid } from "nanoid";
import { BsQuestionSquare } from "react-icons/bs";
import { LocaleToggler } from "../../../../../src/molecules";
import { useDrawerDispatch } from "../../../../../src/context";

interface Props {
  menus: NextLevelMenuItem[];
}

const helpsection = [
  {
    id: nanoid(),
    label: "Returns",
    contentHandle: "/policies/returns-and-refunds",
    icon: <FiCornerDownLeft />,
  },
  {
    id: nanoid(),
    label: "Order Tracking",
    contentHandle: "/orders",
    icon: <SlLocationPin />,
  },
  {
    id: nanoid(),
    label: "FAQs",
    contentHandle: "/policies/faq",
    icon: <BsQuestionSquare />,
  },
  {
    id: nanoid(),
    label: "Privacy Policy",
    contentHandle: "/policies/privacy-policy",
    icon: <FiLock />,
  },
];

const socialMedias = [
  {
    icon: <FiFacebook />,
    redirectUrl: "https://www.facebook.com/jumkeyfashionjewellery",
    color: colors.indigo[500],
  },
  {
    icon: <FiTwitter />,
    redirectUrl: "https://twitter.com/jumkeyclub",
    color: colors.blue[500],
  },
  {
    icon: <FiYoutube />,
    redirectUrl: "https://www.youtube.com/channel/UCM9J4AJMh2gvFzYz6OWu9TQ",
    color: colors.red[500],
  },
  {
    icon: <FiInstagram />,
    redirectUrl: "https://www.instagram.com/jumkeyclub",
    color: colors.pink[500],
  },
];

const NavSidebar: FC<Props> = ({ menus }) => {
  const dispatch = useDrawerDispatch();
  const router = useRouter();

  const handleClick = (link: string) => {
    router.push(link);
    dispatch({ type: "CLOSE_DRAWER" });
  };

  return (
    <div className="w-72 h-full flex flex-col">
      <div className="flex items-center justify-between p-4">
        <Logo small />
        <IconButton onClick={() => dispatch({ type: "CLOSE_DRAWER" })}>
          <FiX />
        </IconButton>
      </div>
      <Divider />
      <div className="py-4 px-4">
        <CustomizedTreeView menus={menus} handleClick={handleClick} />
      </div>
      <Divider />
      <div className="py-4 space-y-1">
        <Typography
          variant="subtitle2"
          textTransform="uppercase"
          sx={{ px: 2 }}>
          Select your country
        </Typography>
        <LocaleToggler />
      </div>
      <Divider />
      <div className="py-4 space-y-1">
        <Typography
          variant="subtitle2"
          textTransform="uppercase"
          sx={{ px: 2 }}>
          Help and Support
        </Typography>
        <div className="w-full space-y-1 text-gray-900 bg-white rounded-lg px-4">
          <FreshChatButton>
            {({ handleClick, loading }) => {
              return (
                <div
                  className="flex items-center gap-4 py-2 text-gray-700 text-sm hover:bg-gray-100 focus:bg-gray-100"
                  onClick={handleClick}>
                  <SlBubbles />
                  {loading ? "loading..." : "Chat Now"}
                </div>
              );
            }}
          </FreshChatButton>

          <div className="flex items-center gap-4 py-2 text-gray-700 text-sm hover:bg-gray-100 focus:bg-gray-100">
            <SlCallOut />
            919902631591
          </div>
          {helpsection.map(help => (
            <div
              key={help.id}
              onClick={() => handleClick(help.contentHandle)}
              className="flex items-center gap-4 py-2 text-gray-700 text-sm hover:bg-gray-100 focus:bg-gray-100 cursor-pointer">
              {help.icon}
              {help.label}
            </div>
          ))}
        </div>
      </div>
      <Divider />

      <div className="py-4 space-y-2 px-4">
        <Typography variant="subtitle2" textTransform="uppercase">
          Connect with us
        </Typography>
        <div className="flex flex-wrap gap-4">
          {socialMedias.map(s => (
            <IconButton
              key={s.color}
              sx={{ color: s.color }}
              size="small"
              onClick={() => handleClick(s.redirectUrl)}>
              {s.icon}
            </IconButton>
          ))}
        </div>
      </div>
    </div>
  );
};

export default NavSidebar;
