import React, { FC } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";
import PortableText from "react-portable-text";
import { FiChevronDown } from "react-icons/fi";

interface Props {
  item: { title: string; description: string; formattedDescription: any };
}

export const PolicyContentBlock: FC<Props> = ({ item }) => {
  return (
    <Accordion>
      <AccordionSummary expandIcon={<FiChevronDown />}>
        <Typography>{item.title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {!item.formattedDescription && item.description ? (
          <Typography variant="body2" color="text.secondary">
            {item.description}
          </Typography>
        ) : (
          <Box
            component={PortableText}
            content={item.formattedDescription}
            sx={{
              fontSize: "14px",
              li: {
                ml: 2,
              },
            }}
          />
        )}
      </AccordionDetails>
    </Accordion>
  );
};
