import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import React from "react";

export const ApiError = ({
  error,
}: {
  error: FetchBaseQueryError | SerializedError;
}) => {
  const getErrorMessage = () => {
    if ("status" in error) {
      const err: string | any =
        "error" in error ? error.error : JSON.stringify(error.data);
      return typeof err === "string" ? err : err.data.description;
    } else {
      return error.message;
    }
  };
  return (
    <div className="flex items-center justify-center">
      <div className="bg-red-50 p-3 flex flex-col items-center gap-1">
        <h5 className="text-sm text-red-400 font-normal">
          Something Went Wrong
        </h5>
        <p className="text-red-500 text-lg font-medium">{getErrorMessage()}</p>
      </div>
    </div>
  );
};
