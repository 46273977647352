import { FC } from "react";
import { Button, ButtonProps, CircularProgress } from "@mui/material";

export const CustomButton: FC<ButtonProps & { loading: boolean }> = ({
  children,
  loading,
  startIcon,
  disabled,
  ...rest
}) => {
  return (
    <Button
      {...rest}
      startIcon={
        loading ? <CircularProgress size={16} /> : startIcon ? startIcon : null
      }
      disabled={loading ? true : disabled ? true : false}>
      {children}
    </Button>
  );
};
