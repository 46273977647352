import { configureStore } from "@reduxjs/toolkit";
import {
  addressApi,
  cartApi,
  loginApi,
  orderApi,
  profileApi,
  sharedApi,
  testimonialsApi,
  wishlistApi,
} from "./Services";
import { rtkQueryErrorLogger } from "./Shared";
import { authReducer, themeReducer } from "./Slices";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    theme: themeReducer,
    [addressApi.reducerPath]: addressApi.reducer,
    [wishlistApi.reducerPath]: wishlistApi.reducer,
    [cartApi.reducerPath]: cartApi.reducer,
    [loginApi.reducerPath]: loginApi.reducer,
    [orderApi.reducerPath]: orderApi.reducer,
    [profileApi.reducerPath]: profileApi.reducer,
    [testimonialsApi.reducerPath]: testimonialsApi.reducer,
    [sharedApi.reducerPath]: sharedApi.reducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat([
      wishlistApi.middleware,
      addressApi.middleware,
      cartApi.middleware,
      loginApi.middleware,
      orderApi.middleware,
      profileApi.middleware,
      testimonialsApi.middleware,
      rtkQueryErrorLogger,
    ]),
});

export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
