import { FC, Fragment } from "react";
import { Box } from "@mui/material";
import { useRouter } from "next/router";
import Link from "next/link";
import { Carousel } from "../../../components/Carousels";
import { analytics } from "../../analytics";
import Head from "next/head";

interface CarouselProps {
  _key: string;
  desktopImageUrl: string;
  mobileImageUrl: string;
  contentHandle: string;
  altText: string;
}

interface Props {
  items: CarouselProps[];
}

export const BannerCarousel: FC<Props> = ({ items }) => {
  const router = useRouter();

  const getCarousel = (isMobile: boolean) => {
    return (
      <Carousel type="media">
        {items.map((item, index) => {
          let imageUrl: string = "";
          if (isMobile) {
            imageUrl = item.mobileImageUrl;
          } else {
            imageUrl = item.desktopImageUrl;
          }
          return (
            <Link
              className="block"
              id={`bannerCarouselItem`}
              href={item.contentHandle}
              key={imageUrl}>
              <div
                onClick={() => {
                  const item = items[index];
                  analytics.pushCarouselBannerClick({
                    bannerLink: isMobile
                      ? item.mobileImageUrl
                      : item.desktopImageUrl,
                    bannerName: item.altText,
                    bannerPosition: `${index + 1}`,
                    bannerUrl: item.contentHandle,
                    pageUrl: router.asPath,
                  });
                  router.push(item.contentHandle);
                }}>
                <img
                  alt={item.altText}
                  src={imageUrl}
                  className="w-full h-auto object-cover"
                />
              </div>
            </Link>
          );
        })}
      </Carousel>
    );
  };

  return (
    <div>
      <Head>
        {items.map((item, index) => {
          return (
            <Fragment key={item.contentHandle}>
              <link
                rel="preload"
                as="image"
                href={item.desktopImageUrl}
                key={index}
              />
              <link
                rel="preload"
                as="image"
                href={item.mobileImageUrl}
                key={index}
              />
            </Fragment>
          );
        })}
      </Head>
      <Box sx={{ display: { xs: "block", sm: "none" } }}>
        {getCarousel(true)}
      </Box>
      <Box sx={{ display: { xs: "none", sm: "block" } }}>
        {getCarousel(false)}
      </Box>
    </div>
  );
};
