import { CoreAdapter } from "./CoreAdapter";

class Error extends CoreAdapter {
  pushApiError = ({ error }: { error: any }) => {
    return {
      event: "error",
      ecommerce: {
        error,
      },
    };
  };

  pushUnAuthError = ({ error }: { error: any }) => {
    return {
      event: "unauthorized",
      ecommerce: {
        error,
      },
    };
  };
}

export const errorAdpater = new Error();
