import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { FiSearch } from "react-icons/fi";

import { TransformedOrderDetailsEntity } from "../../../types/order_types";
import { useSearchOrderQuery } from "../../../store";
import { OrdersFilterDropdown } from "./OrdersFilterDropdown";
import { OrdersFilterTabs } from "./OrdersFilterTabs";

interface Props {
  activeTabState: number;
  setActiveTabState: (newState: number) => void;
  setSearchedOrder: (data: TransformedOrderDetailsEntity | undefined) => void;
}

export const OrdersFilters = forwardRef<any, Props>(
  ({ activeTabState, setActiveTabState, setSearchedOrder }, ref) => {
    const [searchValue, setSearchValue] = useState("");
    const { data } = useSearchOrderQuery(searchValue, {
      skip: searchValue.length !== 5,
    });

    useEffect(() => {
      if (data) {
        setSearchedOrder(data);
      }
    }, [data, setSearchedOrder]);

    useImperativeHandle(
      ref,
      () => ({
        clearInput: () => setSearchValue(""),
      }),
      []
    );

    return (
      <div>
        <div className="flex flex-row-reverse justify-between items-start lg:items-center gap-4 lg:gap-0">
          <div className="hidden lg:block">
            <OrdersFilterTabs
              activeTabState={activeTabState}
              setActiveTabState={setActiveTabState}
            />
          </div>
          <div className="block lg:hidden">
            <OrdersFilterDropdown
              activeTabState={activeTabState}
              setActiveTabState={setActiveTabState}
            />
          </div>
          <div>
            <TextField
              label="Enter an order id"
              size="small"
              value={searchValue}
              onChange={e => setSearchValue(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton size="small">
                      <FiSearch />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </div>
      </div>
    );
  }
);

OrdersFilters.displayName = "OrdersFilters";
