import { FC, useState, ReactNode, useEffect } from "react";

import { useScreenSize } from "../../hooks/useScreenSize";
import { Image as ImageType } from "../../../types/transformed_product_entity";
import { ZoomImageWidget } from "../../atoms";
import { Carousel } from "../../../components/Carousels";

export const ProductDetailsMedia: FC<{
  medias: ImageType[];
  title: string;
}> = ({ medias, title }) => {
  const [videoHeight, setVideoHeight] = useState(0);
  const { isMobile } = useScreenSize();
  const [showStudio, setShowStudio] = useState(false);

  useEffect(() => {
    if (isMobile) {
      setVideoHeight(((window.innerWidth - 32) / 3) * 4);
    }
  }, [isMobile]);

  const getMedias = () => {
    return medias.map((image: ImageType) => {
      let component: ReactNode;
      if (image.type.toLocaleLowerCase() === "image") {
        if (!isMobile) {
          component = <ZoomImageWidget src={image.url} alt={image.name} />;
        } else {
          component = (
            <img
              onClick={() => setShowStudio(true)}
              src={image.url}
              alt={image.name}
              className={`w-full h-full object-contain`}
            />
          );
        }
      } else {
        component = (
          <div className="w-full h-full flex items-center">
            <video
              height={videoHeight}
              controls
              className={`w-full block md:hidden object-contain`}>
              <source src={image.url} type="video/mp4" />
              Your browser does not support the video tag.
            </video>

            <video
              controls
              style={{ aspectRatio: "3/4" }}
              className={`w-full hidden md:block h-auto object-contain`}>
              <source src={image.url} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        );
      }
      return <div key={image.name}>{component}</div>;
    });
  };

  return (
    <div>
      <div className="block lg:hidden">
        <Carousel autoplay={false} type="media">
          {getMedias()}
        </Carousel>
      </div>
      <div className="hidden lg:grid grid-cols-2 gap-4">{getMedias()}</div>

      {/* <AnimatePresence exitBeforeEnter>
        {showStudio && isMobile && (
          <MobileZoomScreen
            title={title}
            medias={medias.filter(m => m.type === "image")}
            onClose={() => setShowStudio(false)}
          />
        )}
      </AnimatePresence> */}
    </div>
  );
};
