import { InputLabel, Slider, Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import {
  createContext,
  FC,
  ReactNode,
  SyntheticEvent,
  useContext,
  useState,
} from "react";
import { Text } from "../../atoms/Text/Text";

interface ContextType {
  range: number[];
  whenRangeChange: (newValue: number[]) => void;
  whenChangeCommitted: () => void;
}

interface Props {
  children: ReactNode;
  setPriceRange: (priceRange: any) => void;
  priceRange: any;
}

interface CompoundRangeSlider {
  Label: typeof Label;
  Slider: typeof CustomSlider;
  Message: typeof Message;
}

const rangeSliderContext = createContext<ContextType>({
  range: [0, 3000],
  whenRangeChange: () => {},
  whenChangeCommitted: () => {},
});

export const RangeSlider: FC<Props> & CompoundRangeSlider = ({
  children,
  priceRange,
  setPriceRange,
}) => {
  const [range, setRange] = useState<number[]>([
    priceRange.min,
    priceRange.max,
  ]);

  const whenRangeChange = (newValue: number[]) => {
    setRange(newValue);
  };

  const whenChangeCommitted = () => {
    setPriceRange({
      min: range[0],
      max: range[1],
    });
  };

  return (
    <rangeSliderContext.Provider
      value={{ range, whenRangeChange, whenChangeCommitted }}>
      <Stack>{children}</Stack>
    </rangeSliderContext.Provider>
  );
};
const useRangeSliderContext = () => {
  return useContext(rangeSliderContext);
};

const Label: FC<{ children: ReactNode }> = ({ children }) => {
  return <InputLabel>{children}</InputLabel>;
};

const CustomSlider: FC<{}> = () => {
  const { range, whenRangeChange, whenChangeCommitted } =
    useRangeSliderContext();

  const handleChange = (
    _: Event | SyntheticEvent<Element, Event>,
    newValue: number | number[]
  ) => {
    if (newValue instanceof Array) {
      whenRangeChange(newValue);
    }
  };

  return (
    <Slider
      id="range-slider"
      sx={{ width: "100%" }}
      min={0}
      max={30000}
      value={[range[0], range[1]]}
      onChange={handleChange}
      onChangeCommitted={whenChangeCommitted}
      valueLabelDisplay="auto"
      color="primary"
      getAriaValueText={value => `${value}`}
    />
  );
};

const Message: FC = () => {
  const { range } = useRangeSliderContext();
  return (
    <Typography>
      {range[0]} to {range[1]}
    </Typography>
  );
};

RangeSlider.Label = Label;
RangeSlider.Slider = CustomSlider;
RangeSlider.Message = Message;
