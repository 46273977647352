import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import React, { FC } from "react";

interface Props {
  open: boolean;
  onAgree: () => void;
  onDisagree: () => void;
  title: string;
  message: string;
}

export const ConfirmDialog: FC<Props> = ({
  open,
  title,
  message,
  onAgree,
  onDisagree,
}) => {
  return (
    <Dialog open={open} fullWidth onClose={onDisagree}>
      <DialogTitle>
        <Typography
          variant="subtitle1"
          fontWeight={700}
          textTransform="uppercase">
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body2">{message}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onDisagree} color="error">
          No
        </Button>
        <Button onClick={onAgree} autoFocus color="primary">
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};
