import { Button } from "@mui/material";
import Link from "next/link";
import React, { FC, ReactNode } from "react";

export const NoItemsFound: FC<{
  message: string;
  title: string;
  icon: ReactNode;
}> = ({ message, title, icon }) => {
  return (
    <div className="col-span-1 lg:col-span-2 flex items-center justify-center">
      <div className="p-3 bg-gray-50 space-y-4 rounded-md">
        <div className="space-y-1">
          <div className="flex gap-2 items-center text-xl">
            {icon}
            <p className="sectionTitle text-lg">{title}</p>
          </div>
          <p className="text-gray-500 text-sm">{message}</p>
        </div>
        <div>
          <Link href="/">
            <Button fullWidth variant="contained">
              Shop now
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
};
