import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { CouponResponse } from "../../../types/coupon_types";
import { CreateOrderResponse } from "../../../types/create_order_response_types";
import { RootState } from "../../Store";
import {
  orderTransformer,
  singleOrderTransformer,
} from "./ResponseTransformers";
import {
  CheckoutResponse,
  OrderbyidResponse,
  OrdersResponse,
  TransformedOrderDetailsEntity,
  TransformedOrderEntity,
} from "./Types";

export type orderType = "CONFIRMED" | "CANCELLED" | "PAYMENT_FAILED" | "";

interface createCheckoutPayload {
  payload: {
    billingAddressId: number;
    paymentMethodId: number;
    priceruleId: number;
    shippingAddressId: number;
  };
  method: "POST" | "PUT";
}

export const orderApi = createApi({
  reducerPath: "orderApi",
  refetchOnFocus: true,
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.BASE_URL,
    prepareHeaders: (header, api) => {
      const { auth } = api.getState() as RootState;
      if (auth.isLoggedin && auth.token) {
        header.set("authorization", `Bearer ${auth.token}`);
      }
      return header;
    },
  }),
  tagTypes: ["Checkout", "Order"],
  endpoints: builder => ({
    getOrders: builder.query<TransformedOrderEntity, orderType>({
      query: query => `/customer/orders?page=1&limit=12&status=${query}`,
      transformResponse: (data: OrdersResponse) => orderTransformer(data),
    }),
    getCoupons: builder.query<CouponResponse, void>({
      query: () => "/customer/checkout/coupons",
      providesTags: ["Checkout"],
    }),
    getManualCoupon: builder.query<{ id: number; couponTitle: string }, string>(
      {
        query: coupon => ({
          url: `/customer/checkout/coupons/search?couponTitle=${coupon}`,
        }),
      }
    ),
    getOrderById: builder.query<TransformedOrderDetailsEntity, string>({
      query: id => `/customer/orders/${id}`,
      transformResponse: (data: OrderbyidResponse) =>
        singleOrderTransformer(data),
    }),
    createOrder: builder.mutation<CreateOrderResponse, void>({
      query: () => ({
        url: "/customer/orders",
        method: "POST",
      }),
    }),
    createCheckout: builder.mutation<CheckoutResponse, createCheckoutPayload>({
      query: ({ method, payload }) => ({
        url: "/customer/checkout",
        method,
        body: payload,
      }),
      invalidatesTags: ["Checkout"],
    }),
    searchOrder: builder.query<TransformedOrderDetailsEntity, string>({
      query: orderNumber =>
        `/customer/orders/order-number/search?orderNumber=${orderNumber}`,
      transformResponse: (data: OrderbyidResponse) =>
        singleOrderTransformer(data),
    }),
    checkoutFromPaymentFailedOrder: builder.mutation<
      any,
      {
        checkoutToken: string;
        paymentMethodId: 1 | 2;
      }
    >({
      query: body => ({
        url: "/customer/checkout/update_payment",
        method: "PUT",
        body,
      }),
    }),
    CODFromPaymentFailedOrder: builder.mutation<any, { checkoutToken: string }>(
      {
        query: body => ({
          url: "/customer/orders/conver_cod",
          method: "POST",
          body,
        }),
      }
    ),
  }),
});

export const {
  useGetOrdersQuery,
  useGetOrderByIdQuery,
  useGetCouponsQuery,
  useLazyGetManualCouponQuery,
  useCreateOrderMutation,
  useCreateCheckoutMutation,
  useSearchOrderQuery,
  useCheckoutFromPaymentFailedOrderMutation,
  useCODFromPaymentFailedOrderMutation,
} = orderApi;
