import { useEffect, useState } from "react";

import { useCart } from "./useCart";
import { TransformedProductEntity } from "../../types/transformed_product_entity";

export const useSelectVariant = (product: TransformedProductEntity) => {
  const [selectedVariant, setSelectedVariant] = useState<string>("");
  const {
    addToCartHandler,
    isAddedToCart,
    handleBuyNow,
    loginModalOpen,
    setLoginModalOpen,
    onLoginSuccess,
  } = useCart(product.id);
  useEffect(() => {
    const availabelVariants = product.variants.find(
      variant => variant.availability !== "OUT-OF-STOCK"
    );
    setSelectedVariant(
      availabelVariants ? availabelVariants.id : product.variants[0].id
    );
  }, [product]);

  return {
    addToCartHandler: () => addToCartHandler(selectedVariant),
    selectedVariant,
    setSelectedVariant,
    isAddedToCart: isAddedToCart(selectedVariant),
    handleBuyNow: () => handleBuyNow(selectedVariant),
    loginModalOpen,
    setLoginModalOpen,
    onLoginSuccess,
  };
};
