import { ReactNode, FC } from "react";
import MultiCarousel, { ResponsiveType } from "react-multi-carousel";
import { useDeviceType } from "../../src/context";

interface ChildrenProps {
  responsive: ResponsiveType;
  children: ReactNode[] | ReactNode;
  height?: number;
  autoplay?: boolean;
}
export const MediaCarousel: FC<ChildrenProps> = ({
  responsive,
  children,
  height,
  autoplay = true,
}) => {
  return (
    <MultiCarousel
      ssr={true}
      deviceType="mobile"
      responsive={responsive}
      infinite
      autoPlay={autoplay}
      autoPlaySpeed={6000}
      pauseOnHover
      itemClass={height ? `h-[${Math.round(height)}px]` : "h-auto"}>
      {children}
    </MultiCarousel>
  );
};
