import { Box, CardHeader, Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import React, { FC } from "react";
import { FiWatch, FiX } from "react-icons/fi";
import { useGetProducts } from "../../../hooks/useGetProducts";
import { ProductCard } from "../../../organisms";
import { CountdownTimer } from "../../CountdownTimer";
import { Text } from "../../Text";

interface Props {
  gridColumn: any;
  url: string;
  timeUpto: string;
  title: string;
}
export const FlashSaleGrid: FC<Props> = ({
  url,
  gridColumn,
  title,
  timeUpto,
}) => {
  const products = useGetProducts(url);
  return (
    <Box gridColumn={gridColumn}>
      <CardHeader
        sx={{ px: 0, mb: 1 }}
        title={<Typography variant="h4">{title}</Typography>}
        subheaderTypographyProps={{ color: "error" }}
      />
      <Box
        display="grid"
        gap={{ xs: 0.5, lg: 2 }}
        gridTemplateColumns={{
          xs: "1fr 1fr",
          lg: "repeat(auto-fit, minmax(250px,1fr))",
        }}>
        {products &&
          products.map((prod, index: number) => {
            return (
              <Box key={prod.id} sx={{ minWidth: { xs: "100%", lg: "100%" } }}>
                <ProductCard product={prod} position={index + 1} />
              </Box>
            );
          })}
      </Box>
    </Box>
  );
};
