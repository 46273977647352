import { FC, useLayoutEffect } from "react";
import { Button, Stack, TextField } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";

import { emailIdRegexp } from "../../../../../utils";
import { useLocaleContext } from "../../../../context/LocaleContext/LocaleContext";
import { useRequestOtpMutation } from "../../../../../store";
import { CustomButton } from "../../../../atoms";
import { CustomTextField } from "../../../../atoms/CustomTextField";
import { loginDataType, loginStateType } from "../../LoginMain";

interface Props {
  setActiveState: (state: loginStateType) => void;
  setLoginData: (state: loginDataType) => void;
}

const validationSchema = Yup.object().shape({
  emailId: Yup.string()
    .required("Email id can't be empty.")
    .matches(emailIdRegexp, {
      message: "Enter a valid email id",
    }),
});

export const EmailOtp: FC<Props> = ({ setActiveState, setLoginData }) => {
  const [requestOtp, { isLoading, isSuccess }] = useRequestOtpMutation();
  const { currency } = useLocaleContext();
  const { values, handleChange, touched, errors, handleSubmit } = useFormik({
    initialValues: { emailId: "" },
    onSubmit: async values => {
      await requestOtp({ inputValue: values.emailId });
    },
    validationSchema,
    validateOnChange: true,
  });

  useLayoutEffect(() => {
    if (isSuccess) {
      toast.success("OTP sent successfully.");
      setActiveState("OTPValue");
      setLoginData({ type: "email", value: values.emailId });
    }
  }, [isSuccess]);

  return (
    <>
      <CustomTextField
        sx={{ width: "100%" }}
        id="emailId"
        label="Email id"
        type="email"
        name="emailId"
        value={values.emailId}
        onChange={handleChange}
        required
        error={touched.emailId && Boolean(errors.emailId)}
        helperText={touched.emailId && errors.emailId}
      />
      <Stack gap={1} sx={{ mt: 3 }}>
        <CustomButton
          loading={isLoading}
          fullWidth
          variant="contained"
          onClick={() => handleSubmit()}>
          Request OTP
        </CustomButton>
        {currency === "INR" && (
          <Button
            fullWidth
            variant="outlined"
            onClick={() => setActiveState("mobileOTP")}>
            Login by mobile OTP
          </Button>
        )}
      </Stack>
    </>
  );
};
