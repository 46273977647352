import {
  Box,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
} from "@mui/material";
import { FC } from "react";
import { FiX } from "react-icons/fi";
import { useScreenSize } from "../../../hooks/useScreenSize";
import { Form } from "../../Form";
import { formData } from "./data";

interface Props {
  open: boolean;
  onClose(): void;
  submitHandler: (values: any) => void;
}
export const CreateReviewDialog: FC<Props> = ({
  open,
  onClose,
  submitHandler,
}) => {
  const { isMobile } = useScreenSize();
  return (
    <Dialog open={open} fullWidth onClose={onClose} fullScreen={isMobile}>
      <Form data={formData} submitHandler={submitHandler}>
        <CardHeader
          title={<Form.Tabs />}
          action={
            <IconButton onClick={onClose} sx={{ mt: 1 }}>
              <FiX />
            </IconButton>
          }
        />
        <DialogContent>
          <Box sx={{ pt: 1 }}>
            <Form.FormContent />
          </Box>
        </DialogContent>
        <DialogActions>
          <Form.ActionButtons />
        </DialogActions>
      </Form>
    </Dialog>
  );
};
