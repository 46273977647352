import { FC } from "react";
import { Button, Link } from "@mui/material";
import { FiBell, FiCheckSquare, FiShoppingCart } from "react-icons/fi";
import { TbHandClick } from "react-icons/tb";

import { ShareButton, WishlistButton } from "../../atoms";
import {
  TransformedProductDetailsEntity,
  TransformedVariantEntity,
} from "../../../types/transformed_product_entity";
import { useCart } from "../../hooks/useCart";
import { useLocaleContext } from "../../context";
import { LoginPopup } from "../LoginPopup";
import { BsWhatsapp } from "react-icons/bs";
import { analytics } from "../../analytics";
import { useRouter } from "next/router";
import { pink } from "@mui/material/colors";

interface Props {
  product: TransformedProductDetailsEntity;
  variant: TransformedVariantEntity;
}

export const ProductDetailsPrice: FC<Props> = ({ product, variant }) => {
  const router = useRouter();
  const {
    addToCartHandler,
    isAddedToCart,
    handleBuyNow,
    loginModalOpen,
    setLoginModalOpen,
    onLoginSuccess,
  } = useCart(product.id);
  const { currency, symbol } = useLocaleContext();

  const { price, id, productHandle, sku } = product;
  const activePrice = price.find(p => p.code === currency);

  const productInCart = isAddedToCart(variant.id);

  const shareText = `Hey! Check out these awesome products on Jumkey. Hope you will like them :)\n\n1. ${product.title}:\n https://jumkey.com/products/${product.productHandle}\n\n
  `;

  const whatsAppLink = `https://api.whatsapp.com/send?phone=919902631591&text=Please place an order for SKU:${sku}; Product URL -https://www.jumkey.com/products/${productHandle}`;

  const pushWhatsappButtonClick = () => {
    analytics.pushOrderByWhatsapp({
      optionLink: whatsAppLink,
      optionName: product.productHandle,
      pageUrl: router.asPath,
      product: product,
    });
  };

  if (variant.availability === "OUT-OF-STOCK") {
    return (
      <div className="flex flex-col gap-2">
        <Button
          variant="contained"
          size="large"
          fullWidth
          sx={{ borderRadius: "4px" }}
          startIcon={<FiBell />}>
          Notify me
        </Button>
        <p className="text-base text-slate-500 dark:text-slate-400">
          Item is currently out of stock, click on the above button to be
          notified when available.
        </p>
      </div>
    );
  }
  return (
    <>
      <div className="flex flex-col gap-3">
        <div className="flex justify-between items-center">
          {activePrice && (
            <div className="flex items-baseline gap-2">
              <h2 className="font-semibold text-2xl">
                {symbol}
                {activePrice.sellingPrice}
              </h2>
              <span className="lg:text-lg text-slate-500 dark:text-slate-400">
                MRP{" "}
                <del>
                  {symbol}
                  {activePrice.mrp}
                </del>
              </span>
              <span className="lg:text-lg text-green-700 dark:text-green-400">
                {Math.floor(activePrice.discount)}% off
              </span>
            </div>
          )}
          <div className="flex gap-2 items-center">
            <WishlistButton itemId={id} color={pink[600]} />
            <ShareButton numberOfItems={1} shareText={shareText} />
          </div>
        </div>
        <div className="flex flex-col gap-3">
          <div className="flex-1 flex flex-col gap-2">
            {productInCart ? (
              <Button
                size="large"
                fullWidth
                sx={{ borderRadius: "4px" }}
                onClick={() => addToCartHandler(variant.id)}
                variant="contained"
                startIcon={<FiCheckSquare />}>
                Proceed to checkout
              </Button>
            ) : (
              <div className="flex flex-col md:flex-row gap-2">
                <Button
                  variant="contained"
                  size="large"
                  color="primary"
                  onClick={() => addToCartHandler(variant.id)}
                  sx={{ flex: 1 }}
                  startIcon={<FiShoppingCart />}>
                  Add To Cart
                </Button>
                <Button
                  size="large"
                  variant="contained"
                  color="secondary"
                  onClick={() => handleBuyNow(variant.id)}
                  sx={{ flex: 1 }}
                  startIcon={<TbHandClick />}>
                  Buy Now
                </Button>
              </div>
            )}
          </div>
          <div>
            {/* <WhatsAppButton product={product} variant={variant} /> */}
            <Link
              className="flex-1 text-black"
              // @ts-ignore
              target="_blank"
              onClick={pushWhatsappButtonClick}
              href={whatsAppLink}>
              <Button
                fullWidth
                startIcon={<BsWhatsapp size={18} />}
                size="large"
                color="inherit"
                variant="outlined"
                className="border-stone-300">
                Order via whatsapp
              </Button>
            </Link>
          </div>
        </div>
      </div>
      <LoginPopup
        open={loginModalOpen}
        setOpen={setLoginModalOpen}
        onSuccess={onLoginSuccess}
      />
    </>
  );
};
