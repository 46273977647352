import {
  Button,
  CircularProgress,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import Link from "next/link";
import { FC, ReactNode } from "react";

interface ActionButton {
  id?: string;
  label: string;
  onClick: () => void;
  icon?: any;
  variant?: any;
  color?:
    | "inherit"
    | "primary"
    | "secondary"
    | "success"
    | "error"
    | "info"
    | "warning";
}

interface Props {
  loading?: boolean;
  image: string;
  title: string;
  subtitle: string | ReactNode;
  children?: ReactNode;
  action?: ReactNode;
  actionButtons: ActionButton[];
  redirectLink?: string;
}

export const HorizontalCard: FC<Props> = ({
  loading = false,
  image,
  action,
  title,
  subtitle,
  children,
  actionButtons,
  redirectLink,
}) => {
  const media = <img src={image} className="w-full h-full object-contain" />;
  return (
    <div className="relative">
      <div className="flex">
        <div className="w-[90px] lg:w-[150px] h-[120px] lg:h-[200px]">
          {redirectLink ? (
            // @ts-ignore
            <Link href={redirectLink} target="_self">
              {media}
            </Link>
          ) : (
            media
          )}
        </div>
        <div className="flex-1 pl-3 flex flex-col gap-3">
          <div className="flex flex-col gap-1">
            <Stack
              direction="row"
              gap={0.25}
              alignItems="flex-start"
              justifyContent="space-between">
              <div>
                <Typography
                  sx={{ fontSize: { xs: "12px", sm: "14px" }, mb: 0.5 }}
                  color="text.primary"
                  fontWeight={700}>
                  {title}
                </Typography>

                {subtitle && <div>{subtitle}</div>}
              </div>
              {action ? <div>{action}</div> : null}
            </Stack>
          </div>
          <Divider />
          <div className="flex-1">{children}</div>
          <div className="flex gap-1 sm:gap-2">
            {actionButtons.map(b => (
              <Button
                sx={{ fontSize: { xs: "10px", sm: "12px" } }}
                key={b.label}
                onClick={b.onClick}
                color={b.color}
                size="small"
                startIcon={b.icon ? b.icon : null}
                variant={b.variant ? b.variant : "text"}>
                {b.label}
              </Button>
            ))}
          </div>
        </div>
      </div>
      {loading ? (
        <div className="absolute top-0 left-0 right-0 bottom-0 z-20 bg-gray-100 bg-opacity-50 flex items-center justify-center">
          <div className="bg-white w-7 h-7 flex items-center justify-center rounded-full shadow-md">
            <CircularProgress size={20} />
          </div>
        </div>
      ) : null}
    </div>
  );
};
