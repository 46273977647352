import { FC } from "react";
import { sortOptions } from "../../../models/SortOptions";
import { Container, Typography } from "@mui/material";

export const SortFilter: FC<any> = ({ sortBy, setSortBy }) => {
  return (
    <Container sx={{ py: 2 }}>
      <div className="flex flex-col gap-4">
        <Typography variant="subtitle1">Sort By</Typography>
        <div className="flex flex-col gap-6">
          {sortOptions.map(item => {
            return (
              <div key={item.name} className="flex items-center">
                <input
                  id={item.name}
                  type="radio"
                  checked={sortBy === item.value}
                  onChange={() => setSortBy(item.value)}
                  name="default-radio"
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label
                  htmlFor={item.name}
                  className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                  {item.name}
                </label>
              </div>
            );
          })}
        </div>
      </div>
    </Container>
  );
};
