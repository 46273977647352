import { Stack, Typography } from "@mui/material";
import { FC } from "react";

const FooterContactSection: FC = () => {
  return (
    <Stack gap={2}>
      <Typography variant="subtitle1">Registered Address</Typography>
      <Stack gap={0.75}>
        <Typography variant="body2" color="text.secondary">
          Ninjaas Labs Private Limited, No 460, Sai Ishwar Krupa, 7th Main Road,
          NGEF Layout, Sadanandanagara, Bengaluru - 560038, Karnataka, India
        </Typography>
        <Stack direction={{ xs: "column", lg: "row" }} gap={{ xs: 1, lg: 2 }}>
          <Typography variant="caption" fontWeight={600}>
            <Typography component="span" variant="caption" fontWeight={400}>
              GSTIN
            </Typography>{" "}
            : 29AAECN7662E1Z7
          </Typography>
          <Typography variant="caption" fontWeight={600}>
            <Typography component="span" variant="caption" fontWeight={400}>
              CIN
            </Typography>{" "}
            : U72200KA2014PTC076731
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default FooterContactSection;
