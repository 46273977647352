import { FC } from "react";
import { FilterOption, RangeSlider, SearchSelect } from "../../molecules";
import {
  FilterValue,
  TransformedFilterEntity,
} from "../../../types/transformedFilterEntity";
import { compareSortingParameters } from "../../../utils";
import { queryFiltersType } from "../../templates/Productlistings/hooks/useProductListingFilter/useProductListingFilter";

interface Props {
  filters: TransformedFilterEntity[];
  filterQuery: queryFiltersType;
  setFilterQuery: (
    value: queryFiltersType | ((prevVar: queryFiltersType) => queryFiltersType)
  ) => void;
  setPriceRange: (priceRange: any) => void;
  priceRange: any;
}

export const ProductlistingDesktopFilters: FC<Props> = ({
  filters,
  filterQuery,
  setFilterQuery,
  setPriceRange,
  priceRange,
}) => {
  const filterOutTheSelectedOptions = (item: TransformedFilterEntity) => {
    let value: FilterValue[] = [];
    if (filterQuery[item.name]) {
      value = item.value.filter(option =>
        filterQuery[item.name].includes(option.name)
      );
    }
    return value;
  };
  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col gap-6">
        <RangeSlider priceRange={priceRange} setPriceRange={setPriceRange}>
          <RangeSlider.Label>Price range</RangeSlider.Label>
          <RangeSlider.Slider />
          <RangeSlider.Message />
        </RangeSlider>
        {filters.sort(compareSortingParameters).map((item, index) => {
          let value = filterOutTheSelectedOptions(item);
          return (
            <SearchSelect
              key={`${item.name}-${index}`}
              item={item}
              value={value}
              onChange={(value: FilterOption[]) => {
                setFilterQuery({
                  ...filterQuery,
                  [item.name]: value.map((v: any) => v.name),
                });
              }}
            />
          );
        })}
      </div>
    </div>
  );
};
