import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { captureException } from "@sentry/nextjs";
import {
  CartProductEntity,
  CartTransformedResponse,
  VariantNotFound,
} from "../../../types/cart_response_type";
import { oldProductTransformer } from "../../../utils";
import { RootState, store } from "../../Store";
import { CartResponse, PriceValue } from "./Types";

type mutationPayload = { itemId: string; variantId: string };

export const cartApi = createApi({
  reducerPath: "cartApi",
  refetchOnFocus: true,
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.BASE_URL,
    prepareHeaders: (header, api) => {
      const { auth } = api.getState() as RootState;
      if (auth.isLoggedin && auth.token) {
        header.set("authorization", `Bearer ${auth.token}`);
      }
      return header;
    },
  }),
  tagTypes: ["Cart"],
  endpoints: builder => ({
    getCart: builder.query<CartTransformedResponse, void>({
      query: () => `/customer/cart?page=1&limit=100`,
      providesTags: ["Cart"],
      transformResponse: (data: CartResponse, meta, args) => {
        let products: (CartProductEntity | VariantNotFound)[] = [];
        data.cartLineItems.forEach(lineItem => {
          const product = data.products.find(p => p.id === lineItem.itemId);
          if (product) {
            try {
              const variant = product.variants.filter(
                v => v.id === lineItem.variantId
              );
              if (variant[0]) {
                const payload = {
                  itemId: product.id,
                  variantId: variant[0].id,
                };
                if (variant[0].id === product.id) {
                  captureException({
                    ...payload,
                    errMessage: "SIMILAR_PRODUCT_AND_VARIANT_ID",
                  });
                }
                if (variant[0].id !== variant[0].variantId) {
                  captureException({
                    ...payload,
                    errMessage: "MISMATCH_VARIANT_ID",
                  });
                }
              }
              const transformedProduct = oldProductTransformer({
                ...product,
                variants: [...variant],
              });
              const quantity = lineItem.quantity;
              const isAvailable = product.isInventoryTracked
                ? lineItem.quantity <= variant[0].variantQuantity
                : true;
              let errorMessage = "";
              if (!isAvailable) {
                if (!product.isInventoryTracked) {
                  errorMessage = `Product is currently out of stock to continue checkout,
                      please remove this item or Move item to your wishlist.`;
                } else if (lineItem.quantity > variant[0].variantQuantity) {
                  errorMessage = `Selected product quantity is currently out of stock / less than the selected quantity. To checkout,
                      please reduce the item quantity or Move item to your wishlist.`;
                }
              }
              products.push({
                ...transformedProduct,
                quantity: quantity,
                variantId: lineItem.variantId,
                isAvailable,
                errorMessage,
                cartItemType: "valid",
              } as CartProductEntity);
            } catch (error) {
              const d: VariantNotFound = {
                cartItemType: "invalid",
                productHandle: product.handle,
                id: product.id,
                title: product.title,
                errMessage: "VARIANT_NOT_FOUND",
              };
              captureException({ ...d, cartData: data.cart });
              products.push(d);
            }
          } else {
            store.dispatch(
              cartApi.endpoints.deleteErrorItemFromCart.initiate({
                itemId: lineItem.itemId,
              })
            );
          }
        });

        const usdData = data.cartValue.currencies[0];
        const usd: PriceValue = {
          sellingPrice: usdData.convertedPrice,
          mrp: usdData.convertedMrp,
          discount: usdData.discount,
          code: "USD",
        };
        const rupee: PriceValue = {
          discount: Math.round(data.cartValue.totalDiscount),
          mrp: data.cartValue.totalMrp,
          sellingPrice: data.cartValue.totalSellingPrice,
          code: "INR",
        };

        const cartValue = [usd, rupee];

        const transformedCart: CartTransformedResponse = {
          products: products,
          value: cartValue,
          count: data.count,
          cartId: data.cart.token,
        };

        return transformedCart;
      },
    }),
    addToCart: builder.mutation<any, mutationPayload>({
      query: body => ({
        url: "/customer/cart",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Cart"],
    }),
    changeQuantity: builder.mutation<
      any,
      mutationPayload & { requiredQuantity: number }
    >({
      query: body => ({
        url: "/customer/cart",
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Cart"],
    }),
    removeFromCart: builder.mutation<any, mutationPayload>({
      query: body => ({
        url: "/customer/cart",
        method: "DELETE",
        body,
      }),
      invalidatesTags: ["Cart"],
    }),
    deleteErrorItemFromCart: builder.mutation<any, { itemId: string }>({
      query: body => ({
        url: "/customer/cart-item",
        method: "DELETE",
        body,
      }),
      invalidatesTags: ["Cart"],
    }),
  }),
});

export const {
  useGetCartQuery,
  useAddToCartMutation,
  useRemoveFromCartMutation,
  useChangeQuantityMutation,
  useDeleteErrorItemFromCartMutation,
} = cartApi;
