import {
  CountryList,
  RequestEmailOtpPayload,
  RequestMobileOtpPayload,
} from "../../../types/login_types";
import { NetworkCall } from "../NetworkCall";

export class Login {
  private static body: any = {};

  static getCountryList = async () => {
    const data = (await NetworkCall.query({
      auth: "basic",
      url: `${process.env.BASE_URL}/customer/country/list`,
    })) as { result: CountryList[] };
    return data.result.filter(country => country.isMobileOtpEnabled);
  };

  static requestMobileOtp = async ({
    dialCode,
    inputValue,
  }: RequestMobileOtpPayload) => {
    Login.body.mobileNumber = `${dialCode}${inputValue}`;
    await NetworkCall.mutate({
      url: `${process.env.BASE_URL}/customer/send_otp`,
      method: "POST",
      body: {
        mobileNumber: `${dialCode}${inputValue}`,
        mobileResendType: "Text",
        otpOption: "mobile",
      },
      auth: "basic",
    });
  };

  static requestEmailOtp = async ({ inputValue }: RequestEmailOtpPayload) => {
    Login.body.emailId = inputValue;
    await NetworkCall.mutate({
      url: `${process.env.BASE_URL}/customer/send_otp`,
      method: "POST",
      body: {
        emailId: inputValue,
        otpOption: "email",
      },
      auth: "basic",
    });
  };

  private static verifyMobileOtp = async ({ otp }: { otp: string }) => {
    const data = await NetworkCall.mutate({
      url: `${process.env.BASE_URL}/customer/login`,
      method: "POST",
      body: {
        ...Login.body,
        otp,
        signInType: "otp_login",
      },
      auth: "basic",
    });
    Login.body = {};
    return data;
  };

  private static verifyEmailOtp = async ({ otp }: { otp: string }) => {
    const data = await NetworkCall.mutate({
      url: `${process.env.BASE_URL}/customer/login`,
      method: "POST",
      body: {
        ...Login.body,
        otp,
        signInType: "email_otp",
      },
      auth: "basic",
    });
    Login.body = {};
    return data;
  };

  static verifyOtp = async (data: { otp: string }) => {
    if (Login.body && Login.body.mobileNumber) {
      return Login.verifyMobileOtp(data);
    }
    return Login.verifyEmailOtp(data);
  };
}
