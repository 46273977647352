import { FC, useMemo } from "react";
import { Dialog, IconButton } from "@mui/material";
import MultiCarousel from "react-multi-carousel";
import { FiX } from "react-icons/fi";

import { useScreenSize } from "../../../hooks/useScreenSize";
import { ReviewsEntity } from "../../../../types/review_types";
import { getTimeAndDateStringFromEPOC } from "../../../../utils";
import Image from "next/image";

interface Props {
  show: boolean;
  onClose: () => void;
  review: ReviewsEntity | undefined;
}

export const ReviewDetails: FC<Props> = ({ show, onClose, review }) => {
  const { isMobile } = useScreenSize();

  const imageList = useMemo(() => {
    let images = [];
    if (review) {
      if (review.imageUrl1) {
        images.push(review.imageUrl1);
      }
      if (review.imageUrl2) {
        images.push(review.imageUrl2);
      }
      if (review.imageUrl3) {
        images.push(review.imageUrl3);
      }
    }
    return images;
  }, [review]);

  return (
    <Dialog
      open={show}
      onClose={onClose}
      fullWidth
      fullScreen={isMobile}
      disablePortal>
      {review && (
        <div className="p-3 space-y-3">
          <div className="flex justify-between items-center">
            <div>
              <h4 className="sectionTitle text-lg uppercase">
                {review.reviewedBy}
              </h4>
              <p className="text-gray-500 text-sm">{`Reviewed on ${
                getTimeAndDateStringFromEPOC(review.createdAt).date
              }.`}</p>
            </div>
            <IconButton onClick={onClose}>
              <FiX />
            </IconButton>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-3">
            <MultiCarousel
              responsive={{
                desktop: {
                  breakpoint: { max: 3000, min: 1281 },
                  items: 1,
                },
                tablet: {
                  breakpoint: { max: 1280, min: 464 },
                  items: 1,
                },
                mobile: {
                  breakpoint: { max: 464, min: 0 },
                  items: 1,
                },
              }}>
              {imageList.map(image => {
                return (
                  <img
                    src={image}
                    alt="Image"
                    className="w-full h-full object-contain"
                  />
                );
              })}
            </MultiCarousel>

            <div>
              <p className="text-sm">{review.description}</p>
            </div>
          </div>
        </div>
      )}
    </Dialog>
  );
};
