import { ReactNode, FC } from "react";
import { Container, Stack, Typography } from "@mui/material";

interface Props {
  title: string;
  subtitle?: string | ReactNode;
  action?: ReactNode;
  children: ReactNode | ReactNode[];
}

export const AuthLayout: FC<Props> = ({
  title,
  subtitle,
  children,
  action,
}) => {
  return (
    <Container>
      <Stack sx={{ minHeight: "100vh" }} spacing={{ xs: 3, lg: 4 }}>
        <Stack
          direction="row"
          gap={2}
          alignItems="center"
          justifyContent="space-between">
          <div>
            <Typography variant="h5">{title}</Typography>
            {subtitle ? (
              typeof subtitle === "string" ? (
                <Typography variant="body1" color="text.secondary"></Typography>
              ) : (
                subtitle
              )
            ) : null}
          </div>
          {action ? action : null}
        </Stack>
        {children}
      </Stack>
    </Container>
  );
};
