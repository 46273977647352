import { ProductsResponseType } from "../../../types/product_listing_types";
import { newProductTransformer } from "../../../utils";
import { NetworkCall } from "../NetworkCall";

export class ProductListings {
  private static getIntegerParams = (
    params: URLSearchParams,
    property: string
  ) => {
    const page = params.get(property);
    if (page !== null) {
      return parseInt(page);
    }
    return 1;
  };

  private static getStringParams = (
    params: URLSearchParams,
    property: string
  ) => {
    const query = params.get(property);
    if (query !== null) {
      return query;
    }
    return "";
  };

  private static getPriceRangeParams = (params: URLSearchParams) => {
    const priceRange = params.get("sellingPrice");
    if (priceRange) {
      const slicedData = priceRange
        .slice(1, priceRange.length - 1)
        .split(" TO ");
      return {
        min: parseInt(slicedData[0]),
        max: parseInt(slicedData[1]),
      };
    }
    return {
      min: 0,
      max: 30000,
    };
  };

  private static getArrayParams = (
    params: URLSearchParams,
    property: string
  ) => {
    const query = params.get(property);
    if (query !== null) {
      return query.split(",");
    }
    return [];
  };

  static getInitialStateFromUrl = () => {
    const params = new URLSearchParams(window.location.search);
    const initialState = {
      page: ProductListings.getIntegerParams(params, "page"),
      searchQuery: ProductListings.getStringParams(params, "query"),
      sortBy: ProductListings.getStringParams(params, "sortBy"),
      priceRange: ProductListings.getPriceRangeParams(params),
      filterQuery: {
        category: ProductListings.getArrayParams(params, "category"),
        brand: ProductListings.getArrayParams(params, "brand"),
        color: ProductListings.getArrayParams(params, "color"),
        material: ProductListings.getArrayParams(params, "material"),
        pattern: ProductListings.getArrayParams(params, "pattern"),
        trend: ProductListings.getArrayParams(params, "trend"),
        availableSize: ProductListings.getArrayParams(params, "availableSize"),
        availability: ProductListings.getArrayParams(params, "availability"),
      },
    };
    return initialState;
  };

  static getProducts = async (url: string) => {
    const data = (await NetworkCall.query({
      url,
      auth: "none",
    })) as ProductsResponseType;
    const products = data.products.map(prod => newProductTransformer(prod));
    let filters: any[] = [];
    if (data?.facets?.facets) {
      filters = Object.entries(data.facets.facets).map(([key, value]) => ({
        name: key,
        value: Object.keys(value).map((el: any) => ({
          name: el,
          value: value[el],
        })),
      }));
    }
    const brandFilterIndex = filters.findIndex(f => f.name === "brand");
    filters.splice(brandFilterIndex, 1);
    let seo;
    if (data?.seo) {
      seo = data.seo;
    } else if (data?.collection?.seo) {
      seo = data.collection.seo;
    } else {
      seo = null;
    }

    return {
      eventId: data.eventId,
      sortBy: data?.responseHeader?.params?.sort
        ? data?.responseHeader?.params?.sort
        : "",
      seo: seo ? seo : null,
      count: data?.page?.totalDocuments ? data.page.totalDocuments : 0,
      products: products,
      filters,
      title: data.collection ? data.collection.title : "Search Result",
      banners: {
        desktop: data?.collection?.bannerUrl ? data.collection.bannerUrl : "",
        mobile: data?.collection?.mobileBannerUrl
          ? data.collection.mobileBannerUrl
          : "",
      },
    };
  };
}
