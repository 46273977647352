import { captureException } from "@sentry/nextjs";

export class NetworkCall {
  private static getOptions = ({
    auth,
    body,
    method = "GET",
  }: {
    auth: "basic" | "token" | "none";
    body?: any;
    method?: "GET" | "POST" | "PUT" | "DELETE";
  }) => {
    let options: RequestInit = {
      method: method,
    };
    if (auth === "basic") {
      options.headers = {
        Authorization: `Basic NC1VKGVMeTNhR1MsI200ZEskNjh1aDZnS3p2dWdURyM6cClIPU1iQHldbWgpcmc4Z2RBey0ufVU0eGE2dkFlMnk=`,
      };
    } else if (auth === "token") {
      options.headers = {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      };
    }
    if (body) {
      options.body = JSON.stringify(body);
    }
    return options;
  };

  private static makeRequest = async ({
    url,
    options,
  }: {
    url: string;
    options: RequestInit;
  }) => {
    try {
      const response = await fetch(url, options);
      const data = await response.json();
      if (!response.ok) {
        captureException(data);
        const error: any = new Error(
          "An error occurred while fetching the data."
        );
        error.info = data;
        error.status = response.status;
        throw error;
      }
      return data;
    } catch (err) {
      throw err;
    }
  };

  static query = async ({
    url,
    auth,
  }: {
    url: string;
    auth: "basic" | "token" | "none";
  }) => {
    const options = NetworkCall.getOptions({ auth });
    const data = await NetworkCall.makeRequest({ url, options });
    return data;
  };

  static mutate = async ({
    method,
    url,
    auth,
    body,
  }: {
    method: "POST" | "PUT" | "DELETE";
    url: string;
    body?: any;
    auth: "basic" | "token" | "none";
  }) => {
    const options = NetworkCall.getOptions({
      method,
      body,
      auth,
    });
    const data = await NetworkCall.makeRequest({ url, options });
    return data;
  };
}
