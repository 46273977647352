import { useState, useEffect, useRef } from "react";
import { Box, Container, Link, Stack, Typography } from "@mui/material";
import NavLink from "next/link";
import { useRouter } from "next/router";
import { useGetTestimonialQuery } from "../../../store";
import { TestimonialCard } from "../../molecules";

export const TestimonialsSection = () => {
  const router = useRouter();
  const [show, setShow] = useState(true);
  const prevPathRef = useRef(router.asPath);
  const [minMax, setMinMax] = useState({ min: 0, max: 10 });
  const { data } = useGetTestimonialQuery(10);

  useEffect(() => {
    if (show && data && prevPathRef.current !== router.asPath) {
      prevPathRef.current = router.asPath;
      const newMin = Math.floor(Math.random() * 250);
      setMinMax({ min: newMin, max: newMin + 10 });
    }
  }, [data, router, show]);

  useEffect(() => {
    if (router.asPath.includes("checkout")) {
      setShow(false);
    } else {
      setShow(true);
    }
  }, [router]);

  if (!show) return null;

  return (
    <Container maxWidth="xl">
      <Stack gap={2}>
        <Stack direction="row" justifyContent="space-between">
          <div>
            <Typography
              variant="subtitle1"
              fontSize={{ xs: "14px", sm: "16px" }}
              fontWeight={700}>
              Happy Customers
            </Typography>
            <Typography
              fontSize={{ xs: "12px", sm: "14px" }}
              variant="body2"
              color="text.secondary">
              What our customers say about us
            </Typography>
          </div>
          <Link
            component={NavLink}
            href="/testimonials"
            fontSize={{ xs: "14px", sm: "16px" }}
            underline="none">
            View All
          </Link>
        </Stack>
        <Stack
          direction="row"
          sx={{
            overflowX: "scroll",
            borderRadius: "4px",
            pb: 2,
          }}
          gap={2}>
          {data?.testimonials
            ?.slice(minMax.min, minMax.max)
            .map((testimonial, index) => {
              return (
                <Box sx={{ minWidth: "300px", maxWidth: "300px" }} key={index}>
                  <TestimonialCard
                    productUrl={testimonial.productUrl}
                    customerName={testimonial.customerName}
                    imageUrl={testimonial.imageUrl}
                    testimonial={testimonial.testimonial}
                  />
                </Box>
              );
            })}
        </Stack>
      </Stack>
    </Container>
  );
};
