import { FC } from "react";
import { Checkbox, Chip, Divider } from "@mui/material";

import { CardActionButtons } from "../../molecules";
import { AddressType } from "../../../types/address_types";

interface Props {
  address: AddressType;
  isSelected?: boolean;
  select?: (address: AddressType) => void;
  isDefault?: boolean;
  actionButtons?: {
    label: string;
    onClick: () => void;
    icon?: any;
    color?:
      | "inherit"
      | "primary"
      | "secondary"
      | "success"
      | "error"
      | "info"
      | "warning";
  }[];
}

export const AddressCard: FC<Props> = ({
  address,
  actionButtons = [],
  isSelected = false,
  isDefault = false,
  select,
}) => {
  const {
    fullName,
    emailId,
    addressType,
    mobile,
    addressLineOne,
    addressLineTwo,
    city,
    state,
    country,
    zipcode,
  } = address;

  return (
    <div className="w-full h-full flex flex-col gap-3">
      <div>
        <div className="flex items-center justify-between">
          <div className="flex flex-col gap-1">
            <p className="text-sm tracking-tight font-semibold">{fullName}</p>
            <div className="flex gap-2">
              {isDefault && (
                <Chip variant="outlined" label="Default" size="small" />
              )}
              <Chip
                variant="outlined"
                label={addressType === "HOME" ? "Home" : "Office"}
                size="small"
              />
            </div>
          </div>
          {select && (
            <Checkbox
              checked={isSelected}
              sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
              onChange={() => select(address)}
            />
          )}
        </div>
      </div>
      <Divider />
      <div className="flex flex-col gap-3 flex-1">
        {emailId && (
          <p className="text-xs lg:text-sm text-gray-700">{emailId}</p>
        )}
        {mobile && <p className="text-xs lg:text-sm text-gray-700">{mobile}</p>}
        <p className="text-xs lg:text-sm text-gray-700">
          {addressLineOne}, {addressLineTwo}, {city}, {state}, {country},
          {zipcode}
        </p>
      </div>
      <div className="flex gap-4">
        <CardActionButtons
          buttons={actionButtons.filter(
            b => b.label.toLowerCase() !== "select"
          )}
        />
      </div>
    </div>
  );
};
