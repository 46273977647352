import React, { useEffect } from "react";
import { Dialog, DialogContent } from "@mui/material";

import { useTypedSelector } from "../../../hooks/useTypedSelector";
import { useGetProfileQuery } from "../../../store";
import { useFreshChat } from "../../hooks";
import { EditProfile } from "../EditProfile";

export const AddUserDetailsDialog = () => {
  const { isLoggedin, userId } = useTypedSelector(state => state.auth);
  const { data } = useGetProfileQuery(undefined, {
    skip: !isLoggedin,
    refetchOnMountOrArgChange: true,
  });
  const { handleClick } = useFreshChat();

  useEffect(() => {
    handleClick();
  }, []);

  useEffect(() => {
    if (data) {
      if (window.Bugpilot) {
        window.Bugpilot.identify({
          ...data,
          id: userId,
          email: data.emailId,
          firstName: data.firstname,
          lastName: data.lastname,
          subscriptionStatus: "active",
        });
      }
      window.fcWidget?.user.isExists().then(
        function (isExist: any) {
          const payload = {
            firstName: data.firstname,
            lastName: data.lastname,
            email: data.emailId,
            meta: {
              mobile: data.mobileNumber,
              userId,
            },
          };
          if (isExist.data) {
            window.fcWidget?.user.update(payload, function (data: any) {
              console.log("User updated");
            });
          } else {
            window.fcWidget?.user.create(payload, function (data: any) {
              console.log("User created");
            });
          }
        },
        function (data: any) {}
      );
    }
  }, [data]);

  if (!data) {
    return null;
  }
  const show = !Boolean(data.firstname) || !Boolean(data.lastname);

  return (
    <Dialog open={show} disablePortal fullWidth>
      <DialogContent>
        {data && (
          <EditProfile
            title="Add profile details"
            data={data}
            firstName={data.firstname}
            lastName={data.lastname}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};
