import { FC } from "react";
import { IconButton } from "@mui/material";
import { FiMinusCircle, FiPlusCircle } from "react-icons/fi";

export const ChangeQuantityWidget: FC<{
  quantity: number;
  changeQuantity: (payload: 1 | -1) => void;
}> = ({ quantity, changeQuantity }) => {
  return (
    <div
      style={{ border: "1px solid rgb(209, 213, 219)" }}
      className="flex items-center gap-2 rounded-md border-2 border-gray-300">
      <IconButton
        size="small"
        color="primary"
        disabled={quantity === 1}
        onClick={() => changeQuantity(-1)}>
        <FiMinusCircle className="text-xs lg:text-lg" />
      </IconButton>
      <span className="text-sm lg:text-base">{quantity}</span>
      <IconButton
        size="small"
        color="primary"
        onClick={() => changeQuantity(1)}>
        <FiPlusCircle className="text-xs lg:text-lg" />
      </IconButton>
    </div>
  );
};
