import { useRef, useState, Fragment } from "react";
import { CircularProgress, IconButton, Popover } from "@mui/material";
import { OrdersFilters } from "../OrdersDesktopFilters";
import {
  TransformedOrderDetailsEntity,
  TransformedSingleOrderEntity,
} from "../../../types/order_types";
import { OrderCard } from "./OrderCard";
import { FiX } from "react-icons/fi";
import { orderType, useGetOrdersQuery } from "../../../store";
import { NoItemsFound } from "../../atoms";
import { FcPaid } from "react-icons/fc";

export const ORDER_TABS: { name: string; value: orderType }[] = [
  { value: "", name: "All" },
  { value: "CONFIRMED", name: "Confirmed" },
  { value: "CANCELLED", name: "Cancelled" },
  { value: "PAYMENT_FAILED", name: "Payment Failed" },
];

export const OrdersMain = () => {
  const popoverBoxRef = useRef<HTMLDivElement>(null);
  const filtersRef = useRef<{ clearInput: () => void }>();

  const [activeTabState, setActiveTabState] = useState(0);
  const { data } = useGetOrdersQuery(ORDER_TABS[activeTabState].value);

  const [searchedOrder, setSearchedOrder] = useState<
    TransformedOrderDetailsEntity | undefined
  >();

  const onClosePopover = () => {
    setSearchedOrder(undefined);
    filtersRef.current?.clearInput();
  };

  const getOrderCard = (
    order: TransformedOrderDetailsEntity | TransformedSingleOrderEntity
  ) => <OrderCard order={order} />;

  return (
    <div className="flex flex-col gap-6">
      <div ref={popoverBoxRef}>
        <OrdersFilters
          ref={filtersRef}
          activeTabState={activeTabState}
          setActiveTabState={newState => setActiveTabState(newState)}
          setSearchedOrder={setSearchedOrder}
        />
        <Popover
          disablePortal
          open={Boolean(searchedOrder)}
          onClose={onClosePopover}
          anchorEl={popoverBoxRef.current}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}>
          <div className="flex flex-col gap-2 p-3">
            <div className="flex justify-between items-center">
              <p className="sectionTitle">{searchedOrder?.orderNumber}</p>
              <IconButton onClick={onClosePopover} size="small">
                <FiX />
              </IconButton>
            </div>
            {searchedOrder && getOrderCard(searchedOrder)}
          </div>
        </Popover>
      </div>
      {data ? (
        data.orders.length > 0 ? (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {data.orders.map(order => (
              <Fragment key={order.id}>{getOrderCard(order)}</Fragment>
            ))}
          </div>
        ) : (
          <div className="mt-12">
            <NoItemsFound
              title="Oops! No orders found"
              message="Looks like you haven't placed any orders yet."
              icon={<FcPaid />}
            />
          </div>
        )
      ) : (
        <div className="flex justify-center items-center">
          <CircularProgress size={24} />
        </div>
      )}
    </div>
  );
};
