import { AppBar } from "@mui/material";
import { FC } from "react";

import MainNav from "./main_nav/MainNav";
import { renderComponent } from "../../utils/renderComponents";
import { HideOnScroll } from "../../src/atoms";

interface Props {
  topNavData?: any;
  mainNavData?: any;
}

const ModifiedAppbar: FC<Props> = ({ topNavData, mainNavData }) => {
  return (
    <HideOnScroll>
      <AppBar
        position="fixed"
        variant="elevation"
        elevation={0}
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          zIndex: ({ zIndex }) => zIndex.drawer - 1,
          background: ({ palette: { background } }) => background.default,
          color: ({ palette: { mode } }) => (mode === "dark" ? "#fff" : "#000"),
        }}>
        <div className="text-white">
          {topNavData && renderComponent(topNavData)}
        </div>
        {mainNavData && <MainNav navigationData={mainNavData as any} />}
      </AppBar>
    </HideOnScroll>
  );
};

export default ModifiedAppbar;
