import React, { FC } from "react";
import { useRouter } from "next/router";
import { HorizontalCard } from "../../HorizontalCard";
import { getTimeAndDateStringFromEPOC } from "../../../../utils";
import {
  TransformedOrderDetailsEntity,
  TransformedSingleOrderEntity,
} from "../../../../types/order_types";
import { FiEye, FiX } from "react-icons/fi";

interface Props {
  order: TransformedOrderDetailsEntity | TransformedSingleOrderEntity;
}

export const OrderCard: FC<Props> = ({ order }) => {
  const router = useRouter();
  const { items, createdAt, totalProducts, orderStatus } = order;
  const { date, time } = getTimeAndDateStringFromEPOC(createdAt);
  return (
    <HorizontalCard
      title={`Order number: #${order.orderNumber}`}
      subtitle={
        <p className="text-xs text-gray-500">
          Ordered on{" "}
          <span className="text-gray-900 font-bold">
            {date} at {time}
          </span>
        </p>
      }
      image={items[0].img}
      actionButtons={[
        {
          label: "View Details",
          onClick: () => router.push(`/order-details/${order.id}`),
        },
      ]}>
      <ul className="list-disc ml-4 flex flex-col gap-2">
        <li className="text-xs lg:text-sm text-gray-500">
          This order have total{" "}
          <span className="text-gray-900 font-bold">{totalProducts}</span>{" "}
          number of product.
        </li>
        <li className="text-xs lg:text-sm text-gray-500">
          Status of this order is{" "}
          <span className="text-gray-900 font-bold">{orderStatus}</span>.
        </li>
      </ul>
    </HorizontalCard>
  );
};
