import { FC, useCallback, useEffect, useRef, useState } from "react";
import { IconButton, TextField } from "@mui/material";
import { useRouter } from "next/router";

import SearchDropdown from "./search_dropdown/SearchDropdown";
import { useSearchProducts } from "../../src/hooks/useSearchProducts";
import { analytics } from "../../src/analytics";
import { FiSearch, FiX } from "react-icons/fi";

const AppbarSearch: FC = () => {
  const router = useRouter();
  const inputRef = useRef<HTMLInputElement>(null);
  const popoverRef = useRef<HTMLDivElement>(null);
  const [showDropdown, setShowDropdown] = useState(false);

  const { value, products, debounceValueChangeHandler, eventId } =
    useSearchProducts();

  useEffect(() => {
    const handleMouseDown = (event: any) => {
      if (
        popoverRef.current &&
        !popoverRef.current.contains(event.target) &&
        !inputRef.current?.contains(event.target)
      ) {
        setShowDropdown(false);
        inputRef.current!.value = "";
      }
    };
    window.addEventListener("mousedown", handleMouseDown);
    return () => {
      window.removeEventListener("mousedown", handleMouseDown);
    };
  }, []);

  const redirectToListingsPage = useCallback(() => {
    analytics.pushSearch({
      eventId: eventId ?? "",
      keyword: value,
      searchUrl: `/search/${value}`,
      products: products,
    });
    router.replace(`/search/${value ? value : "all"}?sortBy=totalOrders desc`);
  }, [value, products, eventId]);

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setShowDropdown(false);
    redirectToListingsPage();
    inputRef.current!.value = "";
    inputRef.current?.blur();
  };

  return (
    <div className="w-full relative">
      <form onSubmit={handleFormSubmit}>
        <TextField
          fullWidth
          color="primary"
          label="What are you looking for?"
          variant="outlined"
          size="small"
          autoComplete="off"
          onChange={debounceValueChangeHandler}
          onKeyDown={() => {}}
          InputProps={{
            inputProps: {
              ref: inputRef,
              onFocus: () => setShowDropdown(true),
            },
            endAdornment: (
              <IconButton
                size="small"
                onClick={() => {
                  if (showDropdown) {
                    inputRef.current!.blur();
                  } else {
                    inputRef.current!.focus();
                  }
                }}>
                {showDropdown ? <FiX /> : <FiSearch />}
              </IconButton>
            ),
          }}
        />
      </form>
      {showDropdown && (
        <SearchDropdown
          ref={popoverRef}
          products={products}
          redirect={redirectToListingsPage}
          closeDropdown={() => {
            setShowDropdown(false);
            inputRef.current!.value = "";
          }}
        />
      )}
    </div>
  );
};

export default AppbarSearch;
