import { useState } from "react";

import { useNotification } from "./useNotification";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { AddressBody, useCreateAddressMutation } from "../../store";

export const useCreateAddress = () => {
  const notify = useNotification();
  const { userId } = useTypedSelector(state => state.auth);

  const [showAddressDialog, setShowAddressDialog] = useState(false);

  const [createAddress, { reset: createAddressReset }] =
    useCreateAddressMutation();

  const onSubmitAddress = async (address: Omit<AddressBody, "customerId">) => {
    notify(await createAddress({ ...address, customerId: userId! }), {
      successMessage: "Address added successfully",
      onSuccess: () => {
        setShowAddressDialog(false);
        createAddressReset();
      },
    });
  };

  return {
    showAddressDialog,
    setShowAddressDialog,
    onSubmitAddress,
  };
};
