import React from "react";
import { Avatar, CircularProgress, Stack, Typography } from "@mui/material";

import { PopupViewer } from "../../../molecules";
import { StoryContainer } from "../Library";
import { ProductStoryContent } from "./ProductStoryContent";
import { useGetProductRowDataQuery } from "../../../../store";

interface Props {
  url: string;
  title: string;
  imageUrl: string;
}

export const ProductStories: React.FC<Props> = ({ url, imageUrl, title }) => {
  const { data: products, isLoading } = useGetProductRowDataQuery(url);
  const [open, setOpen] = React.useState(false);

  const toggleOpen = () => setOpen(p => !p);

  if (isLoading) {
    return <CircularProgress size={68} />;
  }

  if (!products) return null;

  return (
    <>
      <Stack gap={0.25} alignItems="center">
        <Avatar
          onClick={() => setOpen(true)}
          sx={{ width: 64, height: 64 }}
          src={imageUrl}
        />
        <Typography variant="body2">{title}</Typography>
      </Stack>
      <PopupViewer open={open} setOpen={setOpen} ignoreFullWidth>
        <StoryContainer
          length={products.length}
          content={products.map(p =>
            p.images
              .filter(i => i.type === "image")
              .map(i => ({ url: i.url, type: "image", duration: 5000 }))
          )}>
          {({ activeStep, pause, play }) => (
            <ProductStoryContent
              product={products[activeStep]}
              toggleOpen={toggleOpen}
              pause={pause}
              play={play}
            />
          )}
        </StoryContainer>
      </PopupViewer>
    </>
  );
};
