import { Dialog, Typography } from "@mui/material";
import { useRouter } from "next/router";
import {
  useEffect,
  createContext,
  useContext,
  FC,
  ReactNode,
  useState,
} from "react";
import { DialogTitle } from "@mui/material";
import { ChangeLocaleForm } from "./ChangeLocaleForm";
import { CoreAdapter } from "../../analytics/AnalyticsAdapter/shared/CoreAdapter";

export interface LocaleState {
  language: string;
  country: string;
  currency: string;
  symbol: string;
  flag: string;
}

const initialStateIndia: LocaleState = {
  language: "English",
  country: "India",
  currency: "INR",
  symbol: "₹",
  flag: "https://cdn.picpil.com/fit-in/100x100/logos/2022/09/256/india.png",
};

const initialStateRestOfTheWorld: LocaleState = {
  language: "English",
  country: "Rest of the world",
  currency: "USD",
  symbol: "$",
  flag: "https://cdn.picpil.com/fit-in/100x100/logos/2022/09/256/world.png",
};

interface ContextType extends LocaleState {
  showDialog: () => void;
}

const LocaleContext = createContext<ContextType>({
  ...initialStateIndia,
  showDialog: () => {},
});

export const LocaleContextProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const router = useRouter();
  const region = router.query.region;

  const [localeState, setLocaleState] =
    useState<LocaleState>(initialStateIndia);
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (region) {
      setLocaleState(
        region === "india" ? initialStateIndia : initialStateRestOfTheWorld
      );
      CoreAdapter.setActiveCurrency(region === "india" ? "INR" : "USD");
      if (window && window.localStorage) {
        window.localStorage.setItem(
          "localeData",
          JSON.stringify(
            region === "india" ? initialStateIndia : initialStateRestOfTheWorld
          )
        );
      }
    } else {
      if (window && window.localStorage.getItem("localeData")) {
        const data = JSON.parse(localStorage.getItem("localeData") as string);
        CoreAdapter.setActiveCurrency(data.currency as "INR" | "USD");
        setLocaleState(data);
      }
    }
  }, [router.query]);

  const handleChangeLocale = (localeData: LocaleState) => {
    setLocaleState(localeData);
    localStorage.setItem("localeData", JSON.stringify(localeData));
    CoreAdapter.setActiveCurrency(localeData.currency as "INR" | "USD");
    if (router.asPath.includes("/checkout/payment")) {
      router.replace("/checkout/cart", {}, { shallow: true });
    }
  };

  const closeDialog = () => setShow(false);

  const showDialog = () => setShow(true);

  return (
    <LocaleContext.Provider
      value={{
        showDialog,
        language: localeState.language,
        country: localeState.country,
        currency: localeState.currency,
        symbol: localeState.symbol,
        flag: localeState.flag,
      }}>
      {children}
      {show && (
        <Dialog
          TransitionProps={{ unmountOnExit: true }}
          disablePortal
          open={show}
          onClose={closeDialog}
          fullWidth
          PaperProps={{ variant: "outlined", elevation: 0 }}>
          <DialogTitle>
            <Typography variant="subtitle1" fontWeight={700}>
              Update your settings
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Set where you live, what language you speak and the currency you
              use
            </Typography>
          </DialogTitle>
          <ChangeLocaleForm
            localeState={localeState}
            onSubmit={handleChangeLocale}
            closeDialog={closeDialog}
          />
        </Dialog>
      )}
    </LocaleContext.Provider>
  );
};

export const useLocaleContext = () => useContext(LocaleContext);
