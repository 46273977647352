import { GABannerEntity } from "../../types";
import { CoreAdapter } from "./CoreAdapter";

class Banner extends CoreAdapter {
  carouselBannerClickAdapter = (banner: GABannerEntity) => {
    return {
      event: "carousel_banner_click",
      ecommerce: {
        page_url: banner.pageUrl,
        banner_position: banner.bannerPosition,
        banner_name: banner.bannerName,
        banner_url: banner.bannerLink,
        banner_link: banner.bannerUrl,
      },
    };
  };

  gridBannerClickAdapter = (banner: GABannerEntity) => {
    return {
      event: "grid_banner_click",
      ecommerce: {
        page_url: banner.pageUrl,
        banner_position: banner.bannerPosition,
        banner_name: banner.bannerName,
        banner_url: banner.bannerLink,
        banner_link: banner.bannerUrl,
      },
    };
  };
}

export const bannerAdapter = new Banner();
