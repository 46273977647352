import React, { FC } from "react";
import { FiChevronDown, FiChevronUp, FiMinus } from "react-icons/fi";
import { NextLevelMenuItem } from "../../../../../types/navigation";
import { Typography } from "@mui/material";

interface Props {
  menus: NextLevelMenuItem[];
  handleClick: (link: string) => void;
}

const MenuList: FC<{
  children?: React.ReactNode;
  title: string;
  haveChildren: boolean;
  action?: () => void;
}> = ({ children, title, haveChildren, action }) => {
  const [open, setOpen] = React.useState(false);
  return (
    <div>
      <div
        className="flex items-center gap-4"
        onClick={() => (!haveChildren && action ? action() : setOpen(p => !p))}>
        {haveChildren ? open ? <FiChevronUp /> : <FiChevronDown /> : ""}
        <p className="py-2 text-sm">{title}</p>
      </div>
      <ul className={`nested ${open && "active"} pl-3`}>
        {children && <li>{children}</li>}
      </ul>
    </div>
  );
};

export const CustomizedTreeView: FC<Props> = ({ menus, handleClick }) => {
  return (
    <div className="space-y-1">
      <Typography variant="subtitle2" textTransform="uppercase">
        Navigations
      </Typography>
      <ul className="flex flex-col gap-1">
        {menus.map((level2Menu, index) => {
          return (
            <MenuList
              key={index}
              title={level2Menu.name}
              haveChildren={level2Menu.columns.length > 0}
              action={() => handleClick(level2Menu.redirectLink!)}>
              {level2Menu.columns.map((column, i) => {
                return (
                  <MenuList key={i} title={column.header} haveChildren={true}>
                    <ul>
                      {column.values.map((value, i) => (
                        <li
                          className="py-2 text-sm"
                          key={i}
                          onClick={() => handleClick(value.redirectLink)}>
                          <div className="flex items-center gap-2 pl-3">
                            <p className="text-sm pl-2">{value.label}</p>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </MenuList>
                );
              })}
            </MenuList>
          );
        })}
      </ul>
    </div>
  );
};
