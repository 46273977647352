import { useCallback, useRef, useState } from "react";
import debounce from "lodash.debounce";

export const useTextChange = () => {
  const [value, setValue] = useState("");
  const prevValueRef = useRef(value);
  const debounceValueChangeHandler = useCallback(
    debounce((e: React.ChangeEvent<HTMLInputElement>) => {
      setValue(e.target.value);
      prevValueRef.current = value;
    }, 300),
    []
  );
  return {
    value,
    debounceValueChangeHandler,
    setValue,
    isValueChanged: prevValueRef.current !== value,
  };
};
