import React, { createContext, useContext } from "react";

export const deviceTypeContext = createContext<{ deviceType: string }>({
  deviceType: "",
});

export const DeviceTypeContextProvider: React.FC<any> = ({
  children,
  deviceType,
}) => {
  return (
    <deviceTypeContext.Provider value={{ deviceType }}>
      {children}
    </deviceTypeContext.Provider>
  );
};

export const useDeviceType = () => {
  const { deviceType } = useContext(deviceTypeContext);
  return { deviceType };
};
