import { FC } from "react";
import { Chip, ChipProps, Typography } from "@mui/material";
import { useRouter } from "next/router";

interface Props {
  title: string;
  listItems: {
    id: string;
    label: string;
    contentHandle: string;
  }[];
  chipProps?: ChipProps;
  onClick?: () => void;
}

export const ListItemGroup: FC<Props> = ({
  title,
  listItems,
  chipProps,
  onClick,
}) => {
  const router = useRouter();
  return (
    <div className="flex gap-4 flex-col">
      <Typography variant="subtitle2">{title}</Typography>
      <div className="flex flex-row lg:flex-col gap-2 lg:gap-4 flex-wrap items-center lg:items-start">
        {listItems.map(item => {
          return (
            <Chip
              onClick={() => {
                router.push(`/${item.contentHandle}`);
                if (onClick) onClick();
              }}
              key={item.id}
              size="small"
              className="text-xs font-semibold"
              label={item.label}
              {...chipProps}
            />
          );
        })}
      </div>
    </div>
  );
};
