import { TestimonialEntity } from "../../../types/testimonialsType";
import { NetworkCall } from "../NetworkCall";

export class Testimonial {
  static testimonials: TestimonialEntity[] = [];
  static async getTestimonial({ page }: { page: number; url: string }) {
    const data = await NetworkCall.query({
      url: `${process.env.BASE_URL}/customer/testimonial?limit=500&page=${page}`,
      auth: "basic",
    });
    Testimonial.testimonials = [
      ...Testimonial.testimonials,
      ...data.testimonials,
    ];
    return Testimonial.testimonials;
  }
}
