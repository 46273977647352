import { FC } from "react";
import { Box, Button, Link, Typography } from "@mui/material";
import { FiEye } from "react-icons/fi";
import { ProductCard } from "../../../../src/organisms";
import { TransformedProductEntity } from "../../../../types/transformed_product_entity";

interface Props {
  products: TransformedProductEntity[];
  handleViewAll: () => void;
  closeDropdown: () => void;
}

const SearchDropdownMain: FC<Props> = ({
  products,
  handleViewAll,
  closeDropdown,
}) => {
  const onViewAllClick = () => {
    handleViewAll();
    closeDropdown();
  };
  return (
    <div className="flex flex-col gap-4">
      <div className="flex justify-between items-center">
        <Typography variant="subtitle2">Trending Products</Typography>
        <Link
          sx={{ cursor: "pointer" }}
          onClick={onViewAllClick}
          fontSize={{ xs: "12px", sm: "14px" }}
          underline="none"
          textTransform="capitalize">
          View All
        </Link>
      </div>
      <Box
        className="grid gap-4"
        sx={{
          gridTemplateColumns: {
            xs: "1fr 1fr",
            md: "repeat(auto-fill, minmax(250px, 1fr))",
          },
        }}>
        {products.length > 0 ? (
          products.map((product: TransformedProductEntity, index: number) => {
            return (
              <div onClick={closeDropdown} key={product.id}>
                <ProductCard
                  product={product}
                  position={index}
                  autoLayout
                  size="md"
                />
              </div>
            );
          })
        ) : (
          <div className="col-span-2 lg:col-span-4 flex justify-center items-center">
            <div className="p-3 bg-gray-50 space-y-4 rounded-md shadow">
              <div className="space-y-1">
                <div className="flex gap-2 items-center text-xl">
                  <p className="sectionTitle text-lg">No products found</p>
                </div>
                <p className="text-gray-500 text-sm">
                  Please search a different keyword or you can try the suggested
                  keywords.
                </p>
              </div>
            </div>
          </div>
        )}
      </Box>
    </div>
  );
};

export default SearchDropdownMain;
