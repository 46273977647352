import { FC, useState } from "react";
import { Box, Stack } from "@mui/material";

import { StoryMediaContainer } from "./StoryMediaContainer";
import { useFullScreenHeight } from "../../../hooks";
import { AnimatePresence, motion } from "framer-motion";

export interface Content {
  url: string;
  type: "image" | "video";
  duration: number;
}

interface Props {
  length: number;
  content: Content[][];
  children: (props: {
    activeStep: number;
    pause: () => void;
    play: () => void;
  }) => React.ReactNode | React.ReactNode[];
}

type direction = "next" | "prev";

const variants = {
  enter: (direction: direction) => {
    return {
      x: direction === "next" ? "100%" : "-100%",
      opacity: 0,
      rotateZ: direction === "next" ? 45 : -45,
      y: 100,
    };
  },
  center: {
    x: 0,
    y: 0,
    opacity: 1,
    rotateZ: 0,
  },
  exit: (direction: direction) => {
    return {
      x: direction === "next" ? "-100%" : "100%",
      opacity: 0,
      rotateZ: direction === "next" ? -45 : 45,
      y: 100,
    };
  },
};

export const StoryContainer: FC<Props> = ({ length, children, content }) => {
  const [isPaused, setIsPaused] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [direction, setDirection] = useState<"next" | "prev">("next");

  const height = useFullScreenHeight();

  const changeStep = (type: "next" | "prev") => {
    setDirection(type);
    setActiveStep(p => {
      if (type === "next") {
        if (p === length - 1) return 0;
        return p + 1;
      } else {
        if (p === 0) return length - 1;
        return p - 1;
      }
    });
  };

  return (
    <Stack
      sx={{
        width: { xs: "100%", lg: "370px" },
        bgcolor: "black",
        minHeight: { xs: height, lg: "auto" },
        overflowX: "hidden",
      }}>
      <AnimatePresence exitBeforeEnter custom={direction}>
        {content.map((c, i) => {
          if (i !== activeStep) return null;
          return (
            <Box
              key={i}
              component={motion.div}
              custom={direction}
              variants={variants}
              initial="enter"
              animate="center"
              exit="exit"
              onPan={(e: any, panInfo: any) => {
                if (panInfo.offset.x > 0) changeStep("prev");
                else changeStep("next");
              }}
              transition={{ type: "linear", duration: 0.25 }}>
              <StoryMediaContainer
                content={c}
                changeStep={changeStep}
                isPaused={isPaused}
                setIsPaused={setIsPaused}
              />
            </Box>
          );
        })}
      </AnimatePresence>
      {children({
        activeStep,
        pause: () => setIsPaused(true),
        play: () => setIsPaused(false),
      })}
    </Stack>
  );
};
