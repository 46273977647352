import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "../../Store";
import { ProfileResponse, User } from "./Types";

export const profileApi = createApi({
  reducerPath: "profileApi",
  refetchOnFocus: true,
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.BASE_URL,
    prepareHeaders: (header, api) => {
      const { auth } = api.getState() as RootState;
      if (auth.isLoggedin && auth.token) {
        header.set("authorization", `Bearer ${auth.token}`);
      }
      return header;
    },
  }),
  tagTypes: ["Profile"],
  endpoints: builder => ({
    getProfile: builder.query<User, void>({
      query: () => "/customerV2",
      transformResponse: (data: ProfileResponse) => data.customer,
      providesTags: ["Profile"],
    }),
    updateProfile: builder.mutation<any, Partial<User>>({
      query: body => ({
        url: "/customerV2",
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Profile"],
    }),
  }),
});

export const { useGetProfileQuery, useUpdateProfileMutation } = profileApi;
