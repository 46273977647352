import {
  appbarAdapter,
  bannerAdapter,
  cartAdapter,
  checkoutAddressAdapter,
  checkoutCartAdapter,
  checkoutPaymentAdapter,
  errorAdpater,
  loginAdapter,
  productCardAdapter,
  productDescriptionAdapter,
  productViewAdapter,
  wishlistAdapter,
} from "../AnalyticsAdapter";
import { footerAdapter } from "../AnalyticsAdapter/shared/Footer";
import {
  GAAddToCartEntity,
  GAAddToWishlistEntity,
  GAAppbarSearchEntity,
  GABannerEntity,
  GACheckoutAddressEntity,
  GACheckoutPaymentEntity,
  GACreateProductReview,
  GAFooterOptionEntity,
  GAHashTagClick,
  GAMenuClickEntity,
  GAOrderByWhatsappClickEntity,
  GAProductCardClickEntity,
  GAProductDetailsEntity,
  GAViewCartEntity,
  GAViewItemListEntity,
  GAViewReviewsEntity,
  GAViewWishlistEntity,
} from "../types";

class GoogleAnalytics {
  private isDataLayerAvailable = () => window.dataLayer;

  private pushEventWrapper = <T>(cb: (option: T) => void) => {
    return (option: T) => {
      if (this.isDataLayerAvailable()) {
        window.dataLayer.push({ ecommerce: null });
        cb(option);
      }
    };
  };

  //Product list
  pushViewItemList = this.pushEventWrapper<GAViewItemListEntity>(data => {
    window.dataLayer.push(productViewAdapter.viewItemListAdapter(data));
  });

  //Product Card
  pushSelectItem = this.pushEventWrapper<GAProductCardClickEntity>(data => {
    window.dataLayer.push(productCardAdapter.cardItemClickAdapter(data));
  });
  pushHashTagClicked = this.pushEventWrapper<GAHashTagClick>(data => {
    window.dataLayer.push(productCardAdapter.hashTagClickAdapter(data));
  });

  //Product details
  pushViewItemDetails = this.pushEventWrapper<GAProductDetailsEntity>(data => {
    window.dataLayer.push(
      productDescriptionAdapter.viewItemDetailsAdapter(data)
    );
  });
  pushOrderByWhatsapp = this.pushEventWrapper<GAOrderByWhatsappClickEntity>(
    data => {
      window.dataLayer.push(
        productDescriptionAdapter.orderByWhatsappAdapter(data)
      );
    }
  );
  pushProuctReview = (data: GAViewReviewsEntity) => {
    window.dataLayer.push(
      productDescriptionAdapter.viewProductReviewAdapter(data)
    );
  };
  pushWriteProductReview = (data: GACreateProductReview) => {
    window.dataLayer.push(
      productDescriptionAdapter.createProductReviewAdapter(data)
    );
  };

  //Wishlist
  pushModifyWishlist = this.pushEventWrapper<GAAddToWishlistEntity>(data =>
    window.dataLayer.push(wishlistAdapter.modifyWishlistAdapter(data))
  );
  pushViewWishlist = this.pushEventWrapper<GAViewWishlistEntity>(data =>
    window.dataLayer.push(wishlistAdapter.viewWishlistAdapter(data))
  );

  //Cart
  pushAddToCart = this.pushEventWrapper<GAAddToCartEntity>(data => {
    window.dataLayer.push(cartAdapter.modifyCartProductsAdapter(data));
  });
  pushViewCart = this.pushEventWrapper<GAViewCartEntity>(data => {
    window.dataLayer.push(cartAdapter.viewCartAdapter(data));
  });

  //Checkout cart
  pushBeginCheckout = this.pushEventWrapper<GAViewCartEntity>(data => {
    window.dataLayer.push(checkoutCartAdapter.beginCheckoutAdapter(data));
  });

  //Checkout address
  pushAddShippingInfo = this.pushEventWrapper<GACheckoutAddressEntity>(data => {
    window.dataLayer.push(checkoutAddressAdapter.addShippingInfoAdapter(data));
  });

  //Checkout payment
  pushAddPaymentInfo = this.pushEventWrapper<GACheckoutPaymentEntity>(data => {
    window.dataLayer.push(checkoutPaymentAdapter.addPaymentInfoAdapter(data));
  });

  pushPurchase = this.pushEventWrapper<any>(data => {
    window.dataLayer.push(checkoutPaymentAdapter.pushPurchase(data));
  });

  //Grid
  pushCarouselBannerClick = this.pushEventWrapper<GABannerEntity>(data => {
    window.dataLayer.push(bannerAdapter.carouselBannerClickAdapter(data));
  });
  pushGridBannerClick = this.pushEventWrapper<GABannerEntity>(data => {
    window.dataLayer.push(bannerAdapter.gridBannerClickAdapter(data));
  });

  //Aapbar
  pushSearch = this.pushEventWrapper<GAAppbarSearchEntity>(data =>
    window.dataLayer.push(appbarAdapter.appbarSearchAdapter(data))
  );
  pushMenuClick = this.pushEventWrapper<GAMenuClickEntity>(data =>
    window.dataLayer.push(appbarAdapter.menuItemClickAdapter(data))
  );

  pushFooterClick = this.pushEventWrapper<GAFooterOptionEntity>(data => {
    window.dataLayer.push(footerAdapter.pushFooterMenuClick(data));
  });

  //Login page
  pushLogin = this.pushEventWrapper<{
    userId: string;
    userName: string;
    email: string;
    number: string;
  }>(data => {
    window.dataLayer.push(loginAdapter.loginAdapter(data));
  });
  pushLogout = this.pushEventWrapper<{ pageUrl: string; userId: string }>(
    data => {
      window.dataLayer.push(loginAdapter.logoutAdapter(data));
    }
  );
  pushLoginFailure = this.pushEventWrapper<{
    option: "email" | "otp";
    reason: string;
  }>(data => {
    window.dataLayer.push(loginAdapter.loginFailedAdapter(data));
  });

  //Error
  pushError = this.pushEventWrapper<{ error: any }>(data => {
    window.dataLayer.push(errorAdpater.pushApiError(data));
  });
  pushUnauthorized = this.pushEventWrapper<{ error: any }>(data => {
    window.dataLayer.push(errorAdpater.pushUnAuthError(data));
  });

  pushOpenChat = this.pushEventWrapper<GACheckoutPaymentEntity>(() => {});
  pushUpdateProfile = this.pushEventWrapper<GACheckoutPaymentEntity>(() => {});
}

export const ga = new GoogleAnalytics();
