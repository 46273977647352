import { GACheckoutPaymentEntity, GAOrder } from "../types";
import { CoreAdapter } from "./shared/CoreAdapter";

class CheckoutPayment extends CoreAdapter {
  addPaymentInfoAdapter = ({
    products,
    totalValue,
    address,
    paymentType,
    shippingCharges,
    coupon,
    discount,
    currency,
    eventId,
  }: GACheckoutPaymentEntity) => {
    return {
      event: "add_payment_info",
      ecommerce: {
        eventId,
        currency,
        value: totalValue,
        shipping_charges: shippingCharges,
        discount: discount,
        coupoun: coupon,
        ships_to_city: address.city,
        ships_to_state: address.state,
        ships_to_pincode: address.zipcode,
        payment_type:
          paymentType === "1"
            ? "Credit Card / Debit Card / Netbanking / UPI"
            : "Cash On Delivery",
        products: this.convertCartProductsToAnalyticsFormat(products),
      },
    };
  };

  pushPurchase = ({ order, eventId }: GAOrder) => {
    const {
      id,
      priceData,
      items,
      shippingAddress,
      checkoutToken,
      cartToken,
      orderNumber,
      customer,
      currency,
    } = order;
    return {
      event: "purchase_event",
      ecommerce: {
        eventId,
        transaction_id: orderNumber,
        affiliation: "Jumkey online store",
        value: priceData.subTotal,
        currency: currency === "$" ? "USD" : "INR",
        shipping: priceData.shippinCharges,
        shipping_charges: priceData.shippinCharges,
        discount: priceData.discount,
        ships_to_city: shippingAddress.city,
        ships_to_state: shippingAddress.state,
        ships_to_pincode: shippingAddress.zipcode,
        checkout_token: checkoutToken,
        cart_token: cartToken,
        order_number: orderNumber,
        order_id: id,
        customer_id: customer.id,
        products: items,
      },
    };
  };
}

export const checkoutPaymentAdapter = new CheckoutPayment();
