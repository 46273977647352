import { FC } from "react";
import { IconButton } from "@mui/material";
import { FiHeart } from "react-icons/fi";
import { pink } from "@mui/material/colors";
import { useWishlist } from "../../hooks";
import { LoginPopup } from "../../organisms/LoginPopup";

interface Props {
  itemId: string;
  size?: "small" | "medium" | "large";
  color?: string;
  onClick?: () => void;
}

export const WishlistButton: FC<Props> = ({
  itemId,
  size = "medium",
  color,
  onClick,
}) => {
  const {
    toggleWishlistHandler,
    isWishlisted,
    onLoginSuccess,
    setLoginModalOpen,
    loginModalOpen,
  } = useWishlist(itemId);
  return (
    <>
      <IconButton
        id="wishlistToggleButton"
        size={size}
        onClick={() => {
          if (onClick) onClick();
          toggleWishlistHandler();
        }}>
        {isWishlisted() ? (
          <FiHeart stroke={pink[600]} style={{ fill: pink[600] }} />
        ) : (
          <FiHeart
            stroke={color ? color : "#fff"}
            fill={color ? "#fff" : "#00000025"}
          />
        )}
      </IconButton>
      <LoginPopup
        open={loginModalOpen}
        setOpen={setLoginModalOpen}
        onSuccess={onLoginSuccess}
      />
    </>
  );
};
