import { useApiLoadingState } from "./useApiLoadingState";
import { useRouter } from "next/router";
import { useEffect, useRef, useState } from "react";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import {
  useAddToCartMutation,
  useChangeQuantityMutation,
  useGetCartQuery,
  useRemoveFromCartMutation,
} from "../../store";
import {
  CartProductEntity,
  CartTransformedResponse,
} from "../../types/cart_response_type";

import { analytics } from "../analytics";
import { useNotification } from "./useNotification";

export const useCart = (productId: string) => {
  const notify = useNotification();
  const [loginModalOpen, setLoginModalOpen] = useState(false);
  const deletedProductRef = useRef<CartProductEntity | null>(null);
  const router = useRouter();
  const isLoggedin = useTypedSelector(state => state.auth.isLoggedin);
  const { data: cartData, isFetching: cartDataFetching } = useGetCartQuery(
    undefined,
    { skip: !isLoggedin }
  );
  const { isLoading, setIsLoading } = useApiLoadingState(cartDataFetching);

  const [
    addToCart,
    {
      data: addToCartData,
      isSuccess: addToCartSuccess,
      reset: addToCartReset,
      isLoading: addToCartLoading,
    },
  ] = useAddToCartMutation();
  const [
    deleteFromCart,
    {
      data: deleteFromCartData,
      reset: deleteFromCartReset,
      isSuccess: deletFromCartSuccess,
      isLoading: deleteFromCartLoading,
    },
  ] = useRemoveFromCartMutation();
  const [
    changeCartQuantity,
    {
      data: changeCartQuantityData,
      reset: changeCartQuantityReset,
      isSuccess: changeCartQuantitySuccess,
      isLoading: changeQuntityLoading,
    },
  ] = useChangeQuantityMutation();

  const getValidProducts = () => {
    return cartData!.products.filter(
      p => p.cartItemType === "valid"
    ) as CartProductEntity[];
  };

  useEffect(() => {
    if (addToCartSuccess && !cartDataFetching) {
      const cartProduct = getValidProducts().find(p => p.id === productId);
      if (cartProduct) {
        analytics.pushAddToCart({
          eventId: addToCartData.eventId,
          type: "add",
          product: cartProduct as CartProductEntity,
        });
      }
      addToCartReset();
    }
  }, [addToCartSuccess, cartDataFetching]);

  const isAddedToCart = (variantId: string) => {
    if (cartData) {
      return getValidProducts().find(
        p => p.id === productId && p.variantId === variantId
      );
    }
    return false;
  };

  const atc = async (variantId: string) => {
    setIsLoading(true);
    notify<CartTransformedResponse | undefined>(
      await addToCart({ itemId: productId, variantId }),
      {
        successMessage: "Product added to cart",
      }
    );
  };

  const addToCartHandler = async (variantId: string) => {
    if (isLoggedin) {
      if (isAddedToCart(variantId)) {
        router.push("/checkout/cart");
      } else {
        await atc(variantId);
      }
    } else {
      localStorage.setItem(
        "addToCartProductVariant",
        JSON.stringify({ variantId })
      );
      setLoginModalOpen(true);
    }
  };

  const onLoginSuccess = async () => {
    const addToCartProduct = localStorage.getItem("addToCartProductVariant");
    if (addToCartProduct) {
      const { variantId } = JSON.parse(addToCartProduct);
      await atc(variantId);
      localStorage.removeItem("addToCartProductVariant");
    }
  };

  const handleBuyNow = async (variantId: string) => {
    if (isLoggedin) {
      if (!isAddedToCart(variantId)) {
        setIsLoading(true);
        await addToCart({ itemId: productId, variantId }).unwrap();
      }
    } else {
      localStorage.setItem(
        "addToCartProduct",
        JSON.stringify({ itemId: productId, variantId })
      );
    }
    router.push("/checkout/single-page");
  };

  useEffect(() => {
    if (deletFromCartSuccess) {
      if (deletedProductRef.current) {
        analytics.pushAddToCart({
          eventId: deleteFromCartData.eventId,
          type: "remove",
          product: deletedProductRef.current,
        });
      }
      deleteFromCartReset();
    }
  }, [deletFromCartSuccess]);
  const deleteFromCartHandler = (variantId: string) => {
    const cartProduct = cartData?.products.find(
      p => p.id === productId && p.cartItemType === "valid"
    ) as CartProductEntity;
    if (cartProduct) {
      setIsLoading(true);
      deletedProductRef.current = cartProduct;
      deleteFromCart({ itemId: productId, variantId });
    }
  };

  useEffect(() => {
    if (changeCartQuantitySuccess && !cartDataFetching) {
      const cartProduct = cartData?.products.find(
        p => p.id === productId && p.cartItemType === "valid"
      ) as CartProductEntity;
      if (cartProduct) {
        analytics.pushAddToCart({
          eventId: changeCartQuantityData.eventId,
          type: "change_quantity",
          product: cartProduct,
        });
      }
      changeCartQuantityReset();
    }
  }, [changeCartQuantitySuccess, cartDataFetching]);
  const changeCartQuantityHandler = ({
    quantity,
    variantId,
  }: {
    quantity: number;
    variantId: string;
  }) => {
    setIsLoading(true);
    changeCartQuantity({
      itemId: productId,
      requiredQuantity: quantity,
      variantId,
    });
  };

  return {
    cartData,
    isAddedToCart,
    addToCartHandler,
    deleteFromCartHandler,
    changeCartQuantityHandler,
    handleBuyNow,
    loginModalOpen,
    setLoginModalOpen,
    onLoginSuccess,
    cartDataFetching:
      addToCartLoading ||
      deleteFromCartLoading ||
      changeQuntityLoading ||
      isLoading,
  };
};
