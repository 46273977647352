import { Box, CardMedia, IconButton, Stack } from "@mui/material";
import React, { FC } from "react";
import { FiPause, FiPlay } from "react-icons/fi";
import { IconType } from "react-icons/lib";

interface Props {
  url: string;
  type: "image" | "video";

  nextSlide: () => void;
  prevSlide: () => void;

  isPaused: boolean;
  play: () => void;
  pause: () => void;
}

export const StoryMedia: FC<Props> = ({
  url,
  type,
  nextSlide,
  prevSlide,
  isPaused,
  play,
  pause,
}) => {
  const getActionIcon = (Icon: IconType, func: () => void) => {
    return (
      <IconButton size="large" sx={{ color: "#fff" }} onClick={() => func()}>
        <Icon />
      </IconButton>
    );
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: {
          xs: (window.innerWidth / 3) * 4 + "px",
          lg: (370 / 3) * 4 + "px",
        },
        position: "relative",
      }}>
      {type === "image" ? (
        <CardMedia
          component="img"
          src={url}
          sx={{
            width: "100%",
            height: "100%",
            objectFit: "contain",
          }}
        />
      ) : (
        <video
          onPlay={() => pause()}
          onEnded={() => nextSlide()} // TODO:
          controls
          autoPlay
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}>
          <source src={url} type="video/mp4"></source>
        </video>
      )}
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
        }}>
        <Box
          onClick={() => prevSlide()}
          sx={{ width: "100%", height: "100%" }}
        />
        <Box
          onClick={() => nextSlide()}
          sx={{ width: "100%", height: "100%" }}
        />
      </Box>
      <Stack
        sx={{
          width: "100%",
          position: "absolute",
          bottom: "0",
          zIndex: 10,
        }}
        alignItems="center">
        {isPaused ? getActionIcon(FiPlay, play) : getActionIcon(FiPause, pause)}
      </Stack>
    </Box>
  );
};
