import { Drawer } from "@mui/material";
import React, { createContext, useReducer, Reducer, useContext } from "react";

interface DrawerState {
  open: boolean;
  activeComponent: React.ReactElement | null;
  side: "left" | "right" | "top" | "bottom";
}

interface OpenDrawerAction {
  type: "OPEN_DRAWER";
  payload: {
    component: React.ReactElement;
    side: "left" | "right" | "top" | "bottom";
  };
}

interface CloseDrawerAction {
  type: "CLOSE_DRAWER";
}

type Action = OpenDrawerAction | CloseDrawerAction;

export const drawerContext = createContext<{
  drawerDispatch: React.Dispatch<any>;
}>({ drawerDispatch: () => {} });

const drawerReducer: Reducer<DrawerState, Action> = (state, action) => {
  switch (action.type) {
    case "OPEN_DRAWER":
      return {
        ...state,
        open: true,
        activeComponent: action.payload.component,
        side: action.payload.side,
      };
    case "CLOSE_DRAWER":
      return {
        ...state,
        open: false,
        activeComponent: null,
        side: "left",
      };
    default:
      return state;
  }
};

export const DraweContextProvider: React.FC<any> = ({ children }) => {
  const [drawerState, dispatch] = useReducer(drawerReducer, {
    open: false,
    activeComponent: null,
    side: "left",
  });
  return (
    <drawerContext.Provider value={{ drawerDispatch: dispatch }}>
      <Drawer
        disablePortal
        open={drawerState.open}
        anchor={drawerState.side}
        onClose={() => dispatch({ type: "CLOSE_DRAWER" })}>
        {drawerState.activeComponent}
      </Drawer>
      {children}
    </drawerContext.Provider>
  );
};

export const useDrawerDispatch = () => {
  const { drawerDispatch } = useContext(drawerContext);
  return drawerDispatch;
};
