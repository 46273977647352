import { GACheckoutAddressEntity } from "../types";
import { CoreAdapter } from "./shared/CoreAdapter";

class CheckoutAddress extends CoreAdapter {
  addShippingInfoAdapter = ({
    products,
    totalValue,
    address,
    eventId,
  }: GACheckoutAddressEntity) => {
    return {
      event: "add_shipping_info",
      ecommerce: {
        eventId,
        value: totalValue,
        ships_to_city: address.city,
        ships_to_state: address.state,
        ships_to_pincode: address.zipcode,
        products: this.convertCartProductsToAnalyticsFormat(products),
      },
    };
  };
}

export const checkoutAddressAdapter = new CheckoutAddress();
