import { GAHashTagClick, GAProductCardClickEntity } from "../../types";
import { CoreAdapter } from "./CoreAdapter";

class ProductCard extends CoreAdapter {
  hashTagClickAdapter = ({
    pageName,
    pageUrl,
    tagLink,
    tagName,
    cb,
  }: GAHashTagClick) => {
    return {
      event: "hash_tag_clicked",
      ecommerce: {
        page_url: pageUrl,
        page_name: pageName,
        tag_name: tagName,
        tag_link: tagLink,
      },
      eventCallback: cb,
    };
  };

  cardItemClickAdapter = ({
    product,
    pageTitle,
    position,
    cb,
  }: GAProductCardClickEntity) => {
    return {
      event: "select_item",
      ecommerce: {
        click: {
          item_list_id: pageTitle,
          item_list_name: pageTitle,
          products: this.convertTransformedProductToAnalyticsFormat(
            product,
            position
          ),
        },
      },
      eventCallback: cb,
    };
  };
}

export const productCardAdapter = new ProductCard();
