import { Stack, Typography } from "@mui/material";
import Link from "next/link";
import { FC } from "react";

const FooterContentSection: FC<{
  content: {
    label: string;
    options: { key: string; redirectUrl: string }[] | undefined;
    value: string | undefined;
  }[];
}> = ({ content }) => {
  return (
    <>
      {content.map(({ label, options, value }) => {
        return (
          <Stack spacing={2} key={label}>
            <Typography variant="subtitle1">{label}</Typography>
            <div>
              {value && (
                <Typography variant="body2" color="text.secondary">
                  {value}
                </Typography>
              )}
              {options && (
                <Stack direction="row" flexWrap="wrap" gap={1}>
                  {options.map(({ key, redirectUrl }, index) => (
                    <Link key={index} href={redirectUrl}>
                      <Typography variant="body2" color="text.secondary">
                        {key} |
                      </Typography>
                    </Link>
                  ))}
                </Stack>
              )}
            </div>
          </Stack>
        );
      })}
    </>
  );
};

export default FooterContentSection;
