import React, { FC } from "react";
import { motion } from "framer-motion";
import { Box, Stack } from "@mui/material";

interface Props {
  duration: number;
  isActive: boolean;
  isCompleted: boolean;
}

export const StoryProgress: FC<Props> = ({
  duration,
  isActive,
  isCompleted,
}) => {
  return (
    <Stack
      direction="row"
      sx={{
        flex: 1,
        height: "4px",
        borderRadius: "10px",
        bgcolor: isCompleted ? "#fff" : "gray",
      }}>
      <Box
        component={motion.div}
        sx={{
          background: "#fff",
          height: "4px",
          borderRadius: "10px",
          flex: 0.5,
        }}
        initial={{ flex: 0 }}
        animate={
          isActive
            ? {
                flex: 1,
                transition: {
                  duration: duration / 1000,
                  ease: "linear",
                },
              }
            : {}
        }
      />
    </Stack>
  );
};
