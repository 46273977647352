import { ProductsEntity2 } from "../types/product_entity_types_2";
import { captureException } from "@sentry/nextjs";
import {
  ProductPrice,
  TransformedProductDetailsEntity,
  TransformedProductEntity,
} from "../types/transformed_product_entity";
import { CreativeEntity, ProductEntity } from "../types/product_entity_types";
import toast from "react-hot-toast";
import { NextRouter } from "next/router";
import { analytics } from "../src/analytics";
import produce from "immer";
import { ProductListings } from "../src/services";

export const newProductTransformer = (
  prod: ProductEntity
): TransformedProductEntity => {
  return {
    id: prod.id,
    category: prod.category,
    brand: prod.brand,
    title: prod.shortTitle,
    tags: prod?.saleBadges?.map(badge => ({
      name: badge.displayName,
      redirectLink: badge.redirection,
    })),
    price: prod.currency.map(c => ({
      sellingPrice: c.sellingPrice,
      discount: c.discount,
      mrp: c.mrp,
      code: c.code,
    })),
    images: prod.creative
      .sort((a: CreativeEntity, b: CreativeEntity) => {
        return a.position - b.position;
      })
      .map(el => {
        return {
          type: el.type === "IMAGE" ? "image" : "video",
          url: el.url,
          name: el.imageType,
          position: el.position,
        };
      }),
    canonicalUrl: prod.canonicalUrl,
    sizes: prod.availableSizes,
    sku: prod.vendorSku,
    uniqueCode: prod.uniqueId,
    hsnNumber: prod.hsnCode,
    productHandle: prod.handle,
    isAvailable: prod.availability !== "OUT-OF-STOCK",
    rating: prod.averageRating,
    variants: prod.variant
      .sort()
      .reverse()
      .map(variant => {
        return {
          id: variant.id,
          size: variant.size,
          availability: variant.availability,
          shippingProfile: variant.shippinProfile,
          sku: variant.vendorSku,
        };
      }),
  };
};

export const productDetailsTransFormer = (
  prod: ProductEntity
): TransformedProductDetailsEntity => {
  const transformedProduct: TransformedProductEntity =
    newProductTransformer(prod);
  return {
    ...transformedProduct,
    sku: prod.vendorSku,
    title: prod.title,
    productDetails: prod.specifications.map(spec => {
      const value = prod[camelCase(spec)];
      if (value && value.length > 0 && value[0] !== "") {
        return {
          key: spec,
          value: value?.join(", "),
        };
      } else {
        return null;
      }
    }),
    dimensions: prod.variant[0].dimension,
    averageRating: prod.averageRating,
    careTip: prod.careTip,
    disclaimer: prod.disclaimer,
    seo: prod.seo,
  };
};

export const oldProductTransformer = (
  prod: ProductsEntity2
): TransformedProductEntity => {
  const getPrice = () => {
    const variant = prod.variants[0];
    const rupee: ProductPrice = {
      sellingPrice: variant.variantSellingPrice,
      mrp: variant.variantMrp,
      code: "INR",
      discount: Math.ceil(100 - variant.variantDiscount),
    };
    const usdData = JSON.parse(variant.currencies)[0];
    const usd: any = {
      sellingPrice: usdData.convertedPrice,
      mrp: usdData.convertedMrp,
      code: "USD",
      discount: usdData.discount,
    };
    return [rupee, usd];
  };

  const images = prod?.variants[0]?.images
    ? JSON.parse(prod?.variants[0].images)[0]
        .imagesList.sort((a: CreativeEntity, b: CreativeEntity) => {
          return a.position - b.position;
        })
        .map((el: any) => ({
          type: "image",
          url: el.url,
          name: el.imageType,
          position: el.position,
        }))
    : [];
  return {
    rating: 5,
    isAvailable: true,
    productHandle: prod.handle,
    id: prod.id,
    category: prod.category,
    brand: prod.brand,
    title: prod.title,
    tags: [],
    canonicalUrl: "",
    sizes: [""],
    sku: prod.sku,
    uniqueCode: "",
    hsnNumber: "",
    price: getPrice(),
    images: images,
    variants: prod.variants.map(v => ({
      availability: prod.status,
      id: v.id,
      sku: v.variantSku,
      size: v.variantOption1,
      shippingProfile: JSON.parse(v.shippingProfile),
    })),
  };
};

export function camelCase(str: string) {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index == 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, "");
}

export const camelCaseToTitleCase = (str: string) => {
  return str
    .replace(/([A-Z])/g, " $1")
    .replace(/^./, str => {
      return str.toUpperCase();
    })
    .trim();
};

export const getTimeAndDateStringFromEPOC = (EPOC: number) => {
  const date = new Date(new Date(0).setUTCSeconds(EPOC));
  return {
    date: date.toDateString(),
    time: date.toLocaleTimeString(),
  };
};

export const apiErrorHandler = <T, R = unknown>(func: (arg: T) => any) => {
  return async (arg: T) => {
    try {
      const data = await func(arg);
      return data as R;
    } catch (err: any) {
      let message = "";
      if (err.info) {
        const { httpResonseCode, description } = err.info;
        message = description;
        captureException(err.info);
        if (httpResonseCode === 401) {
          analytics.pushUnauthorized({ error: err.info });
          localStorage.removeItem("token");
          window.location.href = "/login";
        } else {
          analytics.pushError({ error: err.info });
        }
      } else {
        message = err.message;
      }
      toast.error(`${message}`);
    }
  };
};

export function compareSortingParameters(a: any, b: any) {
  if (a.name < b.name) {
    return -1;
  }
  if (a.name > b.name) {
    return 1;
  }
  return 0;
}

export function parseJwt(token: string) {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}

export const getPageTitle = (router: NextRouter) => {
  let pageTitle;
  if (router.asPath.includes("collections")) {
    pageTitle = router?.query?.id
      ? router?.query?.id
      : "Jumkey jewellery store";
  } else if (router.asPath.includes("search")) {
    pageTitle = "Search Results";
  } else {
    pageTitle = "Jumkey jewellery store";
  }
  return pageTitle;
};

export const emailIdRegexp =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const mobileNumberRegexp = /^[0-9]{10}$/;
// export const mobileNumberRegexp = /[7-9]{1}[0-9]{9}/

export const addDaysToDate = (days: number) => {
  const now = new Date();
  return new Date(now.setDate(now.getDate() + days)).toDateString();
};

export const getRowProductsForProductCarousel = async (json: any) => {
  const requests: any[] = [];
  json.children.forEach((section: any) => {
    if (section._type === "productCarousel") {
      const url = section.url;
      if (url) {
        requests.push(
          ProductListings.getProducts(url).then(data => {
            return { key: url, value: data };
          })
        );
      }
    }
  });
  const data = await Promise.all(requests);
  const updatedJson = produce(json, (draft: any) => {
    draft.children.forEach((c: any) => {
      if (c._type === "productCarousel") {
        const url = c.url;
        const product = data.find(d => d.key === url);
        if (product) {
          c.products = product.value.products;
        }
      }
    });
  });
  return updatedJson;
};
