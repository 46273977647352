import React, { FC } from "react";
import {
  Avatar,
  Box,
  Button,
  CardHeader,
  IconButton,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import Navlink from "next/link";
import { FiX } from "react-icons/fi";
import { TestimonialEntity } from "../../../../store";

interface Props {
  testimonial: TestimonialEntity;
  close: () => void;
}

export const TestimonialDetails: FC<Props> = ({ testimonial, close }) => {
  return (
    <Stack sx={{ p: 3 }} justifyContent="flex-end" gap={2}>
      <CardHeader
        sx={{ p: 0 }}
        avatar={
          <Avatar
            src={
              "https://cdn.picpil.com/logos/2022/05/147/1242566_original.jpg"
            }
          />
        }
        title={testimonial.customerName}
        titleTypographyProps={{ variant: "body1", color: "#fff" }}
        subheader="Verified Customer on Jumkey"
        subheaderTypographyProps={{ variant: "body2", color: "#fff" }}
        action={
          <IconButton
            onClick={close}
            sx={{ alignSelf: "flex-end", color: "#fff" }}>
            <FiX />
          </IconButton>
        }
      />
      <Box sx={{ maxHeight: "200px", overflowY: "auto" }}>
        <Typography variant="body2" color="#fff">
          {testimonial.testimonial}
        </Typography>
      </Box>
      <Link component={Navlink} href={testimonial.productUrl}>
        <Button variant="contained" size="small">
          View Product
        </Button>
      </Link>
    </Stack>
  );
};
