import { createElement } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import {
  ListItemButton,
  ListItemText,
  Grid,
  Box,
  CardHeader,
  Button,
  CardActions,
  CardContent,
  Stack,
  Card,
  Chip,
} from "@mui/material";

import { ProductCarousel } from "../src/organisms";
import {
  LinkItem,
  Text,
  CountdownTimer,
  GridSection,
  GridSectionTitle,
  GridImageItem,
  BannerCarousel,
  FlashSaleGrid,
  SEO,
  BannerTextCarousel,
  CarouselContainer,
} from "../src/atoms";

const keysToComponentMap: any = {
  SEO,
  Stack,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Grid,
  Box,
  Card,
  Chip,
  CardHeader,
  Button,
  CardActions,
  CardContent,
  BannerTextCarousel,
  FlashSaleGrid,
  GridSection,
  GridSectionTitle,
  GridImageItem,
  ProductCarousel,
  BannerCarousel,
  H1: Text.H1,
  H2: Text.H2,
  H3: Text.H3,
  P: Text.P,
  Caption: Text.Caption,
  Link: LinkItem,
  CountdownTimer,
  CarouselContainer,
};

const propAndStyleTransformer = (styles: any) => {
  let mappedStyles: any = {};
  styles.forEach((style: any) => {
    if (style.value.component) {
      mappedStyles[style.key] = renderComponent(style.value);
    } else {
      mappedStyles[style.key] = style.value;
    }
  });
  return mappedStyles;
};

export const renderComponent = (config: any) => {
  if (typeof keysToComponentMap[config.component] !== "undefined") {
    let allProps: any = {};
    if (config.props) {
      allProps = propAndStyleTransformer(config.props);
    }
    allProps.id = config.id;
    allProps.key = config.id;
    return createElement(
      keysToComponentMap[config.component],
      allProps,
      config.children &&
        (typeof config.children === "string"
          ? config.children
          : config.children.map((c: any) => renderComponent(c)))
    );
  }
};
