import { useTheme } from "@mui/material";
import { FC } from "react";

const footerIconsData = [
  {
    lightImageUrl:
      "https://cdn.picpil.com/fit-in/400x400/icons/2022/06/165/return-box_(1)_1.png",
    darkImageUrl: "/return_box.svg",
    label: "Free 7 days returns for all domestic orders",
  },
  {
    lightImageUrl: "https://cdn.picpil.com/icons/2022/06/166/cod.png",
    darkImageUrl: "/cash_on_delivery.svg",
    label: "Cash on delivery available on all orders",
  },
  {
    lightImageUrl:
      "https://cdn.picpil.com/fit-in/100x100/icons/2022/06/165/quality_1.png",
    darkImageUrl: "/quality.svg",
    label: "100% verified and quality checked products",
  },
  {
    lightImageUrl:
      "https://cdn.picpil.com/fit-in/400x400/icons/2022/06/165/customer-service_1.png",
    darkImageUrl: "/customer_service.svg",
    label: "Personal manager to assist your queries",
  },
];

const FooterTopSection: FC = () => {
  const {
    palette: { mode },
  } = useTheme();
  return (
    <div className="grid grid-cols-2 lg:grid-cols-4 gap-8">
      {footerIconsData.map(({ lightImageUrl, darkImageUrl, label }, index) => (
        <div className="flex justify-center" key={label}>
          <div
            key={index}
            className={`flex flex-col items-center lg:items-start gap-3 ${
              index === footerIconsData.length - 1
                ? "col-span-2 lg:col-span-1"
                : ""
            }`}>
            <div className="w-12 h-12 rounded-full">
              <img
                src={mode === "dark" ? darkImageUrl : lightImageUrl}
                className="w-full h-full object-contain"
              />
            </div>
            <p className="text-center lg:text-left w-32 lg:w-44 text-sm font-semibold text-gray-900 tracking-wide">
              {label}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default FooterTopSection;
