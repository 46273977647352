import Link from "next/link";
import { FC, useState } from "react";
import { useRouter } from "next/router";
import {
  Box,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import {
  FiArrowDown,
  FiArrowUp,
  FiFacebook,
  FiInstagram,
  FiPhoneCall,
  FiTwitter,
  FiYoutube,
} from "react-icons/fi";
import { analytics } from "../../analytics";

interface Option {
  key: string;
  redirectUrl: string;
}

interface NavColumnProps {
  label: string;
  options: Option[];
}

interface Props {
  columns: NavColumnProps[];
  socialMedialinks: Option[];
}

const socialMedias = [
  {
    icon: <FiFacebook />,
    redirectUrl: "https://www.facebook.com/jumkeyfashionjewellery",
    color: "text-indigo-500",
  },
  {
    icon: <FiTwitter />,
    redirectUrl: "https://twitter.com/jumkeyclub",
    color: "text-blue-500",
  },
  {
    icon: <FiYoutube />,
    redirectUrl: "https://www.youtube.com/channel/UCM9J4AJMh2gvFzYz6OWu9TQ",
    color: "text-red-500",
  },
  {
    icon: <FiInstagram />,
    redirectUrl: "https://www.instagram.com/jumkeyclub",
    color: "text-pink-500",
  },
];

const FooterNavigationSection: FC<{ content: Props }> = ({
  content: { columns },
}) => {
  return <FooterNav columns={columns} />;
};

const FooterNav: FC<{ columns: NavColumnProps[] }> = ({ columns }) => {
  return (
    <div className="grid grid-cols-1 lg:grid-cols-5 gap-4 flex-1 bg-white px-3 lg:px-8 py-3 lg:py-8 rounded-md">
      {columns.map(column => (
        <FooterNavColumn key={column.label} {...column} />
      ))}
      {columns.length < 4
        ? new Array(4 - columns.length).fill(0).map((_, i) => {
            return <div key={i}></div>;
          })
        : null}
      <div className="hidden lg:block">
        <FooterNavSocialMedia />
      </div>
    </div>
  );
};

const FooterNavColumn: FC<NavColumnProps> = ({ label, options }) => {
  const router = useRouter();
  const show = useState(false);
  return (
    <>
      <Stack gap={2} display={{ xs: "none", md: "flex" }}>
        <Typography variant="subtitle1">{label}</Typography>
        <Stack gap={1}>
          {options.map(option => (
            <Link key={option.key} href={option.redirectUrl}>
              <div
                onClick={() =>
                  analytics.pushFooterClick({
                    optionName: option.key,
                    optionLink: option.redirectUrl,
                    pageUrl: router.asPath,
                  })
                }>
                <Typography variant="body2" color="text.secondary">
                  {option.key}
                </Typography>
              </div>
            </Link>
          ))}
        </Stack>
      </Stack>
      <Box display={{ xs: "block", md: "none" }} width="100%">
        <ListItemButton onClick={() => show[1](p => !p)}>
          <ListItemIcon>
            {show[0] ? <FiArrowUp /> : <FiArrowDown />}
          </ListItemIcon>
          <ListItemText>{label}</ListItemText>
        </ListItemButton>
        {show[0] && (
          <List>
            {options.map(option => (
              <Link key={option.key} href={option.redirectUrl}>
                <div
                  onClick={() =>
                    analytics.pushFooterClick({
                      optionName: option.key,
                      optionLink: option.redirectUrl,
                      pageUrl: router.asPath,
                    })
                  }>
                  <ListItemButton>
                    <ListItemText>{option.key}</ListItemText>
                  </ListItemButton>
                </div>
              </Link>
            ))}
          </List>
        )}
      </Box>
    </>
  );
};

const FooterNavSocialMedia: FC = () => {
  return (
    <Stack alignItems="flex-end" gap={2}>
      <Stack alignItems="flex-end" gap={1}>
        <Typography variant="subtitle1">Contact Number</Typography>
        <Stack direction="row" gap={1} alignItems="center">
          <FiPhoneCall size={14} />
          <Typography variant="body2">9902631591</Typography>
        </Stack>
      </Stack>
      <Stack alignItems="flex-end" gap={1}>
        <Typography variant="subtitle1">Follow us</Typography>
        <Stack direction="row" gap={2} alignItems="center">
          {socialMedias.map(s => (
            <Link href={s.redirectUrl} key={s.color}>
              <div className={s.color}>{s.icon}</div>
            </Link>
          ))}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default FooterNavigationSection;
