import { useTypedSelector } from "./../../hooks/useTypedSelector";
import { useRouter } from "next/router";
import { analytics } from "../analytics";
import { useTypedDispatch } from "../../hooks/useTypedDispatch";
import { logout } from "../../store";

export const useLogout = () => {
  const dispatch = useTypedDispatch();
  const { isLoggedin, userId } = useTypedSelector(({ auth }) => ({
    isLoggedin: auth.isLoggedin,
    userId: auth.userId,
  }));
  const router = useRouter();

  const pushGaData = () => {
    analytics.pushLogout({ userId: userId!, pageUrl: router.asPath });
  };

  const isRedirectToHomePageRequired = () => {
    const path = router.asPath;
    return (
      (path.includes("orders") ||
        path.includes("cart") ||
        path.includes("checkout") ||
        path.includes("profile") ||
        path.includes("wishlist")) &&
      !path.includes("manual") &&
      !path.includes("single-page")
    );
  };

  const handleLogout = () => {
    if (isLoggedin) {
      pushGaData();
      dispatch(logout());
      if (isRedirectToHomePageRequired()) {
        router.replace("/");
      }
    }
  };

  return { handleLogout, isRedirectToHomePageRequired };
};
