import { FC, useEffect, useState } from "react";
import {
  Button,
  Dialog,
  InputAdornment,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  IconButton,
  MenuItem,
  CircularProgress,
  InputLabel,
  Stack,
  FormHelperText,
  CardContent,
  CardHeader,
} from "@mui/material";
import * as yup from "yup";
import { useFormik } from "formik";

import { AddressBody } from "../../../types/address_types";
import { FiX } from "react-icons/fi";
import {
  PincodeMapEntity,
  useLazyGetAddressByPincodeQuery,
} from "../../../store";
import { captureException } from "@sentry/nextjs";
import { CustomTextField } from "../../atoms/CustomTextField";

interface Props {
  open: boolean;
  onClose: () => void;
  onSubmit: (address: AddressBody) => void;
  initialValue?: any;
  currency: "INR" | "USD";
}

const formInitialValue: Omit<AddressBody, "customerId"> = {
  fullname: "",
  addressType: "",
  companyName: "",
  addressLineOne: "",
  addressLineTwo: "",
  city: "",
  landmark: "",
  state: "",
  country: "",
  emailId: "",
  zipcode: "",
  mobileNumber: "",
};

const validationSchema = yup.object({
  fullname: yup
    .string()
    .min(3, "Minimum 3 characters are required")
    .matches(/^[A-Za-z .]*$/, "Only alphabets are allowed for this field")
    .required("Full Name is required"),
  emailId: yup.string().email().required("Email id is required"),
  mobileNumber: yup
    .string()
    .length(10, "Mobile number must be exactly 10 digits")
    .required("Mobile number is required"),
  zipcode: yup
    .string()
    .length(6, "Pincode should be exactly 6 digits.")
    .required("Pincode is required"),
  addressLineOne: yup
    .string()
    .matches(
      /^[A-Za-z )(-_><?|#$&.\n]*$/,
      'Only alphabets, numbers, comma(","), hash("#"), hyphen("-"), dot(".") and slash("/") are allowed for this field'
    )
    .required("Address Line One is required"),
  addressLineTwo: yup
    .string()
    .matches(
      /^[A-Za-z )(-_><?|#$&.\n]*$/,
      'Only alphabets, numbers, comma(","), hash("#"), hyphen("-"), dot(".") and slash("/") are allowed for this field'
    )
    .required("Address Line Two is required"),
  landmark: yup
    .string()
    .matches(
      /^[A-Za-z )(-_><?|#$&.\n]*$/,
      'Only alphabets, numbers, comma(","), hash("#"), hyphen("-"), dot(".") and slash("/") are allowed for this field'
    )
    .required("Landmark is required"),
  city: yup.string().required("City is required"),
  state: yup.string().required("State is required"),
  country: yup.string().required("Country is required"),
  addressType: yup.string().required("Address Type is required"),
});

export const AddressDialog: FC<Props> = ({
  open,
  onClose,
  onSubmit,
  initialValue,
  currency,
}) => {
  const [cityMenuOptions, setCityMenuOption] = useState<PincodeMapEntity[]>([]);
  const [
    getPincodeData,
    { data: pincodeData, isSuccess, isLoading, isFetching },
  ] = useLazyGetAddressByPincodeQuery();
  const {
    values,
    handleChange,
    touched,
    errors,
    resetForm,
    handleSubmit,
    setValues,
    setErrors,
  } = useFormik({
    initialValues: initialValue
      ? {
          fullname: initialValue.fullName,
          addressType: initialValue.addressType,
          companyName: initialValue.companyName,
          addressLineOne: initialValue.addressLineOne,
          addressLineTwo: initialValue.addressLineTwo,
          city: initialValue.city,
          landmark: initialValue.landmark,
          state: initialValue.state,
          country: initialValue.country,
          emailId: initialValue.emailId,
          zipcode: initialValue.zipcode,
          mobileNumber:
            currency === "INR"
              ? initialValue.mobile.slice(2, initialValue.mobile.length)
              : initialValue.mobile,
        }
      : { ...formInitialValue, country: currency === "INR" ? "India" : "" },
    validationSchema: currency === "INR" ? validationSchema : null,
    onSubmit: async (values: any) => {
      try {
        await onSubmit({
          ...values,
          zipcode: String(values.zipcode),
          fullname: (values.fullname as string).replaceAll(".", " "),
          mobileNumber:
            currency === "INR"
              ? "91" + values.mobileNumber
              : `${values.mobileNumber}`,
        });
      } catch (err) {
        console.log(err);
      }
    },
  });

  useEffect(() => {
    if (String(values.zipcode).length === 6 && currency === "INR") {
      getPincodeData(values.zipcode, false);
    }
  }, [values.zipcode]);

  useEffect(() => {
    if (pincodeData) {
      if (pincodeData.length === 0) {
        setErrors({ ...errors, zipcode: "Invalid pincode entered" });
        setCityMenuOption([]);
      } else {
        setCityMenuOption(pincodeData);
        const { state, place, district } = pincodeData[0];
        if (pincodeData.length === 1) {
          setValues({
            ...values,
            state,
            city: district,
            addressLineTwo: initialValue ? initialValue.addressLineTwo : place,
          });
        } else {
          setValues({ ...values, state, city: district });
        }
      }
    }
  }, [pincodeData, initialValue]);

  const showAllFormFields =
    (cityMenuOptions.length > 0 && String(values.zipcode).length === 6) ||
    currency !== "INR";

  return (
    <form onSubmit={handleSubmit}>
      <CardHeader
        title={initialValue ? "Edit Address" : "Add Address"}
        titleTypographyProps={{
          variant: "subtitle1",
          textTransform: "uppercase",
          fontWeight: 700,
        }}
        subheader={
          currency === "INR"
            ? "Only Indian addresses are allowed"
            : "Please fill the following fields."
        }
        subheaderTypographyProps={{
          variant: "body2",
        }}
        action={
          <IconButton onClick={onClose}>
            <FiX />
          </IconButton>
        }
      />

      <CardContent sx={{ flex: 1 }}>
        <Stack gap={3}>
          <CustomTextField
            InputProps={{
              autoComplete: "off",
            }}
            required
            variant="outlined"
            label={currency === "INR" ? "Pincode" : "Pincode/Zipcode"}
            type={currency === "INR" ? "number" : "text"}
            name="zipcode"
            value={values.zipcode}
            onChange={handleChange}
            error={Boolean(errors.zipcode)}
            helperText={errors.zipcode as string}
          />
          {(isLoading || isFetching) && (
            <Stack justifyContent="center" alignItems="center">
              <CircularProgress size={20} />
            </Stack>
          )}
          {showAllFormFields && (
            <>
              <CustomTextField
                InputProps={{
                  autoComplete: "off",
                }}
                required
                variant="outlined"
                label="City/District"
                disabled={currency === "INR"}
                name="city"
                value={values.city}
                onChange={handleChange}
                error={touched.city && Boolean(errors.city)}
                helperText={touched.city && (errors.city as string)}
              />
              <CustomTextField
                InputProps={{
                  autoComplete: "off",
                }}
                required
                variant="outlined"
                label="State"
                disabled={currency === "INR"}
                name="state"
                value={values.state}
                onChange={handleChange}
                error={touched.state && Boolean(errors.state)}
                helperText={touched.state && (errors.state as string)}
              />
              {
                <CustomTextField
                  InputProps={{
                    autoComplete: "off",
                  }}
                  required
                  variant="outlined"
                  label="Locality"
                  name="addressLineTwo"
                  select={currency === "INR"}
                  value={values.addressLineTwo}
                  onChange={handleChange}
                  error={
                    touched.addressLineTwo && Boolean(errors.addressLineTwo)
                  }
                  helperText={
                    touched.addressLineTwo && (errors.addressLineTwo as string)
                      ? (errors.addressLineTwo as string)
                      : currency === "INR"
                      ? "Please select your locality from the dropdown."
                      : null
                  }>
                  {cityMenuOptions.map(m => (
                    <MenuItem key={m.place} value={m.place}>
                      {m.place}
                    </MenuItem>
                  ))}
                </CustomTextField>
              }

              <CustomTextField
                InputProps={{
                  autoComplete: "off",
                }}
                required
                label="Country"
                variant="outlined"
                name="country"
                disabled={currency === "INR"}
                value={values.country}
                onChange={handleChange}
                error={touched.country && Boolean(errors.country)}
                helperText={touched.country && (errors.country as string)}
              />

              <CustomTextField
                InputProps={{
                  autoComplete: "off",
                }}
                required
                variant="outlined"
                label="Full Name"
                name="fullname"
                value={values.fullname}
                onChange={handleChange}
                error={touched.fullname && Boolean(errors.fullname)}
                helperText={touched.fullname && (errors.fullname as string)}
              />
              <CustomTextField
                required
                variant="outlined"
                label="Mobile Number"
                type="number"
                name="mobileNumber"
                value={values.mobileNumber}
                onChange={handleChange}
                error={touched.mobileNumber && Boolean(errors.mobileNumber)}
                helperText={
                  touched.mobileNumber && errors.mobileNumber
                    ? (errors.mobileNumber as string)
                    : currency !== "INR"
                    ? "Please enter your mobile number with country code."
                    : "Please enter your 10 digit mobile number"
                }
                InputProps={{
                  inputProps: { type: "number", min: 0 },
                  autoComplete: "off",
                  startAdornment:
                    currency === "INR" ? (
                      <InputAdornment position="start">
                        <p>+91</p>
                      </InputAdornment>
                    ) : null,
                }}
              />
              <CustomTextField
                InputProps={{
                  autoComplete: "off",
                }}
                required
                variant="outlined"
                label="Email Id"
                name="emailId"
                value={values.emailId}
                onChange={handleChange}
                error={touched.emailId && Boolean(errors.emailId)}
                helperText={touched.emailId && (errors.emailId as string)}
              />
              <CustomTextField
                InputProps={{
                  autoComplete: "off",
                }}
                required
                className="col-span-2"
                variant="outlined"
                label="Address"
                placeholder="House number, Building name, Street, Locality"
                multiline
                rows={5}
                name="addressLineOne"
                value={values.addressLineOne}
                onChange={handleChange}
                error={touched.addressLineOne && Boolean(errors.addressLineOne)}
                helperText={
                  touched.addressLineOne && (errors.addressLineOne as string)
                }
              />
              <CustomTextField
                InputProps={{
                  autoComplete: "off",
                }}
                required
                variant="outlined"
                label="Landmark"
                name="landmark"
                value={values.landmark}
                onChange={handleChange}
                error={touched.landmark && Boolean(errors.landmark)}
                helperText={touched.landmark && (errors.landmark as string)}
              />
              <Stack gap={1}>
                <InputLabel>Address Type</InputLabel>
                <RadioGroup
                  row
                  name="row-radio-buttons-group"
                  value={values.addressType}
                  onChange={handleChange}>
                  <FormControlLabel
                    value="HOME"
                    name="addressType"
                    control={<Radio />}
                    label="Home"
                  />
                  <FormControlLabel
                    name="addressType"
                    value="OFFICE"
                    control={<Radio />}
                    label="Work"
                  />
                </RadioGroup>
                {touched.country && errors.addressType && (
                  <FormHelperText error={true}>
                    {errors.addressType as string}
                  </FormHelperText>
                )}
              </Stack>

              {values.addressType === "OFFICE" && (
                <CustomTextField
                  InputProps={{
                    autoComplete: "off",
                  }}
                  label="Company Name"
                  required
                  variant="outlined"
                  name="companyName"
                  value={values.companyName}
                  onChange={handleChange}
                  error={touched.companyName && Boolean(errors.companyName)}
                  helperText={
                    touched.companyName && (errors.companyName as string)
                  }
                />
              )}
            </>
          )}
        </Stack>
        <Stack direction="row" justifyContent="flex-end" gap={1} sx={{ mt: 3 }}>
          <Button onClick={() => resetForm()} variant="outlined">
            Reset
          </Button>
          <Button
            type="submit"
            variant="contained"
            onClick={() => {
              try {
                if (Object.values(errors).some(v => Boolean(v))) {
                  throw {
                    name: "Invalid address input",
                    message: "Invalid address input",
                    ...values,
                  };
                }
              } catch (err) {
                captureException(err);
              }
            }}>
            {initialValue ? "Update address" : "Add address"}
          </Button>
        </Stack>
      </CardContent>
    </form>
  );
};
