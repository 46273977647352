import { useMemo } from "react";
import { Stack } from "@mui/material";

import { ProductStories } from "./ProductStories";
import { TestimonialStories } from "./TestimonialStories";
import { useRouter } from "next/router";

export const StoryRow = () => {
  const router = useRouter();
  const url = useMemo(() => {
    const id = router.query.id;
    if (router.asPath.includes("collections")) {
      return `${process.env.BASE_URL}/listings/items/collections?collectionHandle=${id}&limit=100&sortBy=createdAt+desc`;
    } else {
      return `${process.env.BASE_URL}/listings/items/search?query=${id}&limit=100&sortBy=createdAt+desc`;
    }
  }, [router]);
  return (
    <Stack direction="row" gap={2}>
      <TestimonialStories />
      <ProductStories
        url={url}
        title="Just In"
        imageUrl="https://cdn.picpil.com/logos/2022/04/94/new-arrivals-500.jpeg"
      />
      <ProductStories
        url="https://api.jumkey.com/v5/listings/items/collections?collectionHandle=deals-of-the-week-638211933022916050944&limit=12"
        title="Deals of the Week"
        imageUrl="https://cdn.picpil.com/banners/2023/06/171/dealOfTheWeek_200x200.Jpg"
      />
    </Stack>
  );
};
