import {
  Autocomplete,
  AutocompleteRenderGetTagProps,
  AutocompleteRenderInputParams,
  Checkbox,
  Chip,
  ListItem,
  TextField,
} from "@mui/material";
import { FC, HTMLAttributes } from "react";
import { FiCheckCircle, FiCircle } from "react-icons/fi";
import { camelCaseToTitleCase, compareSortingParameters } from "../../../utils";

export interface FilterOption {
  name: string;
  value: number;
}

interface Props {
  item: {
    name: string;
    value: FilterOption[];
  };
  onChange: (value: FilterOption[]) => void;
  value: FilterOption[];
  size?: "small" | "medium";
}

export const SearchSelect: FC<Props> = ({
  item,
  onChange,
  value: selectedValue,
  size = "medium",
}) => {
  const { name, value } = item;
  return (
    <Autocomplete
      size={size}
      multiple
      id="checkboxes-tags-demo"
      options={value.sort(compareSortingParameters)}
      disableCloseOnSelect
      defaultValue={selectedValue}
      isOptionEqualToValue={(option, value) => {
        return option.name === value.name && option.value === value.value;
      }}
      getOptionLabel={(option: FilterOption) => option.name}
      onChange={(_, value: FilterOption[]) => onChange(value)}
      renderTags={(value, getTagProps: AutocompleteRenderGetTagProps) => (
        <AutoCompleteTags value={value} getTagProps={getTagProps} />
      )}
      renderOption={(props, option, { selected }) => (
        <AutoCompleteOptions
          key={props.id}
          props={props}
          option={option}
          selected={selected}
        />
      )}
      renderInput={params => (
        <AutoCompleteInput params={params} label={camelCaseToTitleCase(name)} />
      )}
    />
  );
};
const AutoCompleteTags: FC<{
  value: FilterOption[];
  getTagProps: AutocompleteRenderGetTagProps;
}> = ({ value, getTagProps }) => {
  return (
    <>
      {value.map((item, index: number) => (
        <Chip
          variant="outlined"
          label={item.name}
          size="small"
          {...getTagProps({ index })}
        />
      ))}
    </>
  );
};

const AutoCompleteOptions: FC<{
  props: HTMLAttributes<HTMLLIElement>;
  option: FilterOption;
  selected: boolean;
}> = ({ props, option, selected }) => {
  return (
    <ListItem
      {...props}
      disableGutters
      disablePadding
      style={{ fontSize: "14px" }}>
      <Checkbox
        icon={<FiCircle style={{ fontSize: "18px" }} />}
        checkedIcon={<FiCheckCircle style={{ fontSize: "18px" }} />}
        checked={selected}
      />
      {option.name}
    </ListItem>
  );
};

const AutoCompleteInput: FC<{
  label: string;
  params: AutocompleteRenderInputParams;
}> = ({ label, params }) => {
  return (
    <TextField
      {...params}
      variant="outlined"
      label={label}
      placeholder={label}
    />
  );
};
