import { FC, useEffect, useState } from "react";
import { Button, Stack } from "@mui/material";
import { ReviewCard, ReviewHeader } from "../../molecules";
import { analytics } from "../../analytics";
import { ReviewDetails } from "./ReviewDetails";
import { FileUpload } from "../../services";
import { ReviewsEntity } from "../../../types/review_types";
import { TransformedProductDetailsEntity } from "../../../types/transformed_product_entity";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { useAddReviewMutation, useGetReviewQuery } from "../../../store";
import toast from "react-hot-toast";

interface Props {
  product: TransformedProductDetailsEntity;
  eventId: string;
}

const uploadFile = async (
  file: File,
  key: string | number | symbol
): Promise<{ key: string | number | symbol; value: string }> => {
  const data = await FileUpload.uploadFile(file);
  return {
    key,
    value: data.uploadResult[0].public_url,
  };
};

let initialRender = false;
export const Review: FC<Props> = ({ product, eventId }) => {
  const { data: reviewData } = useGetReviewQuery(product.id);
  const [addReview, { isSuccess: addReviewSuccess, reset }] =
    useAddReviewMutation();
  const [selectedReview, setSelectedReview] = useState<
    ReviewsEntity | undefined
  >();
  const [showAllReview, setShowAllReview] = useState(false);

  useEffect(() => {
    if (addReviewSuccess) {
      toast.success("Review added successfully.");
      reset();
    }
  }, [addReviewSuccess]);

  useEffect(() => {
    if (reviewData && !initialRender) {
      analytics.pushViewItemDetails({
        eventId,
        product,
        numberOfReviews: reviewData.length,
      });
      analytics.pushProuctReview({
        product,
        reviews: reviewData,
      });
    }
  }, [reviewData, product]);

  const submitHandler = async (values: any) => {
    const promise: Promise<{ key: string | number | symbol; value: string }>[] =
      [];
    (Object.keys(values) as Array<keyof typeof values>).forEach(key => {
      if (values[key] instanceof File) {
        promise.push(uploadFile(values[key], key));
      } else if (values[key] instanceof Array) {
        values[key] = values[key].map((v: any) => v.value.trim()).join(",");
      }
    });
    const data = await Promise.all(promise);
    data.forEach(d => {
      if (values[d.key] && values[d.key] instanceof File) {
        values[d.key] = d.value;
      }
    });
    values.itemId = product.id;
    analytics.pushWriteProductReview({ product, review: values });
    await addReview(values);
  };

  return (
    <div className="space-y-3" id="review">
      <ReviewHeader
        averageRating={product.averageRating}
        submitHandler={submitHandler}
      />
      <Stack gap={2} alignItems="center">
        {reviewData
          ?.slice(0, showAllReview ? reviewData.length : 2)
          .map(review => (
            <ReviewCard
              key={review.id}
              review={review}
              onClick={() => setSelectedReview(review)}
            />
          ))}
        {reviewData && reviewData.length > 2 && (
          <Button
            size="small"
            startIcon={showAllReview ? <FiEyeOff /> : <FiEye />}
            onClick={() => setShowAllReview(p => !p)}>
            {showAllReview ? "Hide" : "View all"}
          </Button>
        )}
      </Stack>

      <ReviewDetails
        show={Boolean(selectedReview)}
        onClose={() => setSelectedReview(undefined)}
        review={selectedReview}
      />
    </div>
  );
};
