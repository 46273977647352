import { useState } from "react";

export const useFreshChat = () => {
  const widgetLoading = useState(false);
  function initFreshChat() {
    window.fcWidget?.init({
      token: "e1affb70-e442-4c4d-a70a-a4a4f1283d99",
      host: "https://wchat.freshchat.com",
    });
    window.fcWidget?.on("widget:loaded", function () {
      // window.fcWidget.open();
      window.fcWidget.hide();
      window.fcWidget.user.isExists().then(
        function (data: any) {
          console.log("success");
        },
        function (data: any) {}
      );
      window.fcWidget?.user.get().then(function (result: any) {
        // console.log(result, "reddd");
        var userInfo = result.data;
        //Do Something
      });
      widgetLoading[1](false);
    });
    window.fcWidget?.on("widget:closed", function () {
      window.fcWidget.hide();
    });
  }

  function initialize(i: any, t: any) {
    var e;
    i.getElementById(t)
      ? initFreshChat()
      : (((e = i.createElement("script")).id = t),
        (e.async = !0),
        (e.src = "https://wchat.freshchat.com/js/widget.js"),
        (e.onload = initFreshChat),
        i.head.appendChild(e));
  }

  function handleClick() {
    if (window.fcWidget) {
      window.fcWidget.show();
      window.fcWidget.open();
    } else {
      widgetLoading[1](true);
      initialize(document, "freshchat-js-sdk");
    }
  }

  return {
    loading: widgetLoading[0],
    handleClick,
  };
};
