import { useState, useEffect } from "react";
import { EmailOtp, MobileOtp, VerifyOtp } from "./LoginPageContent";
import { LoginHeader } from "./LoginHeader";
import { useLocaleContext } from "../../context/LocaleContext/LocaleContext";
import { Card, CardContent, CardMedia, Container, Stack } from "@mui/material";

export type loginStateType = "mobileOTP" | "emailOTP" | "OTPValue";
export type loginDataType = {
  type: "email" | "mobile";
  value: string;
};

interface Props {
  onSuccess: () => void;
}

export const LoginMain: React.FC<Props> = ({ onSuccess }) => {
  const { currency } = useLocaleContext();
  const [activeState, setActiveState] = useState<loginStateType>(
    currency === "INR" ? "mobileOTP" : "emailOTP"
  );
  const [loginData, setLoginData] = useState<loginDataType | null>(null);

  useEffect(() => {
    setActiveState(currency === "INR" ? "mobileOTP" : "emailOTP");
  }, [currency]);

  const getComponent = () => {
    switch (activeState) {
      case "mobileOTP":
        return (
          <MobileOtp
            setActiveState={setActiveState}
            setLoginData={setLoginData}
          />
        );
      case "emailOTP":
        return (
          <EmailOtp
            setActiveState={setActiveState}
            setLoginData={setLoginData}
          />
        );
      case "OTPValue":
        return (
          <VerifyOtp
            setActiveState={setActiveState}
            loginData={loginData}
            onSuccess={onSuccess}
          />
        );
      default:
        return null;
    }
  };
  return (
    <Card variant="outlined" elevation={0}>
      <CardMedia
        src="https://swiftly-files.s3.ap-south-1.amazonaws.com/coupon_code_asset_creative.jpg"
        component="img"
      />
      <CardContent>
        <LoginHeader activeState={activeState} />
      </CardContent>
      <CardContent>{getComponent()}</CardContent>
    </Card>
  );
};
