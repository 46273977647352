import { Chip, IconButton, Menu, MenuItem, Stack } from "@mui/material";
import { FC, useState } from "react";
import { FiFilter } from "react-icons/fi";

interface Props {
  activeTabState: number;
  setActiveTabState: (newState: number) => void;
}

const ORDER_TABS = ["All", "CONFIRMED", "CANCELLED", "PAYMENT FAILED"];

export const OrdersFilterDropdown: FC<Props> = ({
  activeTabState,
  setActiveTabState,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (index?: number) => {
    if (index || index === 0) {
      setActiveTabState(index);
    }
    setAnchorEl(null);
  };
  return (
    <div>
      <Stack direction="row" alignItems={"center"} gap={0.5}>
        <Chip label={ORDER_TABS[activeTabState]} size="small" />
        <IconButton onClick={handleClick}>
          <FiFilter />
        </IconButton>
      </Stack>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => handleClose()}>
        {ORDER_TABS.map((o, index) => (
          <MenuItem onClick={() => handleClose(index)} key={o}>
            {o}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};
