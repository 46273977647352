import { FC } from "react";
import Link from "next/link";
import Box from "@mui/material/Box";

import { useRouter } from "next/router";
import { analytics } from "../../../analytics";
import { Card, CardActionArea, CardMedia, Typography } from "@mui/material";

interface Props {
  gridColumn: any;
  label: string;
  url: string | { desktop: string; mobile: string };
  aspect: string;
  redirectUrl: string;
  rounded: boolean;
  sx: any;
  id: string;
}

export const GridImageItem: FC<Props> = ({
  gridColumn,
  label,
  url,
  redirectUrl,
  id,
  aspect,
}) => {
  const router = useRouter();

  const component = (
    <Card
      variant="outlined"
      onClick={() => {
        analytics.pushGridBannerClick({
          bannerLink: typeof url === "string" ? url : url.desktop,
          bannerName: label,
          bannerPosition: `0`,
          bannerUrl: redirectUrl ?? "",
          pageUrl: router.asPath,
        });
      }}>
      <CardActionArea>
        {typeof url === "string" ? (
          <CardMedia component="img" src={url} sx={{ aspectRatio: aspect }} />
        ) : (
          <>
            <CardMedia
              component="img"
              src={url.desktop}
              sx={{
                display: { xs: "none", lg: "block" },
                aspectRatio: aspect,
              }}
            />
            <CardMedia
              component="img"
              src={url.mobile}
              sx={{
                display: { xs: "block", lg: "none" },
                aspectRatio: aspect,
              }}
            />
          </>
        )}
      </CardActionArea>
      {label ? (
        <Box sx={{ p: { xs: 1, lg: 2 } }}>
          <Typography variant="body2" textAlign="center" fontWeight={500}>
            {label}
          </Typography>
        </Box>
      ) : null}
    </Card>
  );

  return (
    <Box gridColumn={gridColumn} key={id}>
      {redirectUrl ? <Link href={redirectUrl}>{component}</Link> : component}
    </Box>
  );
};
