import React, { FC, useEffect, useState } from "react";
import {
  Button,
  DialogActions,
  DialogContent,
  MenuItem,
  TextField,
} from "@mui/material";

//Types
import { LocaleState } from "../LocaleContext";

interface Props {
  localeState: LocaleState;
  onSubmit: (state: LocaleState) => void;
  closeDialog: () => void;
}

export const ChangeLocaleForm: FC<Props> = ({
  localeState,
  onSubmit,
  closeDialog,
}) => {
  const [region, setRegion] = useState(localeState.country);
  const [language, setLanguage] = useState(localeState.language);
  const [currency, setCurrency] = useState(localeState.currency);

  useEffect(() => {
    setCurrency(region === "India" ? "INR" : "USD");
  }, [region]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    closeDialog();
    const localeData: LocaleState = {
      country: region,
      currency: region === "India" ? "INR" : "USD",
      language: language,
      symbol: region === "India" ? "₹" : "$",
      flag:
        region === "India"
          ? "https://cdn.picpil.com/fit-in/100x100/logos/2022/09/256/india.png"
          : "https://cdn.picpil.com/fit-in/100x100/logos/2022/09/256/world.png",
    };
    onSubmit(localeData);
  };

  return (
    <form onSubmit={handleSubmit}>
      <DialogContent>
        <div className="flex flex-col gap-4">
          <TextField
            label="Language"
            select
            disabled
            name="language"
            value={language}
            onChange={e => setLanguage(e.target.value)}>
            {["English", "French", "Spanish"].map(r => (
              <MenuItem key={r} value={r}>
                {r}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            label="Currency"
            select
            disabled
            name="currency"
            value={currency}
            onChange={e => setCurrency(e.target.value)}>
            {["INR", "USD"].map(r => (
              <MenuItem key={r} value={r}>
                {r}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            id="region"
            label="Region"
            select
            value={region}
            name="region"
            onChange={e => setRegion(e.target.value)}>
            {["India", "Rest of the world"].map(r => (
              <MenuItem key={r} value={r}>
                {r}
              </MenuItem>
            ))}
          </TextField>
        </div>
      </DialogContent>
      <DialogActions>
        <Button id="localeTogglerCancel" onClick={closeDialog}>
          Cancel
        </Button>
        <Button id="localeTogglerSubmit" variant="contained" type="submit">
          Save
        </Button>
      </DialogActions>
    </form>
  );
};
