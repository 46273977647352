import { ReactNode, FC } from "react";
import { ResponsiveType } from "react-multi-carousel";
import { MediaCarousel } from "./MediaCarousel";
import { ProductCardCarousel } from "./ProductCardCarousel";

const productCarouselResponsive: ResponsiveType = {
  desktop: {
    breakpoint: { max: 3000, min: 1281 },
    items: 5,
    slidesToSlide: 3,
  },
  tablet: {
    breakpoint: { max: 1280, min: 464 },
    items: 4,
    slidesToSlide: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2,
    slidesToSlide: 1,
  },
};

const mediaCarouselResponsive: ResponsiveType = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
    slidesToSlide: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
    slidesToSlide: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
};

interface Props {
  type: "product" | "media";
  children: ReactNode[] | ReactNode;
  height?: number;
  autoplay?: boolean;
}

export const Carousel: FC<Props> = ({ children, type, height, autoplay }) => {
  switch (type) {
    case "product":
      return (
        <ProductCardCarousel responsive={productCarouselResponsive}>
          {children}
        </ProductCardCarousel>
      );
    case "media":
      return (
        <MediaCarousel
          responsive={mediaCarouselResponsive}
          height={height}
          autoplay={autoplay}>
          {children}
        </MediaCarousel>
      );
    default:
      return null;
  }
};
