import { GAAddToCartEntity, GAViewCartEntity } from "../../types";
import { CoreAdapter } from "./CoreAdapter";

class Cart extends CoreAdapter {
  modifyCartProductsAdapter = ({
    product,
    type,
    eventId,
  }: GAAddToCartEntity) => {
    let eventName: string;
    if (type === "add") {
      eventName = "add_to_cart";
    } else if (type === "remove") {
      eventName = "remove_from_cart";
    } else {
      eventName = "change_quantity";
    }
    return {
      event: eventName,
      ecommerce: {
        eventId,
        currencyCode: "INR",
        products: this.convertCartProductToAnalyticsFormat(product, -1),
      },
    };
  };

  viewCartAdapter = ({ products, totalValue }: GAViewCartEntity) => {
    return {
      event: "view_cart",
      value: totalValue,
      currency: CoreAdapter.activeCurrency,
      ecommerce: {
        // value: products.reduce((acc, p) => acc + p.price.sellingPrice, 0),
        value: products.reduce((acc, product) => {
          const activePrice = product.price.find(
            p => p.code === CoreAdapter.activeCurrency
          );
          if (activePrice) {
            return acc + activePrice.sellingPrice;
          } else {
            return acc;
          }
        }, 0),
        products: this.convertCartProductsToAnalyticsFormat(products),
      },
    };
  };
}

export const cartAdapter = new Cart();
