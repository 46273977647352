import { Box, Stack, CardMedia } from "@mui/material";
import Link from "next/link";
import React from "react";

interface Props {
  gridColumn: {
    xs: string;
    lg: string;
  };
  items: {
    mobileImageUrl: string;
    desktopImageUrl: string;
    redirectUrl?: string;
  }[];
  itemsSize: { mobileSize: number; desktopSize: number };
}

export const CarouselContainer: React.FC<Props> = ({
  items,
  itemsSize,
  gridColumn,
}) => {
  return (
    <Box gridColumn={gridColumn}>
      <Stack
        direction="row"
        gap={2}
        sx={{ width: "100%", overflowX: "auto", pb: 2 }}>
        {items.map((item, index) => (
          <React.Fragment key={item.desktopImageUrl + index}>
            <Box
              component={Link}
              href={item.redirectUrl ?? "#"}
              sx={{
                minWidth: itemsSize.desktopSize,
                maxWidth: itemsSize.desktopSize,
                display: { xs: "none", lg: "block" },
              }}>
              <CardMedia
                component="img"
                src={item.desktopImageUrl}
                sx={{ width: "100%", objectFit: "contain", height: "auto" }}
              />
            </Box>
            <Box
              component={Link}
              href={item.redirectUrl ?? "#"}
              sx={{
                minWidth: itemsSize.mobileSize,
                maxWidth: itemsSize.mobileSize,
                display: { xs: "block", lg: "none" },
              }}>
              <CardMedia
                component="img"
                src={item.desktopImageUrl}
                sx={{ width: "100%", objectFit: "contain", height: "auto" }}
              />
            </Box>
          </React.Fragment>
        ))}
      </Stack>
    </Box>
  );
};
