import { FC } from "react";
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import { Divider, Typography } from "@mui/material";
import { useLocaleContext } from "../../context/LocaleContext/LocaleContext";

interface Props {
  paymentMethod: "1" | "2";
  setPaymentMethod: (paymentMethod: "1" | "2") => void;
  currency: "INR" | "USD";
}

export const CheckoutPaymentMethod: FC<Props> = ({
  paymentMethod,
  setPaymentMethod,
  currency,
}) => {
  return (
    <div className="flex flex-col gap-3">
      <div className="space-y-3">
        <Typography variant="subtitle1" fontWeight={700}>
          Select Payment Method
        </Typography>
        <Divider />
      </div>
      <FormControl>
        <RadioGroup
          name="controlled-radio-buttons-group"
          value={paymentMethod}
          onChange={e => setPaymentMethod(e.target.value as "1" | "2")}>
          <FormControlLabel
            value="1"
            control={<Radio size="small" />}
            label={
              <p className="text-sm font-normal">
                Debit card, Credit card, Net banking, Wallets, UPI
              </p>
            }
          />
          {currency === "INR" && (
            <FormControlLabel
              value="2"
              control={<Radio size="small" />}
              label={<p className="text-sm font-normal">Cash on delivery</p>}
            />
          )}
        </RadioGroup>
      </FormControl>
    </div>
  );
};
