import React, { FC } from "react";
import {
  Badge,
  IconButton,
  Button,
  CircularProgress,
  Container,
} from "@mui/material";
import { SlBasket, SlHeart, SlBubbles } from "react-icons/sl";

import Navlinks from "./nav_links/NavLinks";
import AppbarSearch from "../../../appbar_search/AppbarSearch";
import { NextLevelMenuItem } from "../../../../types/navigation";
import { LocaleToggler, UserProfileMenu } from "../../../../src/molecules";
import { FreshChatButton, Logo } from "../../../../src/atoms";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import { useRouter } from "next/router";

interface Props {
  menus: NextLevelMenuItem[];
  cartCount?: number;
  wishlistCount?: number;
  openWishlist: () => void;
  openCart: () => void;
}

const DesktopNavigation: FC<Props> = ({
  menus,
  cartCount,
  wishlistCount,
  openWishlist,
  openCart,
}) => {
  const router = useRouter();
  const isLoggedin = useTypedSelector(state => state.auth.isLoggedin);

  return (
    <Container maxWidth="xl">
      <div className="gap-8 flex flex-row justify-between items-center py-2">
        <Logo />
        <AppbarSearch />
        <IconButton
          id="wishlistButtonDesktop"
          onClick={openWishlist}
          size="large"
          color="primary">
          <Badge
            color="secondary"
            badgeContent={isLoggedin ? wishlistCount : 0}
            id="wishlist-icon-desktop">
            <SlHeart />
          </Badge>
        </IconButton>
        <IconButton
          id="cartButtonDesktop"
          onClick={openCart}
          size="large"
          color="primary">
          <Badge
            color="secondary"
            badgeContent={isLoggedin ? cartCount : 0}
            id="cart-icon">
            <SlBasket />
          </Badge>
        </IconButton>
        <UserProfileMenu />
      </div>
      <div className="relative flex justify-between items-center pb-1">
        <Navlinks menus={menus} />
        <div className="flex items-center gap-2">
          <LocaleToggler />
          <div className="flex gap-2">
            {/* <InstallAppButton /> */}
            <FreshChatButton>
              {({ handleClick, loading }) => (
                <Button
                  startIcon={
                    loading ? (
                      <CircularProgress size={14} color="inherit" />
                    ) : (
                      <SlBubbles />
                    )
                  }
                  onClick={handleClick}
                  variant="contained"
                  size="small"
                  disabled={loading}>
                  {loading ? "Loading..." : "Chat Now"}
                </Button>
              )}
            </FreshChatButton>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default DesktopNavigation;
