import { useState, MouseEvent, FC } from "react";
import {
  IconButton,
  Badge,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import {
  SlUser,
  SlLogin,
  SlLogout,
  SlList,
  SlUserFollowing,
} from "react-icons/sl";

import Link from "next/link";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import { useLogout } from "../../hooks/useLogout";
import { useScreenSize } from "../../hooks/useScreenSize";

export const UserProfileMenu: FC = () => {
  const isLoggedin = useTypedSelector(state => state.auth.isLoggedin);
  const { handleLogout } = useLogout();
  const { isMobile } = useScreenSize();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      {isLoggedin ? (
        <>
          <IconButton
            id="userIcon"
            onClick={handleClick}
            size={isMobile ? "medium" : "large"}
            color="primary">
            <Badge color="secondary" badgeContent={0}>
              <SlUser />
            </Badge>
          </IconButton>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            disablePortal
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}>
            <Link href="/profile">
              <MenuItem onClick={handleClose}>
                <ListItemIcon>
                  <SlUserFollowing />
                </ListItemIcon>
                <ListItemText>Profile</ListItemText>
              </MenuItem>
            </Link>
            <Link href="/orders">
              <MenuItem onClick={handleClose}>
                <ListItemIcon>
                  <SlList />
                </ListItemIcon>
                <ListItemText>Orders</ListItemText>
              </MenuItem>
            </Link>
            <MenuItem
              onClick={() => {
                setAnchorEl(null);
                handleLogout();
              }}>
              <ListItemIcon>
                <SlLogout />
              </ListItemIcon>
              <ListItemText>Log out</ListItemText>
            </MenuItem>
          </Menu>
        </>
      ) : (
        <Link href="/login">
          <IconButton
            size={isMobile ? "medium" : "large"}
            color="primary"
            id="login-button">
            <SlLogin />
          </IconButton>
        </Link>
      )}
    </div>
  );
};
