import React from "react";
import { Card, Fab, Stack } from "@mui/material";
import { AiFillSafetyCertificate } from "react-icons/ai";
import { BsCashStack } from "react-icons/bs";
import { FiCornerUpLeft } from "react-icons/fi";

import Typography from "@mui/material/Typography";

const bannerItems = [
  {
    id: "111",
    label: "100% purchase protection",
    icon: <AiFillSafetyCertificate />,
  },
  {
    id: "222",
    label: "Cash on delivery available",
    icon: <BsCashStack />,
  },
  {
    id: "333",
    label: "7 days free and easy returns",
    icon: <FiCornerUpLeft />,
  },
];

export const CheckoutStaticBanner = () => {
  return (
    <Card sx={{ mx: { lg: 2, xs: 1 }, mb: { xs: 1 } }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ p: 1 }}
        gap={1}>
        {bannerItems.map(b => (
          <Stack
            key={b.id}
            direction={{ xs: "column", lg: "row" }}
            alignItems="center"
            gap={1}
            sx={{ textAlign: "center" }}>
            <Fab size="small" color="primary" sx={{ fontSize: "1.5rem" }}>
              {b.icon}
            </Fab>
            <Typography
              variant="body2"
              sx={{ display: { xs: "none", lg: "block" } }}>
              {b.label}
            </Typography>
            <Typography
              variant="caption"
              sx={{ display: { xs: "block", lg: "none" } }}>
              {b.label}
            </Typography>
          </Stack>
        ))}
      </Stack>
    </Card>
  );
};
