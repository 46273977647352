import { FC } from "react";
import FooterNavigationSection from "./NavigationSection";
import FooterContactSection from "./FooterContactSection";
import FooterContentSection from "./FooterContentSection";
import FooterTopSection from "./FooterTopSection";

export const FooterMain: FC<{ data: any }> = ({ data: footerData }) => {
  return (
    <div className="space-y-12 py-8">
      {footerData && (
        <>
          <FooterTopSection />
          <FooterNavigationSection content={footerData.footerNavigation} />
          <FooterContactSection />
          <FooterContentSection content={footerData.footerContent} />
        </>
      )}
    </div>
  );
};
