import { FC } from "react";
import { Box } from "@mui/material";
import { useRouter } from "next/router";
import Link from "next/link";

import { analytics } from "../../../analytics";
import { Carousel } from "../../../../components/Carousels";

interface CarouselProps {
  id: string;
  imageUrl: string;
  mobileImageUrl: string;
  contentHandle: string;
  altText: string;
}

interface Props {
  carouselItems: CarouselProps[];
  gridColumn: string;
  id: string;
  aspectRatio?: number[];
}

export const BannerCarousel: FC<Props> = ({
  carouselItems,
  gridColumn,
  id,
}) => {
  const router = useRouter();

  const getCarousel = (isMobile: boolean) => {
    return (
      <Carousel type="media">
        {carouselItems.map((item, index) => {
          let imageUrl: string = "";
          if (isMobile) {
            imageUrl = item.mobileImageUrl;
          } else {
            imageUrl = item.imageUrl;
          }
          return (
            <Link
              className="block"
              id={`bannerCarouselItem`}
              href={item.contentHandle}
              key={imageUrl}>
              <div
                onClick={() => {
                  const item = carouselItems[index];
                  analytics.pushCarouselBannerClick({
                    bannerLink: isMobile ? item.mobileImageUrl : item.imageUrl,
                    bannerName: item.altText,
                    bannerPosition: `${index + 1}`,
                    bannerUrl: item.contentHandle,
                    pageUrl: router.asPath,
                  });
                  router.push(item.contentHandle);
                }}>
                <img
                  alt={item.altText}
                  src={imageUrl}
                  className="w-full h-auto object-cover"
                />
              </div>
            </Link>
          );
        })}
      </Carousel>
    );
  };

  return (
    <Box gridColumn={gridColumn} key={id}>
      <Box sx={{ display: { xs: "none", lg: "block" } }}>
        {getCarousel(false)}
      </Box>
      <Box sx={{ display: { xs: "block", lg: "none" } }}>
        {getCarousel(true)}
      </Box>
    </Box>
  );
};
