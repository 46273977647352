import { Button, Stack } from "@mui/material";
import React, { FC } from "react";

interface Props {
  buttons: {
    label: string;
    onClick: () => void;
    variant?: "text" | "outlined" | "contained" | undefined;
    icon?: any;
    color?:
      | "inherit"
      | "primary"
      | "secondary"
      | "success"
      | "error"
      | "info"
      | "warning";
  }[];
}

export const CardActionButtons: FC<Props> = ({ buttons }) => {
  return (
    <Stack direction="row" gap={1} flexWrap="wrap">
      {buttons.map(b => (
        <Button
          key={b.label}
          onClick={b.onClick}
          color={b.color}
          size="small"
          variant={b.variant ? b.variant : "text"}
          startIcon={b.icon ? b.icon : null}>
          {b.label}
        </Button>
      ))}
    </Stack>
  );
};
