import React, { FC } from "react";
import { Tab, Tabs } from "@mui/material";
import { ORDER_TABS } from "../../OrdersMain/OrdersMain";

interface Props {
  activeTabState: number;
  setActiveTabState: (newState: number) => void;
}

export const OrdersFilterTabs: FC<Props> = ({
  activeTabState,
  setActiveTabState,
}) => {
  return (
    <Tabs
      value={activeTabState}
      onChange={(_, newValue) => setActiveTabState(newValue)}
      orientation="horizontal">
      {ORDER_TABS.map(o => (
        <Tab label={o.name} key={o.name} />
      ))}
    </Tabs>
  );
};
