import { CircularProgress, Pagination, Stack, Typography } from "@mui/material";
import { FC, Fragment, ReactNode } from "react";
import { sortOptions } from "./../../models/SortOptions";
import { useScreenSize } from "../../hooks/useScreenSize";
import { StandardSelect } from "../../molecules";
import { useInfiniteScroll } from "../../templates/Productlistings/hooks/useInfiniteScroll";
import { TransformedProductEntity } from "../../../types/transformed_product_entity";
import { ProductCard } from "../ProductCard/ProductCard";

interface Props {
  children?: ReactNode;
  page: number;
  setPage: (page: number) => void;
  sortBy: string;
  setSortBy: (sortBy: string) => void;
  count: number;
  title: string;
  initialProducts: TransformedProductEntity[];
}

export const ProductListingMain: FC<Props> = ({
  title,
  sortBy,
  setSortBy,
  page,
  setPage,
  count,
  initialProducts,
}) => {
  const { isSupported, observerTarget, isLoading, paginatedProducts } =
    useInfiniteScroll(initialProducts);

  return (
    <div className="flex flex-col gap-6 h-full">
      <div className="flex justify-between items-center">
        <div>
          <Typography variant="subtitle1" fontWeight={700}>
            {title}
          </Typography>
          <Typography variant="body2">Found total {count} products</Typography>
        </div>
        <div className="hidden lg:block">
          <StandardSelect
            id="sort-by-standard-select"
            value={sortBy}
            handleChange={value => setSortBy(value)}
            options={sortOptions}
          />
        </div>
      </div>
      <div className="flex-1 grid grid-cols-2 sm:grid-cols-3 2xl:grid-cols-4 gap-x-2 sm:gap-x-5 gap-y-4 xl:gap-y-8">
        {paginatedProducts?.pages.map((group, i) => (
          <Fragment key={i}>
            {group.map((prod, index) => {
              if (!(prod.images.length > 0)) return null;
              return (
                <ProductCard
                  key={prod.id}
                  product={prod}
                  position={page * (index + 1)}
                />
              );
            })}
          </Fragment>
        ))}
      </div>
      <div ref={observerTarget}></div>

      {isLoading && (
        <Stack alignItems="center">
          <CircularProgress />
        </Stack>
      )}

      {!isSupported && (
        <Pagination
          sx={{ alignSelf: "center", mt: 4 }}
          size="large"
          variant="outlined"
          page={page}
          onChange={(_, value: number) => setPage(value)}
          count={Math.ceil(count / 24)}
          defaultPage={1}
          siblingCount={0}
          boundaryCount={1}
        />
      )}
    </div>
  );
};
