import { FC } from "react";
import { ProductPrice } from "../../../types/transformed_product_entity";

interface Props {
  price: ProductPrice;
  size?: "small" | "medium" | "large";
}

export const PriceDetails: FC<Props> = ({ price }) => {
  const symbol = price.code === "INR" ? "₹" : "$";

  return (
    <div className="flex items-baseline gap-1">
      <span className="text-sm lg:text-lg font-bold text-slate-900">
        {symbol}
        {price.sellingPrice}
      </span>
      <span className="text-slate-500 text-[10px] lg:text-base">
        <del>
          {symbol}
          {price.mrp}
        </del>
      </span>
      <span className="text-teal-700 text-[10px] lg:text-base">
        {Math.floor(price.discount)}% OFF
      </span>
    </div>
  );
};
