import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { SerializedError } from "@reduxjs/toolkit";
import toast from "react-hot-toast";

export const useNotification = () => {
  const showNotification = <T>(
    result: { data: T } | { error: FetchBaseQueryError | SerializedError },
    payload: {
      successMessage?: string;
      onSuccess?: (data: T) => void;
      errorMessage?: string;
    }
  ) => {
    if ("error" in result) {
      if (payload.errorMessage) toast.error(payload.errorMessage);
      return;
    }
    if (payload.successMessage) toast.success(payload.successMessage);
    if (payload.onSuccess) payload.onSuccess(result.data);
  };
  return showNotification;
};
