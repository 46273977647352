import { FC } from "react";
import { NextSeo } from "next-seo";

interface Props {
  seo: any;
}

export const SEO: FC<Props> = ({ seo }) => {
  return (
    <>
      {seo && (
        <NextSeo
          title={seo.seoTitle}
          description={seo.seoDescription}
          canonical={seo.seoCanonicalUrl}
          noindex={seo.noIndex}
          nofollow={seo.noFollow}
          facebook={{ appId: seo.facebookAppId }}
          twitter={{
            cardType: seo.twitterCardType,
            handle: seo.twitterHandle,
            site: seo.twitterSite,
          }}
          additionalMetaTags={seo.additionalMetaTags}
          additionalLinkTags={seo.additionalLinkTags}
          openGraph={{
            type: seo.openGraphType,
            url: seo.openGraphUrl,
            title: seo.openGraphTitle,
            description: seo.openGraphDescription,
            images: [
              {
                url: seo.openGraphImages,
                width: 200,
                height: 200,
                alt: "Og Image Alt",
              },
            ],
            locale: seo.openGraphLocale,
          }}
          robotsProps={seo.robotsProps}
        />
      )}
    </>
  );
};
