import { FC, ReactNode, useEffect } from "react";
import { Stack } from "@mui/material";

import ModifiedAppbar from "../../../components/appbar";
import { useTypedDispatch } from "../../../hooks/useTypedDispatch";
import { LoginResponse, login, useOtplessLoginMutation } from "../../../store";
import { useLoginSuccess, useLogout, useNotification } from "../../hooks";
import { useRouter } from "next/router";
import { TestimonialsSection } from "../../organisms";
import { Footer } from "../../templates/Footer";

interface Props {
  children: ReactNode;
  mainNav: any;
  topNav: any;
  footer: any;
}

let initilaRender = true;
export const GlobalLayout: FC<Props> = ({
  children,
  mainNav,
  topNav,
  footer,
}) => {
  const notify = useNotification();
  const router = useRouter();
  const dispatch = useTypedDispatch();
  const { isRedirectToHomePageRequired } = useLogout();
  const [otplessLogin] = useOtplessLoginMutation();
  const handleLoginSuccess = useLoginSuccess();
  useEffect(() => {
    if (window) {
      const token = window.localStorage.getItem("token");
      if (token) {
        dispatch(login({ token }));
      } else if (isRedirectToHomePageRequired()) {
        router.replace("/login");
      }
    }
  }, []);

  const otpless = async (otplessPayload: {
    token: string;
    timestamp: string;
    timezone: string;
    mobile: {
      name: string;
      number: string;
    };
    waNumber: string;
    waName: string;
  }) => {
    const waNumber = otplessPayload.waNumber;
    const otp = otplessPayload.token;
    notify<LoginResponse>(await otplessLogin({ mobileNumber: waNumber, otp }), {
      successMessage: "Login successful",
      onSuccess: data => {
        handleLoginSuccess(data);
      },
    });
  };

  useEffect(() => {
    if (initilaRender) {
      initilaRender = false;
      const script = document.createElement("script");
      script.src = "https://otpless.com/auth.js";
      document.body.appendChild(script);
      const otplessInit = Reflect.get(window, "otplessInit");
      otplessInit?.();

      Reflect.set(window, "otpless", otpless);
      return () => {
        document.body.removeChild(script);
      };
    }
  }, []);

  return (
    <div className="flex flex-col gap-4">
      <ModifiedAppbar topNavData={topNav} mainNavData={mainNav.mainNavData} />
      <Stack
        sx={{ minHeight: "100vh", paddingTop: { xs: "176px", lg: "190px" } }}>
        {children}
      </Stack>
      <div className="mt-10">
        <TestimonialsSection />
      </div>
      <div>
        <Footer footerData={footer?.footer} />
      </div>
    </div>
  );
};
