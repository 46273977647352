import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "../../Store";
import {
  AddressType,
  AddressBody,
  UpdatedAddressType,
  PincodeMapEntity,
  PincodeResponse,
} from "./Types";

export const addressApi = createApi({
  reducerPath: "addressApi",
  refetchOnFocus: true,
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.BASE_URL,
    prepareHeaders: (header, api) => {
      const { auth } = api.getState() as RootState;
      if (auth.isLoggedin && auth.token) {
        header.set("authorization", `Bearer ${auth.token}`);
      }
      return header;
    },
  }),
  tagTypes: ["Address"],
  endpoints: builder => ({
    getAddress: builder.query<AddressType[], void>({
      query: () => `/customer/address?page=1&limit=100`,
      providesTags: ["Address"],
      transformResponse: (data: AddressType[]) => {
        return data;
      },
    }),
    getAddressByPincode: builder.query<PincodeMapEntity[], string>({
      query: id =>
        `https://swiftlyapps.api.jumkey.com/v5/lookup/pincode?pincode=${id}`,
      transformResponse: (data: PincodeResponse) => data.pincodeMap,
    }),
    createAddress: builder.mutation<any, AddressBody>({
      query: body => ({
        url: "/customer/address",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Address"],
    }),
    updateAddress: builder.mutation<any, AddressBody & { addressId: number }>({
      query: body => ({
        url: "/customer/address",
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Address"],
    }),
    deleteAddress: builder.mutation<any, number>({
      query: addressId => ({
        url: "/customer/address",
        method: "DELETE",
        body: { addressId },
      }),
      invalidatesTags: ["Address"],
    }),
    setDefaultAddress: builder.mutation<any, number>({
      query: id => ({
        url: "/customer/address/default",
        method: "PUT",
        body: { addressId: id },
      }),
      invalidatesTags: ["Address"],
    }),
  }),
});

export const {
  useGetAddressQuery,
  useLazyGetAddressByPincodeQuery,
  useCreateAddressMutation,
  useUpdateAddressMutation,
  useDeleteAddressMutation,
  useSetDefaultAddressMutation,
} = addressApi;
