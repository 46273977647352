import { GAViewItemListEntity } from "../../types";
import { CoreAdapter } from "./CoreAdapter";

class ProductView extends CoreAdapter {
  viewItemListAdapter = ({
    pageTitle,
    products,
    page,
    eventId,
  }: GAViewItemListEntity) => {
    return {
      event: "view_item_list",
      ecommerce: {
        eventId,
        item_list_id: pageTitle,
        item_list_name: pageTitle,
        impressions: products.map((product, index) => {
          return this.convertTransformedProductToAnalyticsFormat(
            product,
            page * (index + 1)
          );
        }),
      },
    };
  };
}

export const productViewAdapter = new ProductView();
