import {
  Button,
  Chip,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import { nanoid } from "nanoid";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { useState } from "react";
import { SlMagnifier } from "react-icons/sl";

const suggestions = [
  { id: nanoid(), label: "Necklace", contentHandle: "/collections/necklace" },
  { id: nanoid(), label: "Earring", contentHandle: "/collections/earrings" },
  {
    id: nanoid(),
    label: "Mangalsutra",
    contentHandle: "/collections/mangalsutra",
  },
  { id: nanoid(), label: "Anklet", contentHandle: "/collections/anklet" },
  {
    id: nanoid(),
    label: "Jewellery set",
    contentHandle: "/collections/jewellery-set",
  },
];

export const InvalidPage = () => {
  const router = useRouter();
  const [searchValue, setSearchValue] = useState("");

  const handleSubmit = (e: any = undefined) => {
    if (e) {
      e.preventDefault();
    }
    router.push(`/search/${searchValue}`);
  };

  return (
    <div className="col-span-1 lg:col-span-2 flex items-center justify-center px-2">
      <div className="space-y-6 rounded-md">
        <div className="space-y-1">
          <div className="flex gap-2 items-center text-xl">
            <p className="sectionTitle text-lg">
              Oops! Something went wrong :(
            </p>
          </div>
          <p className="text-gray-500 text-sm">
            The page you are looking for can't be found. Maybe the links below
            can be helpful.
          </p>
        </div>
        <div className="w-full h-auto">
          <img src="/404.jpeg" className="w-full h-auto object-contain" />
        </div>
        <div className="space-y-6">
          <div className="space-y-4">
            <div className="space-y-1">
              <p className="text-xs font-semibold text-gray-700">Suggestions</p>
              <div className="flex flex-wrap gap-2">
                {suggestions.map(s => {
                  return (
                    <Link href={s.contentHandle} key={s.id}>
                      <Chip label={s.label} size="small" />
                    </Link>
                  );
                })}
              </div>
            </div>
            <form className="flex flex-col" onSubmit={handleSubmit}>
              <TextField
                size="small"
                fullWidth
                label="Necklace, Bangles etc."
                value={searchValue}
                onChange={e => setSearchValue(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton size="small" onClick={handleSubmit}>
                        <SlMagnifier />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </form>
          </div>
          <div>
            <Link href="/">
              <Button fullWidth variant="contained">
                Go back to homepage
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
