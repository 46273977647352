import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { FC } from "react";

interface Props {
  id: string;
  value: string;
  handleChange: (value: string) => void;
  options: {
    name: string;
    value: string;
  }[];
}

export const StandardSelect: FC<Props> = ({
  id,
  value,
  handleChange,
  options,
}) => {
  return (
    <FormControl size="small">
      <InputLabel>Sort</InputLabel>
      <Select
        sx={{ minWidth: "200px" }}
        id={id}
        value={value}
        label="Age"
        onChange={e => handleChange(e.target.value)}>
        {options.map(option => {
          return (
            <MenuItem key={option.name} value={option.value}>
              {option.name}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
