import {
  isRejectedWithValue,
  Middleware,
  MiddlewareAPI,
  SerializedError,
} from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { captureMessage, captureException } from "@sentry/nextjs";
import Router from "next/router";
import toast from "react-hot-toast";

export const getApiErrorMessage = (
  error: SerializedError | FetchBaseQueryError
) => {
  if ("status" in error) {
    const err: string | any = "error" in error ? error.error : error.data;
    return typeof err === "string" ? err : err.data.description;
  } else {
    return error.message;
  }
};

export const rtkQueryErrorLogger: Middleware =
  (api: MiddlewareAPI) => next => action => {
    const args = action?.meta?.arg;
    if (args && args.type === "mutation") {
      if (isRejectedWithValue(action)) {
        const payload = action.payload;
        let message = "";
        if (!payload.data) {
          message = "Someting went wrong.";
        } else {
          message = payload.data.description
            ? payload.data.description
            : "Someting went wrong.";
        }
        if (payload.status === 401) {
          if (!window.localStorage) {
            captureMessage("Localstorage error happend.");
          }
          api.dispatch({ type: "auth/logout" });
          Router.push("/login");
        }
        captureException(action.payload.data);
        toast.error(message);
      }
    }
    return next(action);
  };
