import { useRouter } from "next/router";
import { FC, useLayoutEffect } from "react";
import { Button, Stack } from "@mui/material";
import * as Yup from "yup";
import toast from "react-hot-toast";

import { parseJwt } from "../../../../../utils";
import { analytics } from "../../../../analytics";
import { LoginResponse } from "../../../../../types/login_types";
import { useLocaleContext } from "../../../../context/LocaleContext/LocaleContext";
import { login, useVerifyOtpMutation } from "../../../../../store";
import { CustomButton } from "../../../../atoms";
import { CustomTextField } from "../../../../atoms/CustomTextField";
import { useTypedDispatch } from "../../../../../hooks/useTypedDispatch";
import { useFormik } from "formik";
import { loginDataType, loginStateType } from "../../LoginMain";

interface Props {
  setActiveState: (state: loginStateType) => void;
  loginData: loginDataType | null;
  onSuccess: () => void;
}

const validationSchema = Yup.object().shape({
  otpValue: Yup.string()
    .length(4, "OTP values should be 4 digit long.")
    .required("OTP value can't be empty."),
});

export const VerifyOtp: FC<Props> = ({
  setActiveState,
  loginData,
  onSuccess,
}) => {
  const router = useRouter();
  const dispatch = useTypedDispatch();
  const { currency } = useLocaleContext();
  const [verifyOtp, { isLoading, isSuccess, data }] = useVerifyOtpMutation();

  const { values, handleChange, touched, errors, handleSubmit } = useFormik({
    initialValues: { otpValue: "" },
    onSubmit: async values => {
      if (loginData?.type) {
        await verifyOtp({
          otp: String(values.otpValue),
          type: loginData.type,
          inputValue: loginData.value,
        });
      }
    },
    validationSchema,
    validateOnChange: true,
  });

  const pushLoginToDatalayer = (data: LoginResponse) => {
    const { emailId, mobileNumber, firstname, lastname } = data.customer;
    const { aud } = parseJwt(data.accessToken);
    analytics.pushLogin({
      userId: aud,
      userName: `${firstname} ${lastname}`,
      email: emailId,
      number: mobileNumber,
    });
  };

  const onLoginSuccess = (data: LoginResponse) => {
    pushLoginToDatalayer(data);
    dispatch(login({ token: data.accessToken }));
    onSuccess();
  };

  useLayoutEffect(() => {
    if (isSuccess) {
      toast.success("Login Successful");
      if (data) {
        onLoginSuccess(data);
      }
    }
  }, [isSuccess]);

  return (
    <>
      <CustomTextField
        style={{ width: "100%" }}
        label="Enter OTP"
        id="OTPValue"
        type="number"
        name="otpValue"
        value={values.otpValue}
        onChange={handleChange}
        error={touched.otpValue && Boolean(errors.otpValue)}
        helperText={touched.otpValue && errors.otpValue}
      />
      <Stack gap={1} sx={{ mt: 3 }}>
        <CustomButton
          loading={isLoading}
          fullWidth
          variant="contained"
          onClick={() => handleSubmit()}>
          Verify OTP
        </CustomButton>
        <Button
          fullWidth
          variant="outlined"
          onClick={() =>
            setActiveState(currency === "INR" ? "mobileOTP" : "emailOTP")
          }>
          Go Back
        </Button>
      </Stack>
    </>
  );
};
