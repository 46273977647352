import { TransformedProductDetailsEntity } from "../../../../types/transformed_product_entity";
import { CartProductEntity } from "../../../../types/cart_response_type";
import { TransformedProductEntity } from "../../../../types/transformed_product_entity";

export class CoreAdapter {
  protected static activeCurrency: "INR" | "USD" = "INR";
  static setActiveCurrency = (cur: "INR" | "USD") => {
    CoreAdapter.activeCurrency = cur;
  };
  protected convertTransformedProductToAnalyticsFormat = (
    product: TransformedProductEntity | TransformedProductDetailsEntity,
    position: number
  ) => {
    const activePrice = product.price.find(
      p => p.code === CoreAdapter.activeCurrency
    );
    return {
      id: product.id,
      name: product.title,
      brand: product.brand,
      category: product.category,
      canonical_url: product.canonicalUrl,
      featured_image: product.images[0].url,
      price: activePrice?.sellingPrice,
      mrp: activePrice?.mrp,
      currency: CoreAdapter.activeCurrency,
      relative_position: position,
      available_sizes: product.sizes,
      sku: product.sku,
      unique_code: product.uniqueCode,
    };
  };

  protected convertCartProductToAnalyticsFormat = (
    product: CartProductEntity,
    index: number
  ) => {
    const data = this.convertTransformedProductToAnalyticsFormat(
      product,
      index + 1
    );
    return {
      ...data,
      variant_id: product.variantId,
      variant_sku: product.variants.find(v => v.id === product.variantId)?.sku,
      variant_size: product.variants.find(v => v.id === product.variantId)
        ?.size,
      quantity: product.quantity,
    };
  };

  protected convertCartProductsToAnalyticsFormat = (
    products: CartProductEntity[]
  ) => {
    const parsedProducts = products.map((product, index) => {
      return this.convertCartProductToAnalyticsFormat(product, index);
    });
    return parsedProducts;
  };
}
