import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { TransformedProductEntity } from "../../../types/transformed_product_entity";
import { oldProductTransformer } from "../../../utils";
import { RootState } from "../../Store";
import { WishlistResponse } from "./Types";

export const wishlistApi = createApi({
  reducerPath: "wishlistApi",
  refetchOnFocus: true,
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.BASE_URL,
    prepareHeaders: (header, api) => {
      const { auth } = api.getState() as RootState;
      if (auth.isLoggedin && auth.token) {
        header.set("authorization", `Bearer ${auth.token}`);
      }
      return header;
    },
  }),
  tagTypes: ["Wishlist"],
  endpoints: builder => ({
    getWishlist: builder.query<TransformedProductEntity[], void>({
      query: () => ({ url: `/customer/wishlist?page=1&limit=100` }),
      transformResponse: (data: WishlistResponse) => {
        const products = data.products.map(el => oldProductTransformer(el));
        return products;
      },
      providesTags: ["Wishlist"],
    }),
    addToWishlist: builder.mutation<any, { itemId: string }>({
      query: body => ({
        url: "/customer/wishlist",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Wishlist"],
    }),
    removeFromWishlist: builder.mutation<any, { itemId: string }>({
      query: body => ({
        url: "/customer/wishlist",
        method: "DELETE",
        body,
      }),
      invalidatesTags: ["Wishlist"],
    }),
  }),
});

export const {
  useGetWishlistQuery,
  useAddToWishlistMutation,
  useRemoveFromWishlistMutation,
} = wishlistApi;
