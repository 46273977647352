import { IconButton, Badge } from "@mui/material";
import { SlBasket, SlHeart, SlMenu } from "react-icons/sl";

import NavSidebar from "./nav_sidebar/NavSidebar";
import AppbarSearch from "../../../appbar_search/AppbarSearch";
import { NextLevelMenuItem } from "../../../../types/navigation";
import { Logo } from "../../../../src/atoms";
import { UserProfileMenu } from "../../../../src/molecules";
import { useDrawerDispatch } from "../../../../src/context";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";

interface Props {
  menus: NextLevelMenuItem[];
  cartCount?: number;
  wishlistCount?: number;
  openWishlist: () => void;
  openCart: () => void;
}

const MobileNavigation: React.FC<Props> = ({
  menus,
  cartCount,
  wishlistCount,
  openWishlist,
  openCart,
}) => {
  const isLoggedin = useTypedSelector(state => state.auth.isLoggedin);
  const dispatch = useDrawerDispatch();

  const openSidebar = () => {
    dispatch({
      type: "OPEN_DRAWER",
      payload: {
        component: <NavSidebar menus={menus} />,
        side: "left",
      },
    });
  };

  return (
    <div className="pt-3 pb-2 px-2 space-y-3">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-3">
          <IconButton onClick={openSidebar} size="small">
            <SlMenu className="text-black" />
          </IconButton>
          <Logo small />
        </div>
        <div className="flex items-center gap-2">
          <IconButton color="primary" onClick={openWishlist}>
            <Badge
              badgeContent={isLoggedin ? wishlistCount : 0}
              color="secondary">
              <SlHeart />
            </Badge>
          </IconButton>
          <IconButton color="primary" onClick={openCart}>
            <Badge badgeContent={isLoggedin ? cartCount : 0} color="secondary">
              <SlBasket />
            </Badge>
          </IconButton>
          <UserProfileMenu />
        </div>
      </div>
      <div>
        <AppbarSearch />
      </div>
    </div>
  );
};

export default MobileNavigation;
