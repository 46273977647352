import { FC, useRef } from "react";
import { useRouter } from "next/router";
import NavLink from "next/link";

import { WishlistButton } from "../../atoms";

import { TransformedProductEntity } from "../../../types/transformed_product_entity";
import { analytics } from "../../analytics";
import { useLocaleContext } from "../../context/LocaleContext/LocaleContext";
import { useScreenSize } from "../../hooks/useScreenSize";
import {
  Card,
  CardActionArea,
  CardMedia,
  Stack,
  Link,
  Box,
  Typography,
} from "@mui/material";
import { FiStar } from "react-icons/fi";
import { grey } from "@mui/material/colors";
import Head from "next/head";

interface Props {
  product: TransformedProductEntity;
  position: number;
  autoLayout?: boolean;
  size?: "sm" | "md";
}

export const ProductCard: FC<Props> = ({ product }) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const router = useRouter();
  const { title, price, id, images, productHandle } = product;
  const { currency, symbol } = useLocaleContext();
  const activePrice = price.find(p => p.code === currency);
  const { isMobile } = useScreenSize();

  const tagClick = (tagname: string, url: string) => {
    analytics.pushHashTagClicked({
      pageName: router.asPath,
      pageUrl: router.asPath,
      tagLink: url,
      tagName: tagname,
      cb: () => {},
    });
  };

  return (
    <>
      <Head>
        <link rel="preload" as="image" href={images[0].url} />
      </Head>
      <Card
        component={Stack}
        ref={containerRef}
        sx={{ height: "100%", borderRadius: 0 }}
        elevation={0}
        spacing={{ xs: 0.5, lg: 1 }}>
        <Stack sx={{ flex: 1 }}>
          <Box
            sx={{
              position: "relative",
            }}>
            <Link
              component={NavLink}
              id="productCardAction"
              href={`/products/${productHandle}`}
              target={isMobile ? "_self" : "_blank"}
              sx={{
                display: "block",
                height:
                  (containerRef.current?.getBoundingClientRect().width! * 4) /
                    3 +
                  "px",
              }}>
              <CardActionArea sx={{ height: "100%" }}>
                <CardMedia
                  src={images[0].url}
                  component="img"
                  sx={{ width: "100%", height: "100%", objectFit: "contain" }}
                />
              </CardActionArea>
            </Link>
            <Box
              display="flex"
              alignItems="center"
              sx={{
                position: "absolute",
                bottom: 10,
                right: 10,
                z: 20,
                bgcolor: grey[100],
                px: 0.5,
                py: 0.25,
                borderRadius: 0.5,
              }}>
              <FiStar className="text-xs md:text-sm fill-teal-600 stroke-teal-600" />
              <p className="text-xs md:text-sm font-bold ml-1">
                {product.rating.toPrecision(2)}
              </p>
            </Box>
            <Box sx={{ position: "absolute", top: 0, right: 0, z: 20 }}>
              <WishlistButton itemId={id} />
            </Box>
          </Box>
        </Stack>
        <Box sx={{ flex: 1, px: { xs: 0.5, lg: 1 } }}>
          <Stack sx={{ height: "100%" }} gap={1}>
            <Box sx={{ flex: 1 }}>
              <Link
                href={`/products/${productHandle}`}
                component={NavLink}
                underline="none"
                color="text.primary">
                <Typography
                  fontSize={{ xs: "12px", md: "14px" }}
                  fontWeight={600}>
                  {title}
                </Typography>
              </Link>
            </Box>
            <Stack
              gap={0.5}
              direction="row"
              flexWrap="wrap"
              sx={{ display: { xs: "none", md: "flex" } }}>
              {product.tags.map(tag => {
                return (
                  <Link
                    component={NavLink}
                    href={tag.redirectLink}
                    key={tag.name}
                    onClick={() => tagClick(tag.name, tag.redirectLink)}
                    variant="caption"
                    underline="hover"
                    color="text.secondary">
                    {tag.name}
                  </Link>
                );
              })}
            </Stack>
            {activePrice && (
              <div>
                <Stack direction="row" alignItems="baseline" gap={0.5}>
                  <Typography
                    variant="body1"
                    color="text.primary"
                    fontSize={{ xs: "14px", sm: "16px" }}
                    fontWeight={600}>
                    {symbol}
                    {activePrice.sellingPrice}
                  </Typography>
                  <Typography
                    variant="body1"
                    component="del"
                    fontSize={{ xs: "12px", sm: "14px" }}
                    color="text.secondary">
                    {symbol}
                    {activePrice.mrp}
                  </Typography>
                  <Typography
                    variant="body1"
                    fontSize={{ xs: "12px", sm: "14px" }}
                    color={t => t.palette.success.main}>
                    {Math.floor(activePrice.discount)}% OFF
                  </Typography>
                </Stack>
              </div>
            )}
          </Stack>
        </Box>
      </Card>
    </>
  );
};
