import { FC } from "react";
import { Box, Stack } from "@mui/material";
import { ErrorBoundary } from "@sentry/nextjs";
import { ProductCard } from "../../ProductCard/ProductCard";

interface Props {
  products: any[];
}

export const ProductCarousel: FC<Props> = ({ products }) => {
  return (
    <div>
      <Stack
        direction="row"
        gap={2}
        sx={{ overflowX: "auto", width: "100%", pb: 2 }}>
        {products &&
          products.map((prod, index) => {
            return (
              <Box
                sx={{
                  minWidth: { xs: "191px", lg: "300px" },
                  maxWidth: { xs: "191px", lg: "300px" },
                }}
                key={prod.id}>
                <ErrorBoundary fallback={<p>Something went wrong.</p>}>
                  <ProductCard position={index + 1} product={prod} autoLayout />
                </ErrorBoundary>
              </Box>
            );
          })}
      </Stack>
    </div>
  );
};
