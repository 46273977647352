import { Container, Stack } from "@mui/material";
import { useRouter } from "next/router";

import { LoginMain } from "./LoginMain";

export const LoginComponent = () => {
  const router = useRouter();
  const redirectAfterLogin = () => {
    if (window.history.state.idx !== 0) {
      router.back();
    } else {
      router.replace("/");
    }
  };
  return (
    <Container maxWidth="xs">
      <Stack alignItems="center">
        <LoginMain onSuccess={redirectAfterLogin} />
      </Stack>
    </Container>
  );
};
