import { useRouter } from "next/router";
import { useEffect, useRef, useState } from "react";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import {
  useAddToWishlistMutation,
  useGetWishlistQuery,
  useRemoveFromWishlistMutation,
} from "../../store";
import { TransformedProductEntity } from "../../types/transformed_product_entity";
import { analytics } from "../analytics";
import { useApiLoadingState } from "./useApiLoadingState";
import { useNotification } from "./useNotification";

export function useWishlist(productId: string) {
  const router = useRouter();
  const notify = useNotification();
  const deletedProductRef = useRef<TransformedProductEntity | null>(null);
  const isLoggedin = useTypedSelector(state => state.auth.isLoggedin);

  const [loginModalOpen, setLoginModalOpen] = useState(false);

  const { data: wishlistData, isFetching: wishlistDataFetching } =
    useGetWishlistQuery(undefined, { skip: !isLoggedin });
  const { isLoading, setIsLoading } = useApiLoadingState(wishlistDataFetching);

  const [
    addToWishlist,
    {
      data: addToWishlistData,
      reset: addToWishlistReset,
      isSuccess: addToWishlistSuccess,
      isLoading: addToWishlistLoading,
    },
  ] = useAddToWishlistMutation();

  const [
    deleteFromWishlist,
    {
      data: deleteFromWishlistData,
      reset: deleteFromWishlistReset,
      isSuccess: deletFromWishlistSuccess,
      isLoading: deleteFromWishlistLoading,
    },
  ] = useRemoveFromWishlistMutation();

  const isWishlisted = () => {
    if (wishlistData) {
      return wishlistData.find(p => p.id === productId);
    }
    return false;
  };

  useEffect(() => {
    if (deletFromWishlistSuccess && !wishlistDataFetching) {
      if (deletedProductRef.current) {
        analytics.pushModifyWishlist({
          eventId: deleteFromWishlistData.eventId,
          type: "remove",
          product: deletedProductRef.current,
        });
        deletedProductRef.current = null;
      }
      deleteFromWishlistReset();
    }
  }, [deletFromWishlistSuccess, wishlistDataFetching]);
  const deleteFromWishlistHandler = async () => {
    const wishlistedProduct = wishlistData?.find(p => p.id === productId);
    if (wishlistedProduct) {
      deletedProductRef.current = wishlistedProduct;
      setIsLoading(true);
      deleteFromWishlist({ itemId: productId });
    }
  };

  useEffect(() => {
    if (addToWishlistSuccess && !wishlistDataFetching) {
      const wishlistedProduct = wishlistData?.find(p => p.id === productId);
      if (wishlistedProduct) {
        analytics.pushModifyWishlist({
          eventId: addToWishlistData.eventId,
          type: "add",
          product: wishlistedProduct,
        });
      }
      addToWishlistReset();
    }
  }, [addToWishlistSuccess, wishlistDataFetching]);

  const atw = async (prodId: string) => {
    notify(await addToWishlist({ itemId: prodId }), {
      successMessage: "Added to wishlist",
    });
  };

  const addToWishlistHandler = async () => {
    if (isLoggedin) {
      if (!isWishlisted()) {
        setIsLoading(true);
        atw(productId);
      }
    } else {
      setLoginModalOpen(true);
    }
  };

  const onLoginSuccess = async () => {
    const addToWishlistProduct = localStorage.getItem("addToWishlistProduct");
    if (addToWishlistProduct) {
      const { productId } = JSON.parse(addToWishlistProduct);
      if (productId) {
        await atw(productId);
      }
      localStorage.removeItem("addToWishlistProduct");
    }
  };

  const toggleWishlistHandler = () => {
    if (isLoggedin) {
      if (isWishlisted()) {
        setIsLoading(true);
        deleteFromWishlistHandler();
      } else {
        setIsLoading(true);
        atw(productId);
      }
    } else {
      localStorage.setItem(
        "addToWishlistProduct",
        JSON.stringify({ productId })
      );
      setLoginModalOpen(true);
    }
  };

  return {
    addToWishlistHandler,
    deleteFromWishlistHandler,
    toggleWishlistHandler,
    isWishlisted,
    loginModalOpen,
    setLoginModalOpen,
    onLoginSuccess,
    wishlistDataFetching:
      addToWishlistLoading || deleteFromWishlistLoading || isLoading,
  };
}
