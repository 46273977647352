import { Button, Divider, IconButton, Stack, Typography } from "@mui/material";
import { FC, useMemo, useState } from "react";
import { MobileFIlterTabs } from "../../../molecules";
import {
  FilterType,
  FilterValue,
  TransformedFilterEntity,
} from "../../../../types/transformedFilterEntity";
import { FiCornerDownLeft, FiX, FiXCircle } from "react-icons/fi";
import { useDrawerDispatch } from "../../../context";
import { grey } from "@mui/material/colors";

interface Props {
  filters: TransformedFilterEntity[];
  filterQuery: { [key in FilterType]: string[] };
  setFilterQuery: (data: { [key in FilterType]: string[] }) => void;
}

export const QueryFiilters: FC<Props> = ({
  filters,
  filterQuery,
  setFilterQuery,
}) => {
  const [appliedFilters, setAppiledFilters] = useState(filterQuery);
  const initialState: { [key in FilterType]: string[] } = useMemo(
    () =>
      filters.reduce((acc: any, curr: any) => {
        acc[curr.name] = [];
        return acc;
      }, {}),
    []
  );
  const drawerDispatch = useDrawerDispatch();

  const onChangeAppliedFilter = (
    name: keyof typeof FilterType,
    option: FilterValue
  ) => {
    if (appliedFilters[name]) {
      setAppiledFilters(p => {
        let value: string[] = [];
        if (p[name].includes(option.name)) {
          value = p[name].filter(i => i !== option.name);
        } else {
          value = [...p[name], option.name];
        }
        return {
          ...p,
          [name]: value,
        };
      });
    }
  };

  const clearAllFilter = () => {
    setAppiledFilters(initialState);
  };

  const closeDrawer = () => drawerDispatch({ type: "CLOSE_DRAWER" });

  const handleSubmit = () => {
    setFilterQuery(appliedFilters);
    closeDrawer();
  };

  return (
    <div className="h-screen overflow-y-scroll flex flex-col">
      <div className="flex items-center justify-between p-3 sticky top-0 left-0 z-10 bg-gray-100">
        <Typography variant="subtitle1">Filters</Typography>
        <IconButton size="small" onClick={closeDrawer}>
          <FiX />
        </IconButton>
      </div>
      <div className="flex-1">
        <MobileFIlterTabs
          filters={filters}
          appliedFilters={appliedFilters}
          onChangeAppliedFilter={onChangeAppliedFilter}
        />
      </div>
      <Stack
        direction="row"
        alignItems="center"
        p={1}
        position="sticky"
        bottom={0}
        left={0}
        zIndex={10}
        bgcolor={grey[50]}
        divider={<Divider orientation="vertical" flexItem />}>
        <Button
          startIcon={<FiXCircle />}
          fullWidth
          color="inherit"
          size="small"
          onClick={clearAllFilter}>
          Clear
        </Button>
        <Button
          endIcon={<FiCornerDownLeft />}
          fullWidth
          color="inherit"
          size="small"
          onClick={handleSubmit}>
          Apply
        </Button>
      </Stack>
    </div>
  );
};
