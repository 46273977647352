import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { parseJwt } from "./utils";

interface State {
  isLoggedin: boolean;
  token: string | null;
  userId: string | null;
}

const initialState: State = {
  isLoggedin: false,
  token: null,
  userId: null,
};
const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: (state, action: PayloadAction<{ token: string }>) => {
      const { token } = action.payload;
      state.isLoggedin = true;
      state.token = token;
      const { aud } = parseJwt(token);
      state.userId = aud;
      if (window?.localStorage) {
        window.localStorage.setItem("token", token);
      }
    },
    logout: state => {
      state.isLoggedin = false;
      state.token = null;
      state.userId = null;
      if (window?.localStorage) {
        window.localStorage.clear();
      }
    },
  },
});

export const { login, logout } = authSlice.actions;
export const authReducer = authSlice.reducer;
