import { ReviewResponse } from "./../../../types/review_types";
import { NetworkCall } from "../NetworkCall";

export class Review {
  static getReview = async (key: string) => {
    const keys = key.split("/");
    const id = keys[keys.length - 1];
    const data = (await NetworkCall.query({
      auth: "none",
      url: `${process.env.BASE_URL}/listings/items/review?itemId=${id}`,
    })) as ReviewResponse;
    return data.reviews;
  };

  static postReview = async (values: any) => {
    await NetworkCall.mutate({
      url: `${process.env.BASE_URL}/listings/items/review`,
      method: "POST",
      body: values,
      auth: "none",
    });
    return await Review.getReview(values.itemId);
  };
}
