import Head from "next/head";
import Router from "next/router";
import { ThemeProvider, createTheme, CssBaseline } from "@mui/material";
import { Provider } from "react-redux";
import { Toaster } from "react-hot-toast";
import Script from "next/script";
import UAParser from "ua-parser-js";
import "react-multi-carousel/lib/styles.css";

import {
  closePageLoader,
  PageLoadingSpinner,
  showPageLoader,
} from "../src/atoms";
import { LocaleContextProvider } from "../src/context/LocaleContext";
import { theme } from "../utils/theme";
import { store } from "../store";
import {
  DeviceTypeContextProvider,
  DraweContextProvider,
} from "../src/context";
import { AddUserDetailsDialog } from "../src/organisms";

import "../styles/globals.css";
import "./../styles/countdownTimer.css";
import "@fontsource/mulish/400.css";
import "@fontsource/mulish/500.css";
import "@fontsource/mulish/600.css";
import "@fontsource/mulish/700.css";
import { GlobalLayout } from "../src/layouts";
import { groq } from "next-sanity";
import { client } from "../sanity/lib/client";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";

declare global {
  interface Window {
    dataLayer: any;
    Growlytics: any;
    fbq: any;
    fcWidget: any;
    deferredPrompt: any;
    Bugpilot: any;
    otpless: any;
  }
}

const getMainNavGroqQuery = () => {
  return groq`*[_type == "mainnav" && title == "Main Navigation"][0]`;
};

const getFooterGroqQuery = () => {
  return groq`*[_type == "footer" && title == "Footer"][0]`;
};

Router.events.on("routeChangeStart", () => {
  showPageLoader();
});
Router.events.on("routeChangeComplete", ({}) => {
  closePageLoader();
});

const queryClient = new QueryClient();

function MyApp({ Component, pageProps }: any) {
  const { mainNav, footer, topNav } = pageProps;
  return (
    <ThemeProvider theme={createTheme(theme("light"))}>
      <CssBaseline />
      <Script
        id="gtm"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                  })(window,document,'script','dataLayer','GTM-NQM457T');`,
        }}
      />
      <Head>
        <title>Jumkey Fashion Jewellery</title>
        <meta
          name="description"
          content="Women Fashion Jewellery - Buy Online. Huge range of unique fashion jewellery for ladies at Jumkey. COD. 7 days free returns."
        />
        <link
          rel="icon"
          href="https://cdn.picpil.com/logos/2022/05/147/1242566_original.jpg"
        />
        <meta
          name="viewport"
          content="width=device-width,initial-scale=1,maximum-scale=1,user-scalable=no"></meta>
        <meta
          content="text/html; charset=UTF-8; X-Content-Type-Options=nosniff"
          httpEquiv="Content-Type"
        />
        <meta
          httpEquiv="Strict-Transport-Security"
          content="max-age=2592000"></meta>
        <link rel="manifest" href="/manifest.json" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="black" />
        <meta name="apple-mobile-web-app-title" content="Jumkey" />
        <link rel="apple-touch-icon" href="/icon-192x192.png" sizes="192x192" />
        <link rel="apple-touch-icon" href="/icon-256x256.png" sizes="256x256" />
        <link rel="apple-touch-icon" href="/icon-384x384.png" sizes="384x384" />
        <link rel="apple-touch-icon" href="/icon-512x512.png" sizes="512x512" />
        <meta name="msapplication-TileImage" content="/icon-192x192.png" />
        <meta name="msapplication-TileColor" content="#fff" />
        <meta name="theme-color" content="#f69435"></meta>
        {/* <script
          type="text/javascript"
          src="https://otpless.com/auth.js"></script> */}
      </Head>
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <DeviceTypeContextProvider>
            <LocaleContextProvider>
              <DraweContextProvider>
                <noscript
                  dangerouslySetInnerHTML={{
                    __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-NQM457T"
                              height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
                  }}
                />
                <GlobalLayout mainNav={mainNav} topNav={topNav} footer={footer}>
                  <Component {...pageProps} />
                </GlobalLayout>
                <AddUserDetailsDialog />
              </DraweContextProvider>
            </LocaleContextProvider>
          </DeviceTypeContextProvider>
        </QueryClientProvider>
      </Provider>
      <PageLoadingSpinner />
      <Toaster
        toastOptions={{
          style: { fontFamily: "Mulish" },
        }}
      />
    </ThemeProvider>
  );
}

const getContent = async (url: string) => {
  const res = await fetch(url);
  const data = await res.json();
  return data;
};

MyApp.getInitialProps = async () => {
  let mainNav: any;
  let footer: any;
  let topNav: any;
  try {
    mainNav = await getContent(
      `https://sanity-json-file-storage.s3.ap-south-1.amazonaws.com/mainnav/main-navigation.json`
    );
  } catch (error) {
    mainNav = null;
  }
  try {
    footer = await getContent(
      `https://sanity-json-file-storage.s3.ap-south-1.amazonaws.com/footer/footer.json`
    );
  } catch (error) {
    footer = null;
  }
  try {
    topNav = await getContent(
      `https://swiftly-files.s3.ap-south-1.amazonaws.com/topnav.json`
    );
  } catch (err) {
    topNav = null;
  }

  return {
    pageProps: {
      mainNav,
      footer,
      topNav,
    },
  };
};

// let mainNav: any;
// let footer: any;
// let topNavData: any;
// try {
//   mainNav = await getContent(
//     `https://sanity-json-file-storage.s3.ap-south-1.amazonaws.com/mainnav/main-navigation.json`
//   );
// } catch (error) {
//   mainNav = null;
// }
// try {
//   footer = await getContent(
//     `https://sanity-json-file-storage.s3.ap-south-1.amazonaws.com/footer/footer.json`
//   );
// } catch (error) {
//   footer = null;
// }
// try {
//   topNavData = await getContent(
//     `https://swiftly-files.s3.ap-south-1.amazonaws.com/topnav.json`
//   );
// } catch (err) {
//   topNavData = null;
// }

// let userAgent;
// let deviceType = "";
// if (req) {
//   userAgent = req.headers["user-agent"];
//   const parser = new UAParser();
//   parser.setUA(userAgent);
//   const result = parser.getResult();
//   deviceType = (result.device && result.device.type) || "desktop";
// }

// return {
//   props: {
//     mainNav,
//     footer,
//     topNavData,
//     deviceType,
//   },
// };

export default MyApp;

// useEffect(() => {
//   if (navigator) {
//     if ("serviceWorker" in navigator) {
//       navigator.serviceWorker
//         .register("/sw.js")
//         .then(function () {
//           console.log("Service worker registered!");
//         })
//         .catch(function (err) {
//           console.log(err);
//         });
//     }
//   }
// }, []);
