import { FC } from "react";
import { useRouter } from "next/router";

import DesktopNavigation from "./desktop_navigation/DesktopNavigation";
import MobileNavigation from "./mobile_navigation/MobileNavigation";

import { NextLevelMenuItem } from "../../../types/navigation";
import { analytics } from "../../../src/analytics";
import { useLocaleContext } from "../../../src/context/LocaleContext/LocaleContext";
import { useGetCartQuery, useGetWishlistQuery } from "../../../store";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import { CartProductEntity } from "../../../types/cart_response_type";

interface Props {
  navigationData: NextLevelMenuItem[];
}

const MainNav: FC<Props> = ({ navigationData }) => {
  const router = useRouter();
  const isLoggedin = useTypedSelector(state => state.auth.isLoggedin);
  const { currency } = useLocaleContext();

  const { data: cartData } = useGetCartQuery(undefined, { skip: !isLoggedin });
  const { data: wishlistData } = useGetWishlistQuery(undefined, {
    skip: !isLoggedin,
  });
  const activePrice = cartData?.value.find(p => p.code === currency);

  const openWishList = () => {
    if (isLoggedin) {
      if (wishlistData && wishlistData.length > 0) {
        analytics.pushViewWishlist({ products: wishlistData });
      }
      router.push("/wishlist");
    } else {
      router.push("/login");
    }
  };

  const openCart = () => {
    if (isLoggedin) {
      if (cartData && cartData.products.length > 0 && activePrice) {
        analytics.pushViewCart({
          cartId: cartData.cartId,
          products: cartData.products.filter(
            p => !p.hasOwnProperty("errMessage")
          ) as CartProductEntity[],
          totalValue: activePrice.sellingPrice,
        });
      }
      router.push("/checkout/cart");
    } else {
      router.push("/login");
    }
  };

  const props = {
    menus: navigationData,
    cartCount: cartData?.count,
    wishlistCount: wishlistData?.length,
    openWishlist: openWishList,
    openCart: openCart,
  };

  return (
    <>
      <div className="w-full hidden lg:block">
        <DesktopNavigation {...props} />
      </div>
      <div className="w-full block lg:hidden">
        <MobileNavigation {...props} />
      </div>
    </>
  );
};

export default MainNav;
