import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { TestimonialEntity } from "./Types";

export const testimonialsApi = createApi({
  reducerPath: "testimonialsApi",
  refetchOnFocus: true,
  baseQuery: fetchBaseQuery({ baseUrl: process.env.BASE_URL }),
  endpoints: builder => ({
    getTestimonial: builder.query<TestimonialsResponse, number>({
      query: number => `/customer/testimonial_new?limit=${number}`,
    }),
    getRandomTestimonial: builder.query<TestimonialsResponse, void>({
      query: () => "/customer/random/testimonial?count=10",
    }),
  }),
});

interface TestimonialsResponse {
  testimonials: TestimonialEntity[];
}

export const { useGetTestimonialQuery, useGetRandomTestimonialQuery } =
  testimonialsApi;
