import { FC } from "react";
import { TypographyProps, Box, Button, CardHeader, Link } from "@mui/material";
import NavLink from "next/link";

interface Props {
  gridColumn: any;
  label: string;
  titleTypographyProps?: TypographyProps;
  subHeader?: string;
  action?: {
    label: string;
    redirect: string;
  };
  sx: any;
  id: string;
  subheaderTypographyProps?: TypographyProps;
}

export const GridSectionTitle: FC<Props> = ({
  gridColumn,
  label,
  subHeader,
  action,
  sx,
  id,
}) => {
  return (
    <Box gridColumn={gridColumn} sx={[...sx]} key={id}>
      <CardHeader
        sx={{ p: 0 }}
        title={label}
        titleTypographyProps={{
          fontWeight: 700,
          textTransform: "uppercase",
          variant: "subtitle1",
        }}
        subheader={subHeader ? subHeader : null}
        subheaderTypographyProps={{ variant: "body2" }}
        action={
          action ? (
            <Box sx={{ alignSelf: "center" }}>
              <Link
                component={NavLink}
                href={action?.redirect}
                variant="body1"
                sx={{ display: { xs: "none", lg: "block" } }}>
                View All
              </Link>
              <Link
                component={NavLink}
                href={action?.redirect}
                variant="body2"
                sx={{ display: { xs: "block", lg: "none" } }}>
                View All
              </Link>
            </Box>
          ) : null
        }
      />
    </Box>
  );
};
