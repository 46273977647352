import React from "react";
import { Box } from "@mui/material";

export const Bullet = () => {
  return (
    <Box
      component="span"
      sx={{ display: "inline-block", mx: 0.5, transform: "scale(1.2)" }}>
      •
    </Box>
  );
};
