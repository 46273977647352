import { useEffect } from "react";

import { useTextChange } from "./useTextChange";
import { useLazyGetSearchResultQuery } from "../../store";

export const useSearchProducts = () => {
  const [searchProducts, { data }] = useLazyGetSearchResultQuery();
  const { value, debounceValueChangeHandler, setValue, isValueChanged } =
    useTextChange();

  useEffect(() => {
    searchProducts("");
  }, []);

  useEffect(() => {
    if (isValueChanged) {
      if (value.length > 2 || value.length === 0) {
        searchProducts(value);
      }
    }
  }, [value]);

  return {
    value,
    debounceValueChangeHandler,
    products: data?.products ?? [],
    setValue,
    eventId: data?.eventId,
  };
};
