import { useRef, useEffect, useState } from "react";
import { useRouter } from "next/router";
import { TransformedProductEntity } from "../../../../types/transformed_product_entity";
import { useQueryClient, useInfiniteQuery } from "@tanstack/react-query";
import { ProductsResponseType } from "../../../../types/product_listing_types";
import { newProductTransformer } from "../../../../utils";

export const useInfiniteScroll = (
  initialProducts: TransformedProductEntity[]
) => {
  const queryClient = useQueryClient();
  const { query } = useRouter();
  const observerTarget = useRef(null);

  const [isSupported, setIsSupported] = useState(false);

  const fetchProjects = async ({ pageParam = 1 }) => {
    if (pageParam === 1) return initialProducts;
    let url = `${process.env.BASE_URL}/listings/items/collections?collectionHandle=${query.id}&limit=24&page=${pageParam}`;
    const updatedQuery = { ...query };
    delete updatedQuery.id;
    Object.keys(updatedQuery).forEach(key => {
      if (updatedQuery[key]) {
        url += `&${key}=${query[key]}`;
      }
    });
    const res = await fetch(url);
    const data = (await res.json()) as ProductsResponseType;
    return data.products.map(prod => newProductTransformer(prod));
  };

  const {
    data,
    error,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    status,
  } = useInfiniteQuery({
    queryKey: ["products"],
    queryFn: fetchProjects,
    getNextPageParam: (lastPage, pages) => {
      console.log(lastPage, pages);
      return pages.length + 1;
    },
  });

  const fetchData = () => {
    fetchNextPage();
  };

  useEffect(() => {
    queryClient.setQueryData(["products"], () => ({
      pages: [initialProducts],
      pageParams: [1],
    }));
  }, [initialProducts]);

  useEffect(() => {
    const supported = "IntersectionObserver" in window;
    setIsSupported(supported);
  }, []);

  useEffect(() => {
    if ("IntersectionObserver" in window) {
      const observer = new IntersectionObserver(
        entries => {
          if (entries[0].isIntersecting) {
            fetchData();
          }
        },
        { threshold: 1 }
      );

      if (observerTarget.current) {
        observer.observe(observerTarget.current);
      }

      return () => {
        if (observerTarget.current) {
          observer.unobserve(observerTarget.current);
        }
      };
    }
  }, []);

  return {
    observerTarget,
    isSupported,
    isLoading: isFetching,
    paginatedProducts: data,
  };
};
