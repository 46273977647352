import { useEffect, useState } from "react";

export const useApiLoadingState = (isFetching: boolean) => {
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (!isFetching) {
      setIsLoading(false);
    }
  }, [isFetching]);

  return {
    isLoading: isLoading && isFetching,
    setIsLoading,
  };
};
