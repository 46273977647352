import React from "react";
import {
  CardContent,
  CardHeader,
  Collapse,
  IconButton,
  TypographyProps,
} from "@mui/material";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";

interface ChildProps {
  expanded: boolean;
  setExpanded: React.Dispatch<React.SetStateAction<boolean>>;
}

interface Props {
  title: string | (({ expanded, setExpanded }: ChildProps) => React.ReactNode);
  titleTypographyProps?: TypographyProps;
  children:
    | React.ReactNode
    | (({ expanded, setExpanded }: ChildProps) => React.ReactNode);
  defaultExpanded?: boolean;
}

export const CollapseCard: React.FC<Props> = ({
  title,
  titleTypographyProps = {},
  children,
  defaultExpanded = true,
}) => {
  const [expanded, setExpanded] = React.useState(defaultExpanded);
  return (
    <div>
      <CardHeader
        sx={{ px: 0, pt: 0 }}
        title={
          typeof title === "string" ? title : title({ expanded, setExpanded })
        }
        titleTypographyProps={{
          variant: "subtitle1",
          sx: { cursor: "pointer" },
          onClick: () => setExpanded(p => !p),
          ...titleTypographyProps,
        }}
        action={
          <IconButton size="small" onClick={() => setExpanded(p => !p)}>
            {expanded ? <FiChevronUp /> : <FiChevronDown />}
          </IconButton>
        }
      />
      <Collapse in={expanded}>
        <CardContent sx={{ p: 0 }}>
          {children instanceof Function
            ? children({ expanded, setExpanded })
            : children}
        </CardContent>
      </Collapse>
    </div>
  );
};
